import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SerialConnectionOptionsComponent } from "src/app/features/network-designer/components/serial-connection-options/serial-connection-options.component";
import ConnectionEdge from "./ConnectionEdge";

export default class SerialConnectionEdge extends ConnectionEdge {
  //Serial Connection Values
  ConnectorType = "Serial";
  dceEnd?: string;
  dteEnd?: string;

  addToSerializedTopology(doc: XMLDocument, parent?: HTMLElement) {
    let node = doc.createElement("SerialConnection");
    this.serializeConnectionData(doc, node);

    //insert serial data
    let dce = doc.createElement("DceEnd");
    let dte = doc.createElement("DteEnd");
    dce.innerHTML = this.dceEnd;
    dte.innerHTML = this.dteEnd;
    node.appendChild(dce);
    node.appendChild(dte);

    if (parent) {
      parent.querySelectorAll(`SerialConnections`).forEach((elem: HTMLElement) => {
        elem.appendChild(node);
      });
    }

    return node;
  }

  editConnection(ms: NgbModal) {
    console.log("Serial Connection Edge");
    let modalref = ms.open(SerialConnectionOptionsComponent, {
      scrollable: true,
      centered: true,
      size: "sm",
    });

    modalref.shown.subscribe(() => {
      modalref.componentInstance.initialize(this);
    });

    modalref.closed.subscribe((deviceOne: boolean) => {
      if (deviceOne == true) {
        this.dceEnd = this.Device1InterfaceId;
        this.dteEnd = this.Device2InterfaceId;
      } else {
        this.dceEnd = this.Device2InterfaceId;
        this.dteEnd = this.Device1InterfaceId;
      }
    });
  }
}
