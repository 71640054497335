<div class="d-flex flex-column w-100 editor-container" [ngClass]="{ 'stand-alone': IsStandAlone }">
  <div class="container-fluid editor-nav">
    <a class="btn-nav ml-auto" title="Save" (click)="saveClick()"
      ><span class="mdi mdi-content-save"></span> <span class="pl-1">Save</span></a
    >
    <a class="btn-nav" title="Refresh" (click)="refreshClick()"
      ><span class="mdi mdi-refresh"> <span class="pl-1">Refresh</span></span></a
    >
    <a class="btn-nav mr-auto" title="Exit" (click)="exitClick()"
      ><span class="mdi mdi-exit-to-app"><span class="pl-1">Exit</span></span></a
    >
  </div>
  <app-text-editor class="flex-grow-1" [(content)]="Contents"></app-text-editor>
</div>
