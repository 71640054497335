import { ITopologyNode } from "./ITopologyNode";
import { NodePosition, NodeDimension } from "@swimlane/ngx-graph";
import Point from "../../math/Point";
import BosonVector from "../../Math/BosonVector";
import IContextMenuItem from "src/app/features/network-designer/models/Context Menu/IContextMenuItem";

export default class SelectionNode implements ITopologyNode {
  type: string;
  elementType: string;
  defaultPosition: { x: number; y: number };
  id: string;
  position?: NodePosition;
  dimension?: NodeDimension;
  transform?: string;
  label?: string;
  data?: any;
  meta?: any;
  originLocation: Point;
  contextMenuItems: IContextMenuItem[];

  constructor(name: string, x: number, y: number) {
    this.id = name;
    this.label = "";
    this.type = "Selection";
    this.elementType = "guiElement";
    this.position = { x: x, y: y };
    this.defaultPosition = { x: x, y: y };
    this.dimension = { width: 25, height: 25 };
    this.originLocation = { x: 0, y: 0 };
    this.contextMenuItems = [];
  }

  InitializeNode(x: number, y: number) {
    this.position.x = x + 0.5;
    this.position.y = y + 0.5;
    this.dimension.height = 1;
    this.dimension.width = 1;
    this.originLocation.x = x;
    this.originLocation.y = y;
  }

  ResizeNodeToMousePoint(mousePoint: Point) {
    let originToMouseVector: BosonVector = new BosonVector(
      mousePoint.x - this.originLocation.x,
      mousePoint.y - this.originLocation.y
    );

    if (this.originLocation.x > mousePoint.x == true && this.originLocation.y > mousePoint.y == true) {
      // upper left point
      this.position.x = mousePoint.x;
      this.position.y = mousePoint.y;
    } else if (this.originLocation.x > mousePoint.x == false && this.originLocation.y > mousePoint.y == true) {
      // upper right point
      this.position.x = this.originLocation.x;
      this.position.y = this.originLocation.y + originToMouseVector.y;
    } else if (this.originLocation.x > mousePoint.x == true && this.originLocation.y > mousePoint.y == false) {
      //bottom left point
      this.position.x = this.originLocation.x + originToMouseVector.x;
      this.position.y = this.originLocation.y;
    } else {
      // bottom right point
      this.position.x = this.originLocation.x;
      this.position.y = this.originLocation.y;
    }

    this.dimension.width = Math.abs(originToMouseVector.x);
    this.dimension.height = Math.abs(originToMouseVector.y);
    this.position.x += this.dimension.width / 2;
    this.position.y += this.dimension.height / 2;
  }

  nodesInsideSquare(nodes: ITopologyNode[]): ITopologyNode[] {
    // filter out the gui elements
    let nodesToReturn: ITopologyNode[] = nodes.filter((node) => node.elementType != "guiElement");

    return nodesToReturn.filter((node: ITopologyNode) => this.pointInSquare(node.position) == true);
  }

  private pointInSquare(toEvaluate: Point) {
    let upperLeft: Point = {
      x: this.position.x - this.dimension.width / 2,
      y: this.position.y - this.dimension.height / 2,
    };
    let upperRight: Point = { x: upperLeft.x + this.dimension.width, y: upperLeft.y };
    let bottomLeft: Point = { x: upperLeft.x, y: upperLeft.y + this.dimension.height };

    return (
      toEvaluate.x >= upperLeft.x &&
      toEvaluate.x <= upperRight.x &&
      toEvaluate.y >= upperLeft.y &&
      toEvaluate.y <= bottomLeft.y
    );
  }
}
