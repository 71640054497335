import Line from "./Line";
import Point from "./Point";
import BosonVector from "./BosonVector";

export default class Geometry {
  APPROXIMATE_ZERO: number = Math.pow(0.1, 32);
  APPROXIMATE_INFINITY: number = 90071992547409;

  getReciprocol(value: number): number {
    if (value == 0) {
      return 1 / this.APPROXIMATE_ZERO;
    } else {
      return 1 / value;
    }
  }

  getSlope(source: Point, target: Point) {
    if (target.x - source.x == 0) return this.APPROXIMATE_INFINITY;

    return ((target.y - source.y) * -1) / (target.x - source.x);
  }
  getMidpoint(line: Line): Point {
    return { x: (line.P1.x + line.P2.x) / 2, y: (line.P1.y + line.P2.y) / 2 };
  }

  getVectorFromPoints(p1: Point, p2: Point): BosonVector {
    return new BosonVector(p2.x - p1.x, p2.y - p1.y);
  }

  getVectorFromLine(line: Line): BosonVector {
    return this.getVectorFromPoints(line.P1, line.P2);
  }

  getNormalVectorFromSlope(slope: number) {
    let magnitude = Math.pow(1 + Math.pow(slope, 2), 0.5);
    return new BosonVector(1 / magnitude, slope / magnitude);
  }

  PointWithDistance(start: Point, distance: number, direction: BosonVector): Point {
    let distanceVector = direction.multiplyScalar(distance);

    return { x: start.x + distanceVector.x, y: start.y + distanceVector.y };
  }

  DistanceBetweenPoints(p1: Point, p2: Point): number {
    return Math.pow(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2), 0.5);
  }

  PointInsideRectangle(toEvaluate: Point, upperLeft: Point, upperRight: Point, bottomLeft: Point): boolean {
    let AM: BosonVector = new BosonVector(toEvaluate.x - upperLeft.x, toEvaluate.y - upperLeft.y); // upper left to evaluate
    let AB: BosonVector = new BosonVector(upperRight.x - upperLeft.x, upperRight.y - upperLeft.y); // upper left to upper right
    let AD: BosonVector = new BosonVector(upperRight.x - upperLeft.x, upperRight.y - upperLeft.y); // upper left to bottom left

    return (
      0 < AM.dotProduct(AB) &&
      AM.dotProduct(AB) < AB.dotProduct(AB) &&
      0 < AM.dotProduct(AD) &&
      AM.dotProduct(AD) < AD.dotProduct(AD)
    );
  }
}
