import Hwic1Serial from "../../Addons/Hwic1Serial";
import Hwic2Bri from "../../Addons/Hwic2Bri";
import Hwic2Serial from "../../Addons/Hwic2Serial";
import Hwic4Bri from "../../Addons/Hwic4Bri";
import Hwic4Serial from "../../Addons/Hwic4Serial";
import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter2811 extends BaseInventoryRouter {
  constructor() {
    super("2811", "2800", 64, 128);
    this.maxAddons = 5;
    this.defaultInterfaces.addEthernetInterfaces("FastEthernet", 2, null, this.maxAddons);
    this.supportedAddons.push(new Hwic4Serial(), new Hwic1Serial(), new Hwic2Serial(), new Hwic4Bri(), new Hwic2Bri());
  }
}
