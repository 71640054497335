// https://github.com/swimlane/ngx-graph/blob/master/src/docs/demos/components/ngx-graph-org-tree/customDagreNodesOnly.ts
// code is templated on above (graph library git repo), but has been extensively modified.
import { EventEmitter } from "@angular/core";
import { Graph, Layout, Edge, Node, NodePosition } from "@swimlane/ngx-graph";
import * as dagre from "dagre";
import ConnectionEdge from "./Edges/ConnectionEdge";
import DeviceNode from "./Nodes/DeviceNode";
import Line from "../math/Line";
import Point from "../math/Point";
import { ITopologyNode } from "./Nodes/ITopologyNode";
import GuideLineNode from "./Nodes/GuideLineNode";
import ITopologyEdge from "./Edges/TopologyEdge";
import Geometry from "../Math/Geometry";
import SnapResult from "./Models/SnapResult";
import { CanvasService } from "src/app/features/network-designer/services/canvas.service";

export enum Orientation {
  LEFT_TO_RIGHT = "LR",
  RIGHT_TO_LEFT = "RL",
  TOP_TO_BOTTOM = "TB",
  BOTTOM_TO_TOM = "BT",
}
export enum Alignment {
  CENTER = "C",
  UP_LEFT = "UL",
  UP_RIGHT = "UR",
  DOWN_LEFT = "DL",
  DOWN_RIGHT = "DR",
}

export interface DagreSettings {
  orientation?: Orientation;
  marginX?: number;
  marginY?: number;
  edgePadding?: number;
  rankPadding?: number;
  nodePadding?: number;
  align?: Alignment;
  acyclicer?: "greedy" | undefined;
  ranker?: "network-simplex" | "tight-tree" | "longest-path";
  multigraph?: boolean;
  compound?: boolean;
}

export interface NetMapSettings extends DagreSettings {
  curveDistance?: number;
}

const DEFAULT_EDGE_NAME = "\x00";
const GRAPH_NODE = "\x00";
const EDGE_KEY_DELIM = "\x01";

// Prevent divide by zero errors
const APPROXIMATE_ZERO = Math.pow(0.1, 32);

export class NetMapLayout implements Layout {
  private cs: CanvasService;
  defaultSettings: NetMapSettings = {
    orientation: Orientation.LEFT_TO_RIGHT,
    marginX: 20,
    marginY: 20,
    edgePadding: 100,
    rankPadding: 100,
    nodePadding: 50,
    curveDistance: 0,
    multigraph: true,
    compound: true,
  };
  settings: NetMapSettings = {};
  nodeHeight: number;
  nodeWidth: number;

  dagreGraph: any;
  dagreNodes: any;
  dagreEdges: any;
  graphRunning: boolean;
  dragCollection: ITopologyNode[];
  snapCollection: SnapResult[];
  guideCollection: ITopologyNode[];
  topologyGraph: Graph;
  lastDragPositionX: number;
  lastDragPositionY: number;
  dragInProgress: boolean;
  minSnapDistance: number;
  snapToGridEnabled: boolean = true;

  // Events
  GraphInitialized: EventEmitter<boolean> = new EventEmitter<boolean>();
  DevicesUpdated: EventEmitter<ITopologyNode[]> = new EventEmitter<ITopologyNode[]>();

  constructor(cs: CanvasService) {
    this.cs = cs;
    let dimensions = this.cs.getDeviceNodeSizes();
    this.nodeWidth = dimensions.width;
    this.nodeHeight = dimensions.height;
    this.minSnapDistance = 50;
    this.dragCollection = [];
    this.snapCollection = [];
    this.guideCollection = [];
    this.graphRunning = false;
    this.dragInProgress = false;
  }

  public run(graph: Graph): Graph {
    //Initialize the graph
    this.GraphInitialized.next(true);
    this.createDagreGraph(graph);
    dagre.layout(this.dagreGraph);
    graph.edgeLabels = this.dagreGraph._edgeLabels;

    // Copy Node position and dimensions over to the D3 Dagre Graph
    for (let dagreNodeId in this.dagreGraph._nodes) {
      try {
        let dagreNode = this.dagreGraph._nodes[dagreNodeId];
        let node = graph.nodes.find((n) => n.id === dagreNode.id) as DeviceNode;

        node.position = {
          x: node.defaultPosition.x,
          y: node.defaultPosition.y,
        };

        node.position["configured"] = true;
      } catch (error) {
        console.error(error);
      }

      // node.dimension = {
      //   width: this.nodeWidth,
      //   height: this.nodeHeight,
      // };
    }
    this.graphRunning = true;

    // Draw the connections
    for (let edge of graph.edges) {
      this.updateEdge(graph, edge);
    }

    //Signal event that graph is done loading
    this.GraphInitialized.next(true);
    this.topologyGraph = graph;
    return graph;
  }

  public onDragStart(draggingNode: Node, $event: PointerEvent): void {
    if ($event.button != 0) return;
    this.dragInProgress = true;
    if ($event.ctrlKey && this.isInDragCollection(<ITopologyNode>draggingNode)) {
      this.dragCollection = this.dragCollection.filter((m) => m.id != draggingNode.id);
      return;
    } else if ($event.ctrlKey && !this.isInDragCollection(<ITopologyNode>draggingNode)) {
      this.dragCollection.push(<ITopologyNode>draggingNode);
    } else if (!$event.ctrlKey && !this.isInDragCollection(<ITopologyNode>draggingNode)) {
      this.dragCollection = [<ITopologyNode>draggingNode];
    }

    this.lastDragPositionX = draggingNode.position.x;
    this.lastDragPositionY = draggingNode.position.y;
    this.updateGuideLines(<ITopologyNode>draggingNode);
    this.DevicesUpdated.next(this.guideCollection);
    this.snapCollection = [];
  }

  public onDrag(draggingNode: Node, $event: PointerEvent): void {
    if ($event.buttons != 1 || !draggingNode.position["configured"]) return;
    let deltaX = draggingNode.position.x - this.lastDragPositionX;
    let deltaY = draggingNode.position.y - this.lastDragPositionY;

    let shouldUpdateEdges = false;
    let devicesUpdated: ITopologyNode[] = [];

    for (let i = 0; i < this.dragCollection.length; i++) {
      if (this.dragCollection[i].id != draggingNode.id) {
        //Apply the change to all in the collection
        this.MoveNode(
          this.dragCollection[i],
          this.dragCollection[i].position.x + deltaX,
          this.dragCollection[i].position.y + deltaY
        );

        devicesUpdated.push(this.dragCollection[i]);
        shouldUpdateEdges = true;
      } else {
        this.updateGuideLines(this.dragCollection[i]);
      }
    }

    if (shouldUpdateEdges == true) {
      //this.DevicesUpdated.next(devicesUpdated);
    }

    devicesUpdated.push(...this.guideCollection);
    this.lastDragPositionX = draggingNode.position.x;
    this.lastDragPositionY = draggingNode.position.y;

    if (this.snapToGridEnabled == true) {
      this.UpdateSnapCollection(<ITopologyNode>draggingNode);
    }

    this.DevicesUpdated.next(devicesUpdated);
  }

  public onDragEnd(draggingNode: Node, $event: PointerEvent): void {
    if (this.dragInProgress && draggingNode) {
      if (this.snapToGridEnabled == true) {
        this.PerformSnap(<ITopologyNode>draggingNode);
      }

      //update default positions
      this.topologyGraph.nodes.forEach((node: ITopologyNode) => {
        node.defaultPosition.x = node.position.x;
        node.defaultPosition.y = node.position.y;
      });

      this.dragInProgress = false;
      this.snapCollection = [];
    }
  }

  public updateEdge(graph: Graph, edge: Edge): Graph {
    let sourceNode = graph.nodes.find((n) => n.id === edge.source);
    let targetNode = graph.nodes.find((n) => n.id === edge.target);
    let connectionDeviceMap = this.getConnectionsByDevice(graph.edges as ConnectionEdge[]);
    let connectionEdge = edge as ConnectionEdge;

    // Default start and end points are the center of the node
    let startingPoint = {
      x: sourceNode.position.x,
      y: sourceNode.position.y - this.nodeHeight * 0.125,
    };

    let endingPoint = {
      x: targetNode.position.x,
      y: targetNode.position.y - this.nodeHeight * 0.125,
    };

    // Draw connections next to each other along imaginary axis through the center of the node
    if (connectionDeviceMap.get(connectionEdge.groupUid)) {
      let connectionLine = this.getConnectionLine(
        this.calculateConnectionAxis(startingPoint, endingPoint),
        this.calculateConnectionAxis(endingPoint, startingPoint),
        connectionDeviceMap.get(connectionEdge.groupUid).indexOf(connectionEdge),
        connectionDeviceMap.get(connectionEdge.groupUid).length
      );

      edge.points = connectionLine.toArray();
    } else {
      edge.points = [startingPoint, endingPoint];
    }

    // Apply the new points to the graph
    let edgeLabelId: any = this.getEdgeLabel(graph.edgeLabels, edge as ITopologyEdge);
    if (edgeLabelId) {
      let matchingEdgeLabel = graph.edgeLabels[edgeLabelId];
      if (matchingEdgeLabel) {
        matchingEdgeLabel.points = edge.points;
      }
    }

    return graph;
  }

  public createDagreGraph(graph: Graph): any {
    let settings = Object.assign({}, this.defaultSettings, this.settings);
    this.dagreGraph = new dagre.graphlib.Graph({ compound: settings.compound, multigraph: settings.multigraph });
    this.dagreGraph.setGraph({
      rankdir: settings.orientation,
      marginx: settings.marginX,
      marginy: settings.marginY,
      edgesep: settings.edgePadding,
      ranksep: settings.rankPadding,
      nodesep: settings.nodePadding,
      align: settings.align,
      acyclicer: settings.acyclicer,
      ranker: settings.ranker,
      multigraph: settings.multigraph,
      compound: settings.compound,
    });

    // Default to assigning a new object as a label for each new edge.
    this.dagreGraph.setDefaultEdgeLabel(() => {
      return {
        /* empty */
      };
    });

    this.dagreNodes = graph.nodes.map((n) => {
      let node: any = Object.assign({}, n);
      node.width = this.nodeWidth;
      node.height = this.nodeHeight;
      node.x = n.position.x;
      node.y = n.position.y;
      return node;
    });

    this.dagreEdges = graph.edges.map((l) => {
      let linkId: number = 1;
      let newLink: any = Object.assign({}, l);
      if (!newLink.id) {
        newLink.id = linkId;
        linkId++;
      }
      return newLink;
    });

    for (let node of this.dagreNodes) {
      if (!node.width) {
        node.width = this.nodeWidth;
      }
      if (!node.height) {
        node.height = this.nodeHeight;
      }

      // update dagre
      this.dagreGraph.setNode(node.id, node);
    }

    // update dagre
    for (let edge of this.dagreEdges) {
      if (settings.multigraph) {
        this.dagreGraph.setEdge(edge.source, edge.target, edge, edge.id);
      } else {
        this.dagreGraph.setEdge(edge.source, edge.target);
      }
    }

    return this.dagreGraph;
  }

  private getConnectionsByDevice(edges: ConnectionEdge[]) {
    let deviceMap: Map<string, any[]> = new Map<string, ConnectionEdge[]>();

    //Group connections b the groupID
    edges
      .filter((edge: ConnectionEdge) => edge.type != "")
      .forEach((connection) => {
        if (connection.type == "GuideLine") {
          return;
        }

        if (deviceMap.has(connection.groupUid)) {
          deviceMap.get(connection.groupUid).push(connection);
        } else {
          deviceMap.set(connection.groupUid, [connection]);
        }
      });

    // Sort arrays by thier uid so they are always in the same order
    deviceMap.forEach((value, key) => {
      value.sort((a: any, b: any) => a.uid.localeCompare(b.uid));
    });

    return deviceMap;
  }

  // Edgelabels are a part of NGX graph and is how connections get drawn
  // This funtion maps an edge (connection from component) to an internal NGX graph line
  private getEdgeLabel(edgeLabels: any, edge: ITopologyEdge) {
    let designatedKey: any = null;

    if (!edgeLabels) return "";
    Object.keys(edgeLabels).forEach((key) => {
      if (edge.type == "GuideLine" && edgeLabels[key].source == edge.source && edgeLabels[key].target == edge.target) {
        designatedKey = key;
      } else if (
        edgeLabels[key].source == edge.source &&
        edgeLabels[key].target == edge.target &&
        edgeLabels[key].Device1InterfaceId == (<ConnectionEdge>edge).Device1InterfaceId &&
        edgeLabels[key].Device2InterfaceId == (<ConnectionEdge>edge).Device2InterfaceId
      ) {
        designatedKey = key;
      }
    });

    return designatedKey;
  }

  //Calculates line perpendicular to the displayed connection line. This is used to place multiple connections side by side.
  private calculateConnectionAxis(source: Point, target: Point): Line {
    let axisLength = 30;
    let geometry = new Geometry();
    let axisVector = geometry.getNormalVectorFromSlope(geometry.getReciprocol(geometry.getSlope(source, target)));
    let positivePoint = geometry.PointWithDistance(source, axisLength / 2, axisVector);
    let negativePoint = geometry.PointWithDistance(source, (axisLength / 2) * -1, axisVector);

    // Flip to ensure topmost point on the graph is P1 to prevent connections flipping over the axis
    if (positivePoint.y > negativePoint.y) {
      return new Line(negativePoint, positivePoint);
    } else {
      return new Line(positivePoint, negativePoint);
    }
  }

  // Takes the axis of each device and retrieves the coordinates for the connection line
  private getConnectionLine(sourceAxis: Line, targetAxis: Line, position: number, totalPositions: number) {
    let geometry = new Geometry();

    if (totalPositions == 1) {
      // Single connection simply uses the midpoint
      return new Line(
        { x: geometry.getMidpoint(sourceAxis).x, y: geometry.getMidpoint(sourceAxis).y },
        { x: geometry.getMidpoint(targetAxis).x, y: geometry.getMidpoint(targetAxis).y }
      );
    } else if (totalPositions == 2) {
      // Lines are placed on the extremes of the axis if there are only two options.
      if (position == 0) {
        return new Line(sourceAxis.P1, targetAxis.P1);
      } else {
        return new Line(sourceAxis.P2, targetAxis.P2);
      }
    } else {
      // calculate multiple connections using vector addition
      // Point + (PositionIndex)*(1/NumOfPositions)*AxisVector
      let sourceVector = geometry
        .getVectorFromLine(sourceAxis)
        .multiplyScalar(1 / (totalPositions - 1))
        .multiplyScalar(position);

      let targetVector = geometry
        .getVectorFromLine(targetAxis)
        .multiplyScalar(1 / (totalPositions - 1))
        .multiplyScalar(position);

      // Add the start point to the vector to get the start and end points of the connection line
      let sourcePoint = { x: sourceAxis.P1.x + sourceVector.x, y: sourceAxis.P1.y + sourceVector.y };
      let targePoint = { x: targetAxis.P1.x + targetVector.x, y: targetAxis.P1.y + targetVector.y };

      return new Line(sourcePoint, targePoint);
    }
  }

  addToDragCollection(node: DeviceNode) {
    let nodeFound = false;
    let nodeToRemove: ITopologyNode;

    for (let i = 0; i < this.dragCollection.length; i++) {
      if (this.dragCollection[i].id == node.id) {
        nodeFound = true;
        nodeToRemove = this.dragCollection[i];
        this.dragCollection = this.dragCollection.filter((m) => m.id == nodeToRemove.id);
        break;
      }
    }

    if (!nodeFound) {
      this.dragCollection.push(node);
    }
  }

  updateAllEdges() {
    for (let edge of this.topologyGraph.edges) {
      this.updateEdge(this.topologyGraph, edge);
    }
  }

  isInDragCollection(node: ITopologyNode) {
    return this.dragCollection.some((m) => m.id == node.id);
  }

  updateGuideLines(dev: ITopologyNode) {
    for (let i = 0; i < this.guideCollection.length; i++) {
      switch (this.guideCollection[i].id) {
        case "X1GuidePoint": {
          // this.nodes[i].position.x = dev.position.x - 250;
          // this.nodes[i].position.y = dev.position.y;
          this.guideCollection[i].position = { x: dev.position.x - 2000, y: dev.position.y };
          this.guideCollection[i].defaultPosition.x = dev.position.x - 2000;
          this.guideCollection[i].defaultPosition.y = dev.position.y;
          break;
        }
        case "X2GuidePoint": {
          this.guideCollection[i].position.x = dev.position.x + 2000;
          this.guideCollection[i].position.y = dev.position.y;
          this.guideCollection[i].defaultPosition.x = dev.position.x + 2000;
          this.guideCollection[i].defaultPosition.y = dev.position.y;
          break;
        }
        case "Y1GuidePoint": {
          this.guideCollection[i].position.x = dev.position.x;
          this.guideCollection[i].position.y = dev.position.y + 2000;
          this.guideCollection[i].defaultPosition.x = dev.position.x;
          this.guideCollection[i].defaultPosition.y = dev.position.y + 2000;
          break;
        }
        case "Y2GuidePoint": {
          this.guideCollection[i].position.x = dev.position.x;
          this.guideCollection[i].position.y = dev.position.y - 2000;
          this.guideCollection[i].defaultPosition.x = dev.position.x;
          this.guideCollection[i].defaultPosition.y = dev.position.y - 2000;
          break;
        }
        default: {
          break;
        }
      }

      this.MoveNode(this.guideCollection[i], this.guideCollection[i].position.x, this.guideCollection[i].position.y);
    }
  }

  MoveNode(node: ITopologyNode, x: number, y: number) {
    if (x == 0 && y == 0) {
      console.log("Move To zero");
    }
    node.position.x = x;
    node.position.y = y;
  }

  UpdateSnapCollection(draggingNode: ITopologyNode) {
    let elementsNotBeingDragged: ITopologyNode[] = <ITopologyNode[]>(
      this.topologyGraph.nodes.filter((m: ITopologyNode) => m.elementType != "guiElement")
    );

    let Results: SnapResult[];
    let xResults: SnapResult[];
    let yResults: SnapResult[];

    elementsNotBeingDragged = elementsNotBeingDragged.filter((m) => !this.dragCollection.some((n) => n.id == m.id));
    Results = elementsNotBeingDragged.map((node: ITopologyNode) => new SnapResult(draggingNode, node));

    // Filter and sort results by axis
    xResults = Results.filter((result) => result.BestAxis == "X" && result.BestDistance <= this.minSnapDistance);
    yResults = Results.filter((result) => result.BestAxis == "Y" && result.BestDistance <= this.minSnapDistance);

    // Insert the results
    this.snapCollection = [];
    if (xResults && xResults.length > 0) {
      this.snapCollection.push(xResults[0]);
    }

    if (yResults && yResults.length > 0) {
      this.snapCollection.push(yResults[0]);
    }
  }

  PerformSnap(draggingNode: ITopologyNode) {
    let deltaX: number;
    let deltaY: number;
    let aggregateSnapPoint: Point;

    // if no snap is possible short circuit
    if (this.dragInProgress == false || !draggingNode || !this.snapCollection || this.snapCollection.length == 0) {
      return;
    }

    // Aggregate into the best possible snap point
    if (this.snapCollection.length == 1) {
      aggregateSnapPoint = this.snapCollection[0].SnapLocation;
    } else {
      aggregateSnapPoint = { x: this.snapCollection[1].SnapLocation.x, y: this.snapCollection[0].SnapLocation.y };
    }

    //Calculate drag delta for the dragging collection
    deltaX = aggregateSnapPoint.x - draggingNode.position.x;
    deltaY = aggregateSnapPoint.y - draggingNode.position.y;

    //Apply the change to all in the collection
    for (let i = 0; i < this.dragCollection.length; i++) {
      if (this.dragCollection[i].id != draggingNode.id) {
        this.MoveNode(
          this.dragCollection[i],
          this.dragCollection[i].position.x + deltaX,
          this.dragCollection[i].position.y + deltaY
        );
      } else {
        this.MoveNode(draggingNode, aggregateSnapPoint.x, aggregateSnapPoint.y);
      }
    }

    this.DevicesUpdated.next(this.dragCollection);
  }
}
