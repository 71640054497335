import IHasInterfaces from "../../Shared/interfaces/IHasInterfaces";
import IInventoryDevice from "../Devices/IInventoryDevice";
import IInventoryInterface from "../Interfaces/IInventoryInterface";
import InventoryBriInterface from "../Interfaces/InventoryBriInterface";
import InventoryConsoleInterface from "../Interfaces/InventoryConsoleInterface";
import InventoryEthernetInterface from "../Interfaces/InventoryEthernetInterface";
import InventorySerialInterface from "../Interfaces/InventorySerialInterface";
import IAddon from "./IAddon";

export default class DefaultConfiguration implements IAddon {
  name: string;
  description: string;
  interfaces: IInventoryInterface[];
  giabitCount: number = 0;
  fastCount: number = 0;
  ethernetCount: number = 0;
  briCount: number = 0;
  serialCount: number = 0;
  addToDevice(device: IHasInterfaces, rack: number, slot: number) {
    device.interfaces.push(...this.interfaces.map((m) => this.connectionFactory(m)));
  }

  constructor() {
    this.name = "Default";
    this.description = "Default Ports for device";
    this.interfaces = [new InventoryConsoleInterface()];
  }

  addEthernetInterfaces(subType: string, count: number, racks: number, slots: number) {
    let numOfInterfaces: number = this.interfaces.length;
    let slotNumber: number = 0;
    for (let i = numOfInterfaces; i < numOfInterfaces + count; i++) {
      if (subType.toLowerCase().startsWith("gig")) {
        slotNumber = this.giabitCount;
        this.giabitCount++;
      } else if (subType.toLowerCase().startsWith("fast")) {
        slotNumber = this.fastCount;
        this.fastCount++;
      } else {
        slotNumber = this.ethernetCount;
        this.ethernetCount++;
      }

      this.interfaces.push(new InventoryEthernetInterface(subType, racks, 0, slotNumber));
    }
  }

  addSerialInterfaces(count: number, racks: number, slots: number) {
    let numOfInterfaces: number = this.interfaces.length;
    for (let i = numOfInterfaces; i < numOfInterfaces; i++) {
      this.serialCount++;
      this.interfaces.push(new InventorySerialInterface(racks, 0, this.serialCount));
    }
  }

  addBriInterfaces(count: number, racks: number, slots: number) {
    let numOfInterfaces: number = this.interfaces.length;
    for (let i = numOfInterfaces; i < numOfInterfaces + count; i++) {
      this.interfaces.push(new InventoryBriInterface(racks, 0, this.briCount));
      this.briCount++;
    }
  }

  connectionFactory(int: IInventoryInterface): IInventoryInterface {
    if (!int) return null;

    if (int.type == "Bri") {
      return new InventoryBriInterface(int.rack, int.slot, int.port);
    } else if (int.type == "Ethernet") {
      return new InventoryEthernetInterface(int["baseName"], int.rack, int.slot, int.port);
    } else if (int.type == "Serial") {
      return new InventorySerialInterface(int.rack, int.slot, int.port);
    } else if (int.type == "Console") {
      return new InventoryConsoleInterface();
    }

    return null;
  }
}
