import ConnectionEdge from "src/app/shared/models/TopologyCanvas/Edges/ConnectionEdge";
import SerialConnectionEdge from "src/app/shared/models/TopologyCanvas/Edges/SerialConnectionEdge";
import Connection from "./Connection";

export default class SerialConnection extends Connection {
  isDevice1Dce: boolean;

  constructor() {
    super();
    this.ConnectorType = "Serial";
    this.isDevice1Dce = true;
  }

  createConnectionEdge(): ConnectionEdge {
    let newEdge = new SerialConnectionEdge();
    newEdge.initializeFromConnection(this);
    newEdge.type = this.ConnectorType;
    return newEdge;
  }
}
