import { Guid } from "guid-typescript";
import ConnectionEdge from "src/app/shared/models/TopologyCanvas/Edges/ConnectionEdge";
import IConnection from "src/app/shared/models/TopologyCanvas/Interfaces/IConnection";

export default class Connection implements IConnection {
  uid: string;
  ConnectorType: string;
  Device1Id: string;
  Device1Name: string;
  Device1InterfaceName: string;
  Device1InterfaceId: string;
  Device2Id: string;
  Device2Name: string;
  Device2InterfaceId: string;
  Device2InterfaceName: string;

  constructor() {
    this.uid = Guid.create().toString();
  }

  createConnectionEdge(): ConnectionEdge {
    let newEdge = new ConnectionEdge();
    newEdge.initializeFromConnection(this);
    newEdge.type = this.ConnectorType;
    return newEdge;
  }
}
