import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter804 extends BaseInventoryRouter {
  constructor() {
    super("804", "800", 8, 12);
    this.maxAddons = 0;
    this.defaultInterfaces.addEthernetInterfaces("Ethernet", 1, null, this.maxAddons);
    this.defaultInterfaces.addBriInterfaces(1, null, this.maxAddons);
  }
}
