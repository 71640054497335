import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Edge, NodePosition } from "@swimlane/ngx-graph";
import DeleteConnectionMenuItem from "src/app/features/network-designer/models/Context Menu/DeleteConnectionMenuItem";
import IContextMenuItem from "src/app/features/network-designer/models/Context Menu/IContextMenuItem";
import { ContextMenuService } from "src/app/features/network-designer/services/context-menu.service";
import IConnection from "../Interfaces/IConnection";
import IConnectionEdge from "../Interfaces/IConnectionEdge";
import ITopologyEdge from "./TopologyEdge";

export default class ConnectionEdge implements IConnectionEdge {
  // Graph Values
  id?: string;
  source: string;
  target: string;
  label?: string;
  data?: any;
  points?: any;
  line?: string;
  textTransform?: string;
  textAngle?: number;
  oldLine?: any;
  oldTextPath?: string;
  textPath?: string;
  midPoint?: NodePosition;

  //NetSim Connection Values
  uid: string;
  groupUid: string;
  Device1Id: string;
  Device1Name: string;
  Device1InterfaceName: string;
  Device1InterfaceId: string;
  Device2Id: string;
  Device2Name: string;
  Device2InterfaceId: string;
  Device2InterfaceName: string;
  ConnectorType: string = "Standard";
  type: string;
  contextMenuItems: IContextMenuItem[];

  initializeFromConnection(conn: IConnection) {
    // Connection edges are also IConnections so clone necessary values
    this.uid = conn.uid;
    this.Device1Id = conn.Device1Id;
    this.Device2Id = conn.Device2Id;
    this.Device1Name = conn.Device1Name;
    this.Device2Name = conn.Device2Name;
    this.Device1InterfaceId = conn.Device1InterfaceId;
    this.Device2InterfaceId = conn.Device2InterfaceId;
    this.Device1InterfaceName = conn.Device1InterfaceName;
    this.Device2InterfaceName = conn.Device2InterfaceName;

    //Set the group ID
    let uidArray = [this.Device1Id, this.Device2Id];
    uidArray.sort();
    this.groupUid = uidArray.join("+");

    //set source and target for the UI
    this.source = this.Device1Id;
    this.target = this.Device2Id;
  }

  initializeContextMenu(cms: ContextMenuService) {
    this.contextMenuItems = [];
    this.contextMenuItems.push(new DeleteConnectionMenuItem(cms, this.uid));
  }

  addToSerializedTopology(doc: XMLDocument, parent?: HTMLElement) {
    let node = doc.createElement("Connection");
    this.serializeConnectionData(doc, node);

    if (parent) {
      parent.querySelectorAll(`Connections`).forEach((elem: HTMLElement) => {
        elem.appendChild(node);
      });
    }

    return node;
  }

  editConnection(ms: NgbModal) {
    return; //Standard connecitons cannot be edited
  }

  protected serializeConnectionData(doc: XMLDocument, elem: HTMLElement) {
    let dev1Node = doc.createElement("DeviceOne");
    let dev2Node = doc.createElement("DeviceTwo");
    let dev1InterfaceNode = doc.createElement("DeviceInterfaceOne");
    let dev2InterfaceNode = doc.createElement("DeviceInterfaceTwo");

    dev1Node.innerHTML = this.Device1Id;
    dev2Node.innerHTML = this.Device2Id;
    dev1InterfaceNode.innerHTML = this.Device1InterfaceId;
    dev2InterfaceNode.innerHTML = this.Device2InterfaceId;

    elem.appendChild(dev1Node);
    elem.appendChild(dev2Node);
    elem.appendChild(dev1InterfaceNode);
    elem.appendChild(dev2InterfaceNode);
  }
}
