import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  AfterViewInit,
  AfterContentChecked,
  HostListener,
} from "@angular/core";
import { PreferencesService } from "../../../preferences/services/preferences.service";
import { LabTreeService } from "../../../lab/services/lab-tree.service";
import { ConsoleService } from "src/app/features/console/services/console.service";
import { UserPreferences } from "../../../../shared/models/UserPreferences";
import { AppService } from "../../../../core/services/app.service";
import { switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { EngineService } from "src/app/engine/services/engine.service";
import OpenEditorResponse from "src/app/engine/models/OpenEditorResponse";

@Component({
  selector: "app-console",
  templateUrl: "./console.component.html",
  styleUrls: ["./console.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConsoleComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @Input() DeviceIndex: number;
  @Input() set injectedPreferences(v: UserPreferences) {
    this.UpdateConsolePreferences(v);
  }
  @Input() IsStandalone: boolean = false;
  @ViewChild("terminal") Terminal: ElementRef;

  Id: string;
  loading = false;
  BackgroundColor: string;
  ForegroundColor: string;
  FontFamily: string;
  FontSize: number;
  isTerminalVisible: boolean = false;

  // Control Keys
  ctrlIsDown: boolean;

  private handlers = new Array();

  constructor(
    private cs: ConsoleService,
    private ps: PreferencesService,
    private lts: LabTreeService,
    private as: AppService,
    private es: EngineService
  ) {
    if ("netsim" === environment.sessionApplication) {
      this.handlers.push(
        ps.PreferencesChangedObservable.subscribe((settings) => this.UpdateConsolePreferences(settings))
      );
    }

    this.handlers.push(cs.MessageRecievedObservable.subscribe((payload) => this.messageReceived(payload)));
  }
  ngAfterContentChecked(): void {
    if (this.Terminal && this.isTerminalVisible == false && this.Terminal.nativeElement.offsetParent != null) {
      this.isTerminalVisible = true;
      this.Terminal.nativeElement.focus();
    } else if (this.Terminal && this.isTerminalVisible == true && this.Terminal.nativeElement.offsetParent == null) {
      this.isTerminalVisible = false;
    }
  }

  ngOnInit() {
    this.handlers.push(
      this.lts.labExecuted.subscribe((labId: string) => {
        this.loading = true;
      })
    );

    this.handlers.push(
      this.lts.labFinished.subscribe((labId: string) => {
        this.loading = false;
      })
    );

    this.handlers.push(
      this.es.OpenEditorObservable.subscribe((response: OpenEditorResponse) => {
        if (response.DeviceNumber == this.DeviceIndex) {
          if (this.IsStandalone) {
            window.open(`/editor/${this.DeviceIndex}/hosts`, "_blank");
          } else {
            this.cs.openEditor(response);
          }
        }
      })
    );

    const labResumedHandler = this.lts.labResumed.subscribe((labId: string) => {
      this.loading = false;
      labResumedHandler.unsubscribe();
    });

    if ("netsim" === environment.sessionApplication) {
      if (this.as.CurrentUser) {
        this.handlers.push(
          this.ps.getCurrentPreferences().subscribe((resp) => {
            this.UpdateConsolePreferences(resp);
          })
        );
      } else {
        this.handlers.push(
          this.as.CurrentUser$.pipe(
            switchMap(() => {
              return this.ps.getCurrentPreferences();
            })
          ).subscribe((resp) => {
            this.UpdateConsolePreferences(resp);
          })
        );
      }
    }
  }

  ngAfterViewInit() {
    // console.log(`ng after view init for console`);
    try {
      const output = window.localStorage.getItem(`console_${this.DeviceIndex}`);

      if (output !== null) {
        this.Terminal.nativeElement.querySelector(".cursor").remove();
        this.Terminal.nativeElement.textContent += output;
        // console.log(`scroll height: ${this.Terminal.nativeElement.scrollHeight}`)
        this.Terminal.nativeElement.scrollTop = this.Terminal.nativeElement.scrollHeight;
        this.Terminal.nativeElement.insertAdjacentHTML("beforeEnd", '<span class="cursor">_</span>');
        setTimeout(() => {
          this.Terminal.nativeElement.scrollTop = this.Terminal.nativeElement.scrollHeight;
        }, 100);
      }
    } catch (error) {
      // log error
    }

    this.Terminal.nativeElement.focus();
  }

  focus() {
    // console.log(`focus() for console: ${this.Terminal.nativeElement.scrollHeight}`);
    this.Terminal.nativeElement.querySelector(".cursor").remove();
    this.Terminal.nativeElement.scrollTop = this.Terminal.nativeElement.scrollHeight;
    this.Terminal.nativeElement.insertAdjacentHTML("beforeEnd", '<span class="cursor">_</span>');
    this.Terminal.nativeElement.focus();
    setTimeout(() => {
      this.Terminal.nativeElement.scrollTop = this.Terminal.nativeElement.scrollHeight;
    }, 100);
  }

  unfocus() {
    // console.log(`unfocus() for console`);
    this.Terminal.nativeElement.querySelector(".cursor").remove();
  }

  mouseUp(event) {
    if ((event.button && event.button == 0) || (event.which && event.which == 1)) {
      if (typeof window.getSelection != "undefined") {
        const selection = window.getSelection().toString();
        if (selection.length > 0) {
          navigator.clipboard.writeText(window.getSelection().toString());
        }
      }
    }
  }

  private Awaiting: string[] = [];
  private AwaitingIndex: number;
  private PasteInProgress: boolean = false;

  rightClickPaste(event) {
    if (event != null) {
      event.preventDefault();
    }

    window.navigator.clipboard
      .readText()
      .then((clipText) => {
        let current = "";
        this.AwaitingIndex = 0;
        this.PasteInProgress = true;
        this.CurrentReceived = "";

        for (var i = 0; i < clipText.length; i++) {
          if (13 == clipText.charCodeAt(i)) {
            this.Awaiting.push(current);
            current = "";
          } else if (10 != clipText.charCodeAt(i)) {
            current += clipText.charAt(i);
          }
          if (10 != clipText.charCodeAt(i)) {
            this.sendKeyboardInput(clipText.charCodeAt(i));
          }
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  }

  ngOnDestroy() {
    this.handlers.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  UpdateConsolePreferences(consoleSettings: UserPreferences) {
    this.BackgroundColor = consoleSettings.consoleBackground;
    this.ForegroundColor = consoleSettings.consoleForeground;
    this.FontFamily = consoleSettings.consoleFontFamily;
    this.FontSize = consoleSettings.consoleFontSize;
  }

  private CurrentReceived: string = "";
  messageReceived(payload: string) {
    var message = payload;
    if (message.hasOwnProperty("DeviceIndex") && message["DeviceIndex"] == this.DeviceIndex) {
      var data: number[] = message["Data"];
      var stringData: string = String.fromCharCode(...data);
      //console.log(stringData);
      if (stringData == "\u001b[K") {
        // backspace
        this.Terminal.nativeElement.querySelector(".cursor").remove();
        this.Terminal.nativeElement.scrollTop = this.Terminal.nativeElement.scrollHeight;
        this.Terminal.nativeElement.insertAdjacentHTML("beforeEnd", '<span class="cursor">_</span>');
      } else if (stringData == "\u001b[2K") {
        // skip
      } else if (stringData == "\u001b[1D") {
        let textBefore = this.Terminal.nativeElement.querySelector(".cursor").previousSibling;
        let textAfter = this.Terminal.nativeElement.querySelector(".cursor").nextSibling;

        if (textAfter == null) {
          textAfter = document.createTextNode(textBefore.textContent[textBefore.textContent.length - 1]);
          textBefore.textContent = textBefore.textContent.substring(0, textBefore.textContent.length - 1);
          this.Terminal.nativeElement.querySelector(".cursor").after(textAfter);
        } else {
          textAfter.textContent = textBefore.textContent[textBefore.textContent.length - 1] + textAfter.textContent;
          textBefore.textContent = textBefore.textContent.substring(0, textBefore.textContent.length - 1);
        }
      } else if (stringData == "\u001b[1A") {
        // move to begin
        this.Terminal.nativeElement.textContent = this.Terminal.nativeElement.textContent.substring(
          0,
          this.Terminal.nativeElement.textContent.lastIndexOf("\r\n")
        );
        this.Terminal.nativeElement.insertAdjacentHTML("beforeEnd", '<span class="cursor">_</span>');
      } else {
        if (this.PasteInProgress) {
          for (let i = 0; i < stringData.length; i++) {
            if (stringData.charCodeAt(i) != 10 && stringData.charCodeAt(i) != 13) {
              this.CurrentReceived += stringData.charAt(i);
            }
          }
        }

        let newString = stringData;
        // for (let i = 0; i < stringData.length; i++) {
        //   if (stringData.charCodeAt(i) != 10 && stringData.charCodeAt(i) != 13) {
        //     newString += stringData.charAt(i);
        //   }
        // }

        this.Terminal.nativeElement.querySelector(".cursor").remove();
        if (
          this.PasteInProgress &&
          this.Awaiting.length > 0 &&
          this.AwaitingIndex < this.Awaiting.length &&
          this.CurrentReceived.endsWith(this.Awaiting[this.AwaitingIndex])
        ) {
          this.AwaitingIndex++;
          this.Terminal.nativeElement.textContent += newString + "\r\n";
          this.CurrentReceived = "";
        } else {
          this.Terminal.nativeElement.textContent += newString;
        }

        this.cacheConsoleContent();
        this.Terminal.nativeElement.scrollTop = this.Terminal.nativeElement.scrollHeight;
        this.Terminal.nativeElement.insertAdjacentHTML("beforeend", '<span class="cursor">_</span>');
      }
    }
  }

  cacheConsoleContent() {
    try {
      window.localStorage.setItem(`console_${this.DeviceIndex}`, this.Terminal.nativeElement.textContent);
    } catch (error) {
      // log error
    }
  }

  handleKeyPress(event) {
    event.preventDefault();

    this.sendKeyboardInput(event.which);
  }

  sendKeyboardInput(ascii) {
    this.cs.sendMessage(this.DeviceIndex, ascii);
  }

  handleInput(event) {
    event.preventDefault();

    if (event.data !== null) {
      this.sendKeyboardInput(event.data.toString().charCodeAt());

      // This next line is a hack for Android Virtual Keybord.
      this.Terminal.nativeElement.textContent = this.Terminal.nativeElement.textContent.substring(1);
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    const ctrlKeys: string[] = new Array(
      "KeyA",
      "KeyB",
      "KeyC",
      "KeyD",
      "KeyE",
      "KeyF",
      "KeyN",
      "KeyP",
      "KeyR",
      "KeyU",
      "KeyV",
      "KeyW",
      "KeyZ"
    );
    const specialKeys: string[] = ["Backspace", "Tab", "Delete", "ArrowLeft", "ArrowDown", "ArrowRight", "ArrowUp"];
    let asciiKeyCode: number;

    // Any key interrupts Paste
    this.PasteInProgress = false;

    if (this.escapeDown) {
      event.preventDefault();
      return;
    }

    // Chrome Virtual Keyboard does not properly support event.code. It instead uses the deprecated keyCode.
    if (event.code.length === 0 && event.keyCode !== 0) {
      const androidCtrlKeys: number[] = new Array(65, 66, 67, 68, 69, 70, 78, 80, 82, 85, 87, 90);
      const androidSpecialKeys: number[] = new Array(8, 9, 127, 2, 6);
      // I removed 16 and 14 from the list above. 16 is 'shift'; so having it in the list breaks the ability to capitalize a letter
      // on android virtual keyboard. Not sure why those two were in the list after thinking through this code block.

      if (13 === event.keyCode) {
        event.preventDefault();
        this.Terminal.nativeElement.querySelector(".cursor").remove();
        let currentContent: string = this.Terminal.nativeElement.textContent;
        if (!currentContent.endsWith("--MORE--")) {
          this.Terminal.nativeElement.textContent += "\r\n";
        }
        this.Terminal.nativeElement.insertAdjacentHTML("beforeEnd", '<span class="cursor">_</span>');
        asciiKeyCode = 13;
      } else if (event.ctrlKey === true && androidCtrlKeys.includes(event.keyCode)) {
        event.preventDefault();
        asciiKeyCode = event.keyCode - 64;
      } else if (androidSpecialKeys.includes(event.keyCode)) {
        event.preventDefault();
        asciiKeyCode = event.keyCode;
      }
    }

    if (["Enter", "NumpadEnter"].includes(event.code)) {
      event.preventDefault();

      this.Terminal.nativeElement.querySelector(".cursor").remove();
      let currentContent: string = this.Terminal.nativeElement.textContent;
      if (!currentContent.endsWith("--MORE--")) {
        this.Terminal.nativeElement.textContent += "\r\n";
      }
      this.Terminal.nativeElement.insertAdjacentHTML("beforeEnd", '<span class="cursor">_</span>');
      asciiKeyCode = 13;
    } else if (event.ctrlKey && event.shiftKey && event.code == "Digit6") {
      asciiKeyCode = 30;
    } else if ((event.ctrlKey || event.metaKey) && ctrlKeys.includes(event.code)) {
      event.preventDefault();
      switch (event.code) {
        case "KeyA":
          asciiKeyCode = 1;
          break;
        case "KeyB":
          asciiKeyCode = 2;
          break;
        case "KeyC":
          asciiKeyCode = 3;
          break;
        case "KeyD":
          asciiKeyCode = 4;
          break;
        case "KeyE":
          asciiKeyCode = 5;
          break;
        case "KeyF":
          asciiKeyCode = 6;
          break;
        case "KeyN":
          asciiKeyCode = 14;
          break;
        case "KeyP":
          asciiKeyCode = 16;
          break;
        case "KeyR":
          asciiKeyCode = 18;
          break;
        case "KeyU":
          asciiKeyCode = 21;
          break;
        case "KeyV":
          this.rightClickPaste(null);
          break;
        case "KeyW":
          asciiKeyCode = 23;
          break;
        case "KeyZ":
          asciiKeyCode = 26;
          break;
      }
    } else if (specialKeys.includes(event.code)) {
      event.preventDefault();
      switch (event.code) {
        case "Backspace":
          asciiKeyCode = 8;
          break;
        case "Tab":
          asciiKeyCode = 9;
          break;
        case "Delete":
          asciiKeyCode = 127;
          break;
        case "ArrowUp":
          asciiKeyCode = 16;
          break;
        case "ArrowDown":
          asciiKeyCode = 14;
          break;
        case "ArrowLeft":
          asciiKeyCode = 2;
          break;
        case "ArrowRight":
          asciiKeyCode = 6;
          break;
      }
    }

    if (asciiKeyCode != undefined) {
      this.cs.sendMessage(this.DeviceIndex, asciiKeyCode.toString());
    }
  }

  private escapeDown: boolean = false;

  @HostListener("window:keydown", ["$event"])
  keyDown(event: KeyboardEvent) {
    if (event.key.toLocaleLowerCase() === "escape") {
      this.escapeDown = true;
    }
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key.toLocaleLowerCase() === "escape") {
      this.escapeDown = false;
    }
  }
}
