import { Component, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "progress-spinner",
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .spinner {
        display: inline-block;
        height: 75px;
        width: 75px;
        background-image: url("./assets/images/netsim-spin.png");
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100%;
        -webkit-animation: spin 1.25s ease-in-out infinite;
        animation: spin 1.25s ease-in-out infinite;
        opacity: 0.5;
      }
      html[data-theme="dark"] .spinner {
        background-image: url("./assets/images/netsim-spin-white.png");
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(720deg);
        }
      }
    `,
  ],
  template: `<div class="spinner"></div>`,
})
export class ProgressComponent {
  constructor() {}
}
