import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-auto-login",
  template: "<p>Redirecting to login...</p>",
})
export class AutoLoginComponent implements OnInit {
  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    this.authService.login();
  }
}
