import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LabsService } from "./shared/services/labs.service";
import { UsersService } from "./shared/services/users.service";
import { LabDocumentComponent } from "./features/lab-document/components/lab-document/lab-document.component";
import { EngineService } from "./engine/services/engine.service";
import { AboutNetSimComponent } from "./shared/modals/about-netsim/about-netsim.component";
import { ConsoleComponent } from "./features/console/components/console/console.component";
import { PreferencesService } from "./features/preferences/services/preferences.service";
import { ConsoleService } from "./features/console/services/console.service";
import {
  AuthInterceptor,
  AuthModule,
  AuthWellKnownEndpoints,
  LogLevel,
  OidcConfigService,
  OidcSecurityService,
} from "angular-auth-oidc-client";
import { AuthService } from "./core/services/auth.service";
import { environment, LoadAuthenticationConfig } from "src/environments/environment";
import { AuthGuard } from "./core/guards/AuthGuard";
import { ForbiddenComponent } from "./core/components/forbidden/forbidden.component";
import { AutoLoginComponent } from "./core/components/auto-login/auto-login.component";
import { AppService } from "./core/services/app.service";
import { MiddleclickDirective } from "./shared/directives/middleclick.directive";
import { StandaloneConsoleComponent } from "./features/console/pages/standalone-console/standalone-console.component";
import { AuthLocalStorage } from "./core/models/AuthLocalStorage";
import { GettingStartedComponent } from "./shared/components/getting-started/getting-started.component";
import { GettingStartedModalComponent } from "./shared/modals/getting-started-modal/getting-started-modal.component";
import { NetMapComponent } from "./features/netmap/components/netmap/netmap.component";
import { NgxGraphModule } from "@swimlane/ngx-graph";
import { SharedModule } from "./shared/shared.module";
import { TabComponent } from "./shared/components/tab.component";
import { DesignerSettingsComponent } from "./features/preferences/components/designer-settings/designer-settings.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LabDocumentPreferencesComponent } from "./features/preferences/components/lab-document-preferences/lab-document-preferences.component";

import { LoadingService } from "./shared/services/loading.service";
import { NsxHostComponent } from "./features/nsx/components/nsx-host/nsx-host.component";
import { BosonTextEditorComponent } from "./features/boson-text-editor/components/boson-text-editor/boson-text-editor.component";
import { StandaloneBosonTextEditorComponent } from "./features/boson-text-editor/pages/standalone-boson-text-editor/standalone-boson-text-editor.component";
import { LabDocumentViewerComponent } from "./features/lab-document/components/lab-document-viewer/lab-document-viewer.component";

@NgModule({
  declarations: [
    AppComponent,
    LabDocumentComponent,
    AboutNetSimComponent,
    ForbiddenComponent,
    AutoLoginComponent,
    MiddleclickDirective,
    StandaloneConsoleComponent,
    GettingStartedComponent,
    GettingStartedModalComponent,
    NetMapComponent,
    StandaloneBosonTextEditorComponent,
    BosonTextEditorComponent,
    LabDocumentViewerComponent,
  ],
  imports: [
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    AuthModule.forRoot({ storage: AuthLocalStorage }),
    NgxGraphModule,
    SharedModule,
    BrowserAnimationsModule,
  ],
  providers: [
    AppService,
    LabsService,
    UsersService,
    EngineService,
    PreferencesService,
    ConsoleService,
    AuthService,
    OidcSecurityService,
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: LoadAuthenticationConfig,
      deps: [OidcConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AuthGuard,
    NgbActiveModal,
    LoadingService,
  ],
  exports: [ConsoleComponent],
  bootstrap: [AppComponent],
  entryComponents: [ConsoleComponent],
})
export class AppModule {}
