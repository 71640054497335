import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { LabsService } from "../../../../shared/services/labs.service";
import { EngineService } from "src/app/engine/services/engine.service";
import { environment } from "src/environments/environment";
import { LabTreeService } from "../../../lab/services/lab-tree.service";
import { parseString } from "xml2js";
import { GraphComponent } from "@swimlane/ngx-graph";
import * as shape from "d3-shape";
import { of, Subject } from "rxjs";
import { NetMapService } from "../../services/netmap.service";
import { ConsoleService } from "src/app/features/console/services/console.service";
import Device from "src/app/engine/models/Device";
import { switchMap, timeout } from "rxjs/operators";
import GetDevicesResponse from "src/app/engine/models/GetDevicesResponse";
import { AppService } from "../../../../core/services/app.service";
import Session from "../../../../shared/models/Session";
import Container from "../../../../shared/models/Container";
import { ContainersService } from "../../../../shared/services/containers.service";
import { BrowserCommunicationService } from "src/app/core/services/browser-communication.service";
import ConnectionEdge from "src/app/shared/models/TopologyCanvas/Edges/ConnectionEdge";
import { NetMapLayout } from "src/app/shared/models/TopologyCanvas/NetMapLayout";
import DeviceNode from "src/app/shared/models/TopologyCanvas/Nodes/DeviceNode";
import { CanvasService } from "src/app/features/network-designer/services/canvas.service";
import { TopologySerializationService } from "src/app/features/network-designer/services/topology-serialization.service";
import { UserFilesService } from "src/app/features/user-files/services/user-files.service";
import { CloudFileService } from "src/app/shared/services/cloud-file.service";
import { isNumeric } from "rxjs/internal-compatibility";
import { SessionsService } from "src/app/shared/services/sessions.service";
import { SvgGraphComponent } from "src/app/features/network-designer/components/svg-graph/svg-graph.component";
import ITopologyUI from "src/app/features/network-designer/models/Shared/interfaces/ITopologyUI";
import SelectionNode from "src/app/shared/models/TopologyCanvas/Nodes/SelectionNode";
import { ResizedEvent } from "src/app/shared/models/Events/ResizedEvent";

@Component({
  selector: "app-netmap",
  templateUrl: "./netmap.component.html",
  styleUrls: ["./netmap.component.scss"],
})
export class NetMapComponent implements OnInit, AfterViewInit, ITopologyUI {
  file: any = {};
  lastLabId: string;
  isExpired: boolean;
  isLabLoaded: Boolean = false;
  positionMap: Map<any, any> = new Map();
  interfaceMap: Map<any, any> = new Map();
  nodes: DeviceNode[] = [];
  edges: ConnectionEdge[] = [];
  data: any = {};
  topology: any;
  curve = shape.curveLinear;
  layout: NetMapLayout = new NetMapLayout(this.nds);
  devices: Device[];
  isStandaloneWindow: boolean = false;
  currentSession: Session;
  currentContainer: Container;
  topologyXml: string = null;
  postLoad: boolean = false;

  panX: number = 0;
  panY: number = 0;

  center$: Subject<boolean> = new Subject();
  update$: Subject<any> = new Subject<any>();
  zoomToFit$: Subject<boolean> = new Subject();
  panToNode$: Subject<string> = new Subject();

  @ViewChild("netmap") netMap: SvgGraphComponent;

  private handlers = new Array();

  constructor(
    private lts: LabTreeService,
    private ls: LabsService,
    private es: EngineService,
    private cs: ConsoleService,
    private bcs: BrowserCommunicationService,
    private containers: ContainersService,
    private route: ActivatedRoute,
    private ufs: UserFilesService,
    private title: Title,
    private ns: NetMapService,
    private as: AppService,
    private nds: CanvasService,
    private tss: TopologySerializationService,
    private ss: SessionsService
  ) {}

  ngAfterViewInit(): void {
    //this.centerGraph();
  }

  ngDoCheck() {
    if (this.postLoad == false && this.netMap && this.netMap.nodes && this.topologyXml) {
      try {
        this.tss.deserializeTopology(this, this.topologyXml);
        this.update$.next({ nodes: this.nodes, edges: this.edges });
        this.tss.defaultPanAndZoom(this, this.topologyXml);
        this.update$.next({ nodes: this.nodes, edges: this.edges });
      } catch (error) {
        console.error(error);
      }

      this.postLoad = true;
    }
  }

  ngOnInit(): void {
    // Initialize setup for standalone window
    if (window.location.pathname.startsWith("/netmap")) {
      this.title.setTitle(`Topology - ${environment.title}`);
      this.isStandaloneWindow = true;

      this.route.params.subscribe((params) => {
        if (params["id"]) {
          this.getTopologyDocument(params["id"])
            .pipe(
              switchMap((success: boolean) => {
                return this.as.CurrentSession$.first();
              }),
              switchMap((session: Session) => {
                this.currentSession = session;
                if (session.ContainerId) {
                  return this.containers.getContainer(session.ContainerId);
                } else {
                  return of("");
                }
              }),
              switchMap((container: Container) => {
                this.currentContainer = container;
                this.es.initializeWebSocketsForStandAlone(container);
                return this.es.getDevices();
              })
            )
            .subscribe((res: GetDevicesResponse) => {
              this.devices = res.Devices;
            });

          this.bcs.LabEndedObservable.subscribe((labId: string) => {
            window.close();
          });

          this.bcs.LabFinishedObservable.subscribe((labId: string) => {
            window.location.href = `./netmap/${labId}`;
          });
        }
      });
    }

    if (this.ns.fileToLoad) {
      this.ufs.getFile(this.as.CurrentUser.Id, this.ns.fileToLoad).subscribe((file: ArrayBuffer) => {
        var enc = new TextDecoder("utf-8");
        let bufferString = enc.decode(file).replace(/^\"/g, "").replace(/\"$/g, "");
        this.topologyXml = atob(bufferString);
        this.ns.fileToLoad = null;
      });
    }

    this.handlers.push(
      this.es.SessionExpiredObservable.subscribe((message: string) => {
        this.isExpired = true;
      })
    );

    // // Layout of the graph has been initialized. Give a moment to render then center the graph.
    // this.handlers.push(
    //   this.layout.GraphInitialized.subscribe((value: boolean) => {
    //     setTimeout(() => {
    //       this.centerGraph();
    //     }, 150);
    //   })
    // );

    // Hookup so external components can center the graph
    this.handlers.push(
      this.ns.CenterGraphObservable.subscribe((value) => {
        this.centerGraph();
      })
    );

    let topologyId =
      this.es.CurrentLabId && 0 < this.es.CurrentLabId.length ? this.es.CurrentLabId : this.es.CurrentFileId;
    //if (this.as.CurrentSession && this.as.CurrentSession.ContainerId && this.isStandaloneWindow == false && this.as.CurrentSession.UserFileId && 0 < this.as.CurrentSession.UserFileId.length) {
    if (this.isStandaloneWindow == false && topologyId && 0 < topologyId.length) {
      this.getTopologyDocument(topologyId).toPromise();
      this.devices = this.es.CurrentDevices;
    }
  }

  ngOnDestroy() {
    this.handlers.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  private getTopologyDocument(labId: string) {
    let piping;
    if (isNumeric(labId)) {
      piping = this.ufs.getUserFileTopology(labId);
    } else {
      piping = this.ls.getLabTopology(labId);
    }

    if (labId) {
      return piping.pipe(
        switchMap((res: any) => {
          // A lab has an explicit topology file and that *file* is returned when we request
          // the lab file asset.
          //
          // A user's file save could be a BSN, LAB, or TOP. BSN and LAB files do not have an explicit
          // topology file. The getUserFileTopology returns the XML encapsulated by the BSN or LAB
          // type.

          let xml;
          if (isNumeric(labId)) {
            // Topology XML must start with <?xml to be backward compatible.
            xml = res;
          } else {
            xml = res.Data;
          }

          this.topologyXml = xml;
          return of(true);
        })
      );
    } else {
      // No Lab Provided
      return of(false);
    }
  }

  public nodeDoubleClick(link: DeviceNode) {
    if (this.devices) {
      if (this.isStandaloneWindow == true) {
        this.devices
          .filter((m) => m.DeviceGuid == link.id)
          .forEach((dev: Device) => {
            window.open(`./console/${dev.DeviceNumber}`, "_blank");
          });
      } else {
        this.cs.OpenConsoles(this.devices.filter((m) => m.DeviceGuid == link.id));
      }
    }
  }

  updateGraph() {
    this.netMap.update();
    setTimeout(() => {
      this.netMap.panTo(0, 0);
    }, 25);
  }

  centerGraph() {
    if (this.netMap && this.netMap.initialized == true) {
      this.netMap.panTo(0, 0);
    } else {
      setTimeout(() => {
        this.centerGraph();
      }, 100);
    }
  }

  // ITopologyUI Members
  selectionSquare: SelectionNode;
  zoomTo(zoom: number) {
    if (this.netMap) {
      this.netMap.zoomTo(zoom);
    }
  }

  panTo(x: number, y: number) {
    if (this.netMap) {
      this.netMap.panTo(x, y);
    }
  }

  viewportResized(event: ResizedEvent) {
    if (this.topologyXml) {
      this.tss.defaultPanAndZoom(this, this.topologyXml);
    }
  }
}
