import IHasInterfaces from "../../Shared/interfaces/IHasInterfaces";
import IInventoryDevice from "../Devices/IInventoryDevice";
import InventoryEthernetInterface from "../Interfaces/InventoryEthernetInterface";
import IAddon from "./IAddon";

export default class SW2Eth implements IAddon {
  name: string = "SW-2-Eth";
  description: string = "2 Ethernet";

  addToDevice(device: IHasInterfaces, rack: number, slot: number) {
    for (let i = 0; i < 2; i++) {
      device.interfaces.push(new InventoryEthernetInterface("Ethernet", rack, slot, i));
    }
  }
}
