import { NodePosition, NodeDimension } from "@swimlane/ngx-graph";
import DeleteNodeMenuItem from "src/app/features/network-designer/models/Context Menu/DeleteNodeMenuItem";
import IContextMenuItem from "src/app/features/network-designer/models/Context Menu/IContextMenuItem";
import { ContextMenuService } from "src/app/features/network-designer/services/context-menu.service";
import BaseXmlSerialization from "../BaseXmlSerialization";
import ITextNode from "./ITextNode";
import { ITopologyNode } from "./ITopologyNode";

export default class NoteNode extends BaseXmlSerialization implements ITextNode {
  type: string;
  elementType: string;
  defaultPosition: { x: number; y: number };
  contextMenuItems: IContextMenuItem[];
  id: string;
  position?: NodePosition;
  dimension?: NodeDimension;
  transform?: string;
  label?: string;
  data?: any;
  meta?: any;
  text: string;
  multiLine: boolean;
  editInProgress: boolean = false;

  addToSerializedTopology(doc: XMLDocument, parent?: HTMLElement): HTMLElement {
    let returnNode: HTMLElement = null;

    if (parent) {
      parent.querySelectorAll(`NoteLookup`).forEach((elem: HTMLElement) => {
        this.addDeviceCoordinate(doc, elem, this);
      });

      parent.querySelectorAll(`Notes`).forEach((elem: HTMLElement) => {
        returnNode = this.addBlankTag(doc, elem, "NoteIO");
        this.addKeyValue(doc, returnNode, "ID", this.id);
        this.addKeyValue(doc, returnNode, "Text", this.text);
        this.addKeyValue(doc, returnNode, "Type", "0");
      });
    }

    return returnNode;
  }

  initializeContextMenu(cms: ContextMenuService) {
    this.contextMenuItems = [new DeleteNodeMenuItem(cms, this.id)];
  }
}
