export function CreateInterfaceName(name: string, rack: number, slot: number, port: number) {
  let location: string = "";

  if (rack != null && rack != undefined) {
    location += `${rack}/`;
  }

  if (slot != null && slot != undefined) {
    location += `${slot}/`;
  }

  location += `${port}`;
  return `${name}${location}`;
}
