import { ContextMenuService } from "src/app/features/network-designer/services/context-menu.service";
import DeviceNode from "src/app/shared/models/TopologyCanvas/Nodes/DeviceNode";
import IInventoryTreeNode from "../../../Tree/IInventoryTreeNode";
import DefaultConfiguration from "../../Addons/DefaultConfiguration";
import IAddon from "../../Addons/IAddon";
import { IInventoryConfiguration } from "../../Interfaces/IInventoryConfiguration";
import IInventoryInterface from "../../Interfaces/IInventoryInterface";
import BaseInventoryDevice from "../BaseInventoryDevice";
import IInventoryDevice from "../IInventoryDevice";

export default class BaseInventoryRouter extends BaseInventoryDevice implements IInventoryDevice, IInventoryTreeNode {
  Name: string;
  Children: IInventoryDevice[];
  AreChildrenCollapsed: boolean;
  IsHidden: boolean;
  deviceType: string = "Router";

  constructor(model: string, series: string, flash: number, systemMemory: number) {
    super(model, series, flash, systemMemory);

    this.Name = this.model;
    this.Children = [];
    this.AreChildrenCollapsed = false;
    this.IsHidden = false;
  }

  addToSerializedTopology(doc: XMLDocument, parent?: HTMLElement) {
    //Create node
    let node = doc.createElement("Router");
    this.serializeDeviceFields(doc, node);

    //Add Interface Fields
    node.appendChild(doc.createElement("Ethernet"));
    node.appendChild(doc.createElement("FastEthernet"));
    node.appendChild(doc.createElement("GigabitEthernet"));
    node.appendChild(doc.createElement("Serial"));
    node.appendChild(doc.createElement("Token"));
    node.appendChild(doc.createElement("Bri"));

    if (parent) {
      parent.querySelectorAll(`Routers`).forEach((elem: HTMLElement) => {
        elem.appendChild(node);
      });
    }

    return node;
  }

  createDeviceNode(configuration: IInventoryConfiguration, cms: ContextMenuService): DeviceNode {
    let newNode = new DeviceNode();
    newNode.type = this.deviceType;
    this.initializeDeviceNode(newNode, configuration, cms);
    return newNode;
  }
}
