import IHasInterfaces from "../../Shared/interfaces/IHasInterfaces";
import IInventoryDevice from "../Devices/IInventoryDevice";
import InventorySerialInterface from "../Interfaces/InventorySerialInterface";
import IAddon from "./IAddon";

export default class Hwic1Serial implements IAddon {
  name: string = "HWIC-1T";
  description: string = "1 Serial";

  addToDevice(device: IHasInterfaces, rack: number, slot: number) {
    device.interfaces.push(new InventorySerialInterface(rack, slot, 0));
  }
}
