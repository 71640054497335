import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  private OpenItemSource = new Subject<string>();
  // private OpenAllConsolesSource = new Subject();

  OpenItem$ = this.OpenItemSource.asObservable();
  // OpenAllConsoles$ = this.OpenAllConsolesSource.asObservable();

  constructor() {}

  OpenItem(name: string) {
    this.OpenItemSource.next(name);
  }

  // OpenAllConsoles() {
  //   this.OpenAllConsolesSource.next();
  // }
}
