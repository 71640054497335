import NM1Ethernet from "../../Addons/NM1Ethernet";
import NM1FastEthernet from "../../Addons/NM1FastEthernet";
import NM4ASSerial from "../../Addons/NM4ASSerial";
import NM4Bri from "../../Addons/NM4Bri";
import NM4Ethernet from "../../Addons/NM4Ethernet";
import NM4Serial from "../../Addons/NM4Serial";
import NM8ASSerial from "../../Addons/NM8ASSerial";
import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter3620 extends BaseInventoryRouter {
  constructor() {
    super("3620", "3600", 256, 512);
    this.maxAddons = 2;
    this.supportedAddons.push(
      new NM4Serial(),
      new NM4ASSerial(),
      new NM8ASSerial(),
      new NM1FastEthernet(),
      new NM4Ethernet(),
      new NM1Ethernet(),
      new NM4Bri()
    );
  }
}
