<div class="full h-100 w-100">
  <div class="container h-100">
    <h1>No Access</h1>
    <p class="mt-2">Your account does not have access to this content.</p>
    <p class="mt-4">
      Please visit
      <a href="https://boson.com/netsim-cisco-network-simulator#NetSimProductCards" target="_blank">www.boson.com</a>
      for more information.
    </p>
  </div>
</div>
