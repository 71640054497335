import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PreferencesService } from "src/app/features/preferences/services/preferences.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-about-netsim",
  templateUrl: "./about-netsim.component.html",
  styleUrls: ["./about-netsim.component.scss"],
})
export class AboutNetSimComponent implements OnInit {
  latestVersion: string;
  currentVersion: string;
  year: number = 0;
  darkModeActive = false;

  constructor(public activeModal: NgbActiveModal, private preferencesService: PreferencesService) {}

  ngOnInit() {
    this.latestVersion = environment.latestVersion;
    this.currentVersion = "14.0.0";
    this.year = new Date().getFullYear();

    this.darkModeActive = this.preferencesService.getIsDarkModeActive();

    this.preferencesService.SetToDarkModeObservable.subscribe((isDark: boolean) => {
      this.darkModeActive = isDark;
    });
  }

  ngOnDestroy() {}

  onClose() {
    this.activeModal.close();
  }
}
