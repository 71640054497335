import { ChangeDetectorRef, Component, DoCheck, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import LabelNode from "src/app/shared/models/TopologyCanvas/Nodes/LabelNode";

@Component({
  selector: "[app-label-node]",
  templateUrl: "./label-node.component.svg",
  styleUrls: ["./label-node.component.scss"],
})
export class LabelNodeComponent implements OnInit, DoCheck {
  //Fields
  @Input() node: LabelNode;
  @ViewChild("markupText") textEditor: ElementRef;

  textEditorLoaded: boolean = false;
  oldText: string;

  constructor(private changes: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngDoCheck(): void {
    if (this.textEditorLoaded == false && this.textEditor) {
      this.textEditorLoaded = true;
      this.setSVGDimensions();
      this.changes.detectChanges();
    }

    if (this.node.text != this.oldText) {
      this.oldText = this.node.text;
      if (this.textEditorLoaded == true) {
        this.setSVGDimensions();
      }
    }
  }

  setSVGDimensions() {
    let boundingBox = this.textEditor.nativeElement.getBBox();
    this.node.dimension.height = boundingBox.height;
    this.node.dimension.width = boundingBox.width;
  }
}
