import IHasInterfaces from "../../Shared/interfaces/IHasInterfaces";
import IInventoryDevice from "../Devices/IInventoryDevice";
import InventoryBriInterface from "../Interfaces/InventoryBriInterface";
import IAddon from "./IAddon";

export default class NM4Bri implements IAddon {
  name: string = "NM-4B-S/T";
  description: string = "4 Bri";

  addToDevice(device: IHasInterfaces, rack: number, slot: number) {
    for (let i = 0; i < 4; i++) {
      device.interfaces.push(new InventoryBriInterface(rack, slot, i));
    }
  }
}
