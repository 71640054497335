import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter1710 extends BaseInventoryRouter {
  constructor() {
    super("1710", "1700", 16, 64);
    this.maxAddons = 0;
    this.defaultInterfaces.addEthernetInterfaces("Ethernet", 1, null, null);
    this.defaultInterfaces.addEthernetInterfaces("FastEthernet", 1, null, null);
  }
}
