import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/core/services/app.service";
import User from "src/app/shared/models/User";

@Component({
  selector: "app-lab-document-viewer",
  templateUrl: "./lab-document-viewer.component.html",
  styleUrls: ["./lab-document-viewer.component.scss"],
})
export class LabDocumentViewerComponent implements OnInit {
  file: any = {};
  canAccessDocumentViewer: boolean = true;
  constructor(private aps: AppService) {}

  ngOnInit(): void {
    this.aps.CurrentUser$.subscribe((user) => this.setUserDependantSettings(user));

    if (this.aps.CurrentUser) {
      this.setUserDependantSettings(this.aps.CurrentUser);
    }
  }

  fileChanged(e) {
    this.file = e.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.file.Data = fileReader.result;
    };
    fileReader.readAsText(this.file);
  }

  reload() {
    location.reload();
  }

  private setUserDependantSettings(user: User) {
    this.canAccessDocumentViewer = user && user.Roles && user.Roles.some((m) => m == "edit_content");

    if (!this.canAccessDocumentViewer) {
      window.location.href = "/";
    }
  }
}
