import { AfterViewChecked, Component, HostBinding, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { EngineService } from "src/app/engine/services/engine.service";
import { environment } from "src/environments/environment";
import { LabsService } from "../../../../shared/services/labs.service";
import { LabTreeService } from "../../../lab/services/lab-tree.service";
import { BrowserCommunicationService } from "src/app/core/services/browser-communication.service";
import { PreferencesService } from "src/app/features/preferences/services/preferences.service";
import { UserPreferences } from "src/app/shared/models/UserPreferences";
import { AppService } from "src/app/core/services/app.service";
import Device from "src/app/engine/models/Device";
import { ConsoleService } from "src/app/features/console/services/console.service";
import { NavigationBarService } from "src/app/shared/services/navigation-bar.service";

@Component({
  selector: "app-lab-document",
  templateUrl: "./lab-document.component.html",
  styleUrls: ["./lab-document.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LabDocumentComponent implements OnInit, AfterViewChecked {
  @HostBinding("style.--textFontFamily") textFontFamily: string;
  @HostBinding("style.--codeFontFamily") codeFontFamily: string;
  @HostBinding("style.--codeFontSize") codeFontSize: string;
  @HostBinding("style.--textFontSize") textFontSize: string;
  @HostBinding("style.--documentContainerSize") documentContainerSize: string;

  @Input() file: any = {};
  lastLabId: string;
  isExpired: boolean;
  isLabLoaded: Boolean = false;
  isLabPostProcessingComplete: boolean = false;
  isStandalone: boolean = false;
  isPreview: boolean = false;
  isViewer: boolean = false;
  deviceMap: Map<string, Device> = new Map<string, Device>();

  private preferences: UserPreferences;

  private handlers = new Array();

  constructor(
    private aps: AppService,
    private lts: LabTreeService,
    private ls: LabsService,
    private es: EngineService,
    private bcs: BrowserCommunicationService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private ps: PreferencesService,
    private cs: ConsoleService,
    private nbs: NavigationBarService
  ) {}

  ngOnDestroy() {
    this.handlers.forEach((subscription) => {
      subscription.unsubscribe();
    });

    document.querySelectorAll("a.clickable-hostname").forEach((elem) => {
      elem.removeEventListener("click", this.openConsoleHandler);
    });
  }

  ngAfterViewChecked(): void {
    if (this.isLabPostProcessingComplete == false && this.preferences && this.file && this.file.Data) {
      this.editDocumentForWeb();
      this.isLabPostProcessingComplete = true;
    }
  }

  ngOnInit() {
    this.isExpired = false;

    if (window.location.pathname.startsWith("/document/viewer")) {
      this.isViewer = true;
      this.isStandalone = true;
      this.isPreview = false;
    } else {
      if (window.location.pathname.startsWith("/document")) {
        this.title.setTitle(`Lab Document - ${environment.title}`);
        this.isStandalone = true;

        this.bcs.LabEndedObservable.subscribe((labId: string) => {
          window.close();
        });

        this.bcs.LabExecutedObservable.subscribe((labId: string) => {
          window.location.href = "." + this.router.url.replace(this.lastLabId, labId);
        });

        this.route.params.subscribe((params) => {
          this.getLabDocument(params["id"]);
        });
      }

      if (window.location.pathname.startsWith("/document/preview/")) {
        this.isPreview = true;
      }

      this.handlers.push(
        this.es.SessionExpiredObservable.subscribe((message: string) => {
          this.isExpired = true;
        })
      );

      this.handlers.push(
        this.lts.labFinished.subscribe((labId: string) => {
          this.getLabDocument(labId);
        })
      );

      this.handlers.push(
        this.lts.labResumed.subscribe((labId: string) => {
          this.getLabDocument(labId);
        })
      );

      if (this.es.CurrentLabId && 0 < this.es.CurrentLabId.length) {
        this.lastLabId = this.es.CurrentLabId;
        this.getLabDocument(this.es.CurrentLabId);
      }
    }

    this.handlers.push(
      this.ps.getCurrentPreferences().subscribe((preferences: UserPreferences) => {
        this.configurePreferences(preferences);
      })
    );

    this.handlers.push(
      this.ps.PreferencesChangedObservable.subscribe((preferences: UserPreferences) => {
        this.configurePreferences(preferences);
      })
    );

    this.handlers.push(this.ps.SetToDarkModeObservable.subscribe((isDark: boolean) => {}));
  }

  displayOneElement(html: string, selector: string) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    let wrapperDiv = doc.createElement("div");
    let header = doc.createElement("h1");
    let elements;
    this.aps.setNavbarAsHidden(true);
    wrapperDiv.classList.add("d-flex", "flex-column", "justify-content-center", "document-body", "single-element");

    if (selector == "#address-table") {
      elements = this.getAddressTables(doc);
    } else {
      elements = doc.querySelectorAll(selector);
    }

    try {
      if (elements.length == 0) return null;

      while (doc.lastChild) {
        doc.removeChild(doc.lastChild);
      }

      switch (selector) {
        case "#address-table":
          header.innerHTML = "IP Addresses";
          break;
        case "#topology-image, #dark-topology-image":
          header.innerHTML = "Topology";
          break;
        case "#command-summary":
          header.innerHTML = "Command Summary";
          break;
        default:
          header.innerHTML = "";
          break;
      }

      wrapperDiv.appendChild(header);
      header.classList.add("align-self-center");

      elements.forEach((elem: HTMLElement) => {
        elem.classList.add("align-self-center");
        wrapperDiv.appendChild(elem);
      });

      doc.appendChild(wrapperDiv);
      return doc.documentElement.outerHTML;
    } catch (error) {
      return null;
    }
  }

  getAddressTables(doc: Document) {
    let addressHeader = doc.getElementById("addresses-header");
    let tasksSection = doc.getElementById("task-section");

    if (!addressHeader) {
      addressHeader = doc.getElementById("command-table");
    }

    if (addressHeader && tasksSection) {
      var filter = Array.prototype.filter,
        result = doc.querySelectorAll(".divTable"),
        filtered = filter.call(result, function (node) {
          return (
            addressHeader.compareDocumentPosition(node) & Node.DOCUMENT_POSITION_FOLLOWING &&
            tasksSection.compareDocumentPosition(node) & Node.DOCUMENT_POSITION_PRECEDING
          );
        });

      return filtered;
    } else {
      return doc.querySelectorAll("#address-table");
    }
  }

  linkCodeBlocksToDevices() {
    //Map devices to thier device name
    this.deviceMap = new Map<string, Device>();
    this.es.CurrentDevices.forEach((element) => {
      this.deviceMap.set(element.DeviceName, element);
    });

    let match: RegExpMatchArray;
    let hostnameRegex = new RegExp(/^(\w|[_])+(?=(\(.+?\))?(#|>|&gt;))/);

    //Search relevant sections for code lines
    document.querySelectorAll("#task-section,#solution-section").forEach((section: HTMLElement) => {
      section.querySelectorAll("span.code-block>span.code").forEach((code: HTMLElement) => {
        match = code.innerHTML.match(hostnameRegex);
        if (match && this.deviceMap.has(match[0].toString())) {
          //Create the clickable element that will replace the plain text
          let clickableElement = this.createClickableHostName(match[0].toString(), code, hostnameRegex);
          clickableElement.addEventListener("click", this.openConsoleHandler);
          clickableElement["openConsole"] = () => {
            this.cs.OpenConsoles([this.deviceMap.get(clickableElement.innerHTML)]);
          };
          clickableElement.classList.add("valid-hostname");
          clickableElement.setAttribute("title", "Click here to open console.");
        } else if (match) {
          //Create element with invalid hostname
          let clickableElement = this.createClickableHostName(match[0].toString(), code, hostnameRegex);
          clickableElement.classList.add("invalid-hostname");
          clickableElement.setAttribute("title", "No matching device was found.");
        }

        match = null;
      });
    });
  }

  private openConsoleHandler(event) {
    event.target.openConsole();
  }

  private createClickableHostName(text: string, code: HTMLElement, re: RegExp) {
    let clickableElement = document.createElement("a");
    clickableElement.innerHTML = text;
    clickableElement.classList.add("clickable-hostname");
    code.innerHTML = code.innerHTML.replace(re, "");
    code.prepend(clickableElement);
    return clickableElement;
  }

  configurePreferences(preferences: UserPreferences) {
    this.preferences = preferences;

    // set fonts
    this.textFontFamily = preferences.documentTextFontFamily;
    this.codeFontFamily = preferences.documentCodeFontFamily;

    //set text font size headers scale to text size
    this.textFontSize = `${preferences.documentTextFontSize}pt`;

    //set code font sizes
    this.codeFontSize = `${preferences.documentCodeFontSize}pt`;

    //set document width
    switch (preferences.documentContainerSize) {
      case "container": {
        this.documentContainerSize = `62.5rem`;
        break;
      }
      case "narrow": {
        this.documentContainerSize = `43.75rem`;
        break;
      }
      default: {
        this.documentContainerSize = `100%`;
        break;
      }
    }
  }

  public reloadLab() {
    this.lts.executeLab(this.lastLabId);
  }

  private getLabDocument(labId: string) {
    this.lastLabId = labId;
    if (labId) {
      this.ls.getLabDocument(labId).subscribe((res: any) => {
        //Display reference materials
        let references = {
          "/topology": "#topology-image, #dark-topology-image",
          "/command-summary": "#command-table",
          "/ip-addresses": "#address-table",
        };

        for (let key of Object.keys(references)) {
          if (this.router.url.endsWith(key)) {
            res.Data = this.displayOneElement(res.Data, references[key]);
            break;
          }
        }

        this.file = res;
        this.addTargetAttribute();
        this.notifyMainMenuOfReferences(references);
        this.isLabLoaded = true;
        this.isLabPostProcessingComplete = false;
      });
    }
  }

  private notifyMainMenuOfReferences(references: any) {
    let doc = new DOMParser().parseFromString(this.file.Data, "text/html");
    let addresses = this.getAddressTables(doc);
    let validPaths: string[] = [];

    for (let key of Object.keys(references)) {
      if (doc.querySelector(references[key])) {
        validPaths.push(key);
      } else if (references[key] == "#address-table" && addresses && addresses.length > 0) {
        validPaths.push(key);
      }
    }

    this.nbs.updateReferences(validPaths);
  }

  private addTargetAttribute() {
    const labDocument = document.createElement("div");
    labDocument.innerHTML = this.file.Data;
    // * Sets target attribute for each anchor tag.
    Array.from(labDocument.querySelectorAll("a")).forEach((e) => {
      e.setAttribute("target", "_blank");
    });
    this.file.Data = labDocument.innerHTML;
  }

  private async editDocumentForWeb() {
    let gradeNote = document.getElementById("grade-note");
    let topologyImage = document.getElementById("topology-image");
    let darkTopologyImage = document.getElementById("dark-topology-image");
    let logoImage = document.getElementById("logo-image");

    // Set the dark mode logo
    if (logoImage) {
      let darkImage = <HTMLElement>logoImage.cloneNode();
      logoImage.after(darkImage);
      logoImage.setAttribute("src", "/assets/images/NetSim-Network-Simulator.svg");
      logoImage.classList.add("light-mode-only");
      darkImage.setAttribute("src", "/assets/images/NetSim-Network-Simulator-DarkMode.png");
      darkImage.classList.add("dark-mode-only");
    }

    // Set dark mode topology image
    if (topologyImage && darkTopologyImage) {
      topologyImage.classList.add("light-mode-only");
      darkTopologyImage.classList.add("dark-mode-only");
    }

    // Set Dark Mode images
    if (this.ps.getIsDarkModeActive() == true) {
      // if there is not a dedicated dark mode replace black with white to increase contrast
      if (topologyImage && !darkTopologyImage) {
        topologyImage.setAttribute(
          "src",
          await this.ps.SwapImageColors(topologyImage.getAttribute("src"), "#000000", "#ffffff")
        );
      }
    }

    if (!gradeNote) {
      return;
    }

    gradeNote.innerHTML = "";
    gradeNote.appendChild(
      this.createTextSpan(
        "Once you have completed this lab, be sure to check your work by using the grading function. You can do so by clicking"
      )
    );
    gradeNote.appendChild(this.createBoldText("Lab", ["ml-1"]));
    gradeNote.appendChild(this.createTextSpan("", ["mdi", "mdi-arrow-right", "grade-arrow", "ml-1", "mr-1"]));
    gradeNote.appendChild(this.createBoldText("Grade Lab", ["mr-1"]));
    gradeNote.appendChild(this.createTextSpan("from the main menu."));

    if (this.isStandalone == false) {
      this.linkCodeBlocksToDevices();
    }

    if (this.preferences.documentShowPreview && this.isPreview == false) {
      try {
        this.linkLabPreviews();
      } catch (error) {
        console.error(error);
      }
    }

    this.isLabPostProcessingComplete = true;
  }

  linkLabPreviews() {
    let labTasks = document.getElementById("task-section");
    let labSolutions = document.getElementById("solution-section");
    let taskMap = this.mapAllTasks(labTasks);
    let solutionMap = this.mapAllTasks(labSolutions);
    if (labTasks && labSolutions) {
      for (let key of Object.keys(taskMap)) {
        taskMap[key].forEach((value: HTMLElement, step: number) => {
          if (solutionMap[key] && solutionMap[key].has(step)) {
            this.createTaskPreview(taskMap[key].get(step), solutionMap[key].get(step), `preview-t${key}s${step}`);
          }
        });
      }
    }
  }

  private createTaskPreview(taskElement: HTMLElement, solutionElement: HTMLElement, id: string) {
    // Create the peak element
    let peek = document.createElement("div");
    peek.setAttribute("id", id);
    peek.classList.add("collapse", "solution-preview");
    peek.innerHTML = solutionElement.innerHTML;
    taskElement.classList.add("position-relative");
    taskElement.appendChild(peek);

    // Create the toggle button
    let displaySolution = document.createElement("div");
    let displayLink = document.createElement("span");
    displaySolution.classList.add("preview-solution-float");
    displaySolution.appendChild(displayLink);
    displayLink.appendChild(this.createTextSpan("", ["mdi", "mdi-eye-minus-outline"]));
    displayLink.classList.add("collapsed");
    displayLink.setAttribute("data-toggle", "collapse");
    displayLink.setAttribute("data-target", `#${id}`);
    displayLink.setAttribute("title", "Show Solution");
    taskElement.prepend(displaySolution);
  }

  private mapAllTasks(section: HTMLElement) {
    let map = {};
    let numOfTasks: number = 0;

    // iterate through  task headers mapping the steps
    section.querySelectorAll(".task-header").forEach((elem: HTMLElement) => {
      numOfTasks++;
      let regexResult = /^\s*[Tt]ask\s*(\d+)/.exec(elem.innerHTML);
      if (regexResult && regexResult.length > 1) {
        map[regexResult[1].toString()] = this.mapSingleTask(this.findListForTask(elem));
      }
    });

    // Some Labs have no tasks just a list of steps
    if (numOfTasks == 0) {
      map["1"] = this.mapSingleTask(this.findListForTask(<HTMLElement>section.firstElementChild));
    }

    return map;
  }

  private mapSingleTask(taskList: HTMLElement): Map<number, HTMLElement> {
    let stepMap = new Map<number, HTMLElement>();

    // map step number to HTML element
    if (taskList) {
      taskList.querySelectorAll("ol:not([type])>li").forEach((elem, index) => {
        if (elem.parentElement.classList.length > 0) return;

        stepMap.set(index, <HTMLElement>elem);
      });
    }

    return stepMap;
  }

  private findListForTask(taskElem: HTMLElement): HTMLElement {
    if (!taskElem) {
      return null;
    }

    let nextNode = <HTMLElement>taskElem.nextElementSibling;

    // Search for the next valid sibling
    while (nextNode) {
      if (nextNode.tagName == "OL") {
        return nextNode;
      } else if (nextNode.tagName != "P") {
        return null;
      } else {
        nextNode = <HTMLElement>nextNode.nextElementSibling;
      }
    }

    return nextNode;
  }

  private createTextSpan(text: string = "", classes: string[] = []): HTMLElement {
    let textSpan = document.createElement("span");
    return this.initializeTextElement(textSpan, text, classes);
  }

  private createBoldText(text: string = "", classes: string[] = []): HTMLElement {
    let textStrong = document.createElement("strong");
    return this.initializeTextElement(textStrong, text, classes);
  }

  private initializeTextElement(elem: HTMLElement, text: string = "", classes: string[] = []): HTMLElement {
    elem.innerText = text;
    classes.forEach((spanClass: string) => elem.classList.add(spanClass));
    return elem;
  }
}
