import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Guid } from "guid-typescript";
import { Observable, Subject } from "rxjs";
import Connection from "src/app/engine/models/Connection";
import Point from "src/app/shared/models/math/Point";
import ConnectionEdge from "src/app/shared/models/TopologyCanvas/Edges/ConnectionEdge";
import IConnection from "src/app/shared/models/TopologyCanvas/Interfaces/IConnection";
import DeviceNode from "src/app/shared/models/TopologyCanvas/Nodes/DeviceNode";
import { ITopologyNode } from "src/app/shared/models/TopologyCanvas/Nodes/ITopologyNode";
import DeleteNodeMenuItem from "../models/Context Menu/DeleteNodeMenuItem";
import EditDeviceMenuItem from "../models/Context Menu/EditDeviceMenuItem";
import IInventoryDevice from "../models/Inventory/Devices/IInventoryDevice";
import { IInventoryConfiguration } from "../models/Inventory/Interfaces/IInventoryConfiguration";
import IInventoryInterface from "../models/Inventory/Interfaces/IInventoryInterface";
import ConnectionParameters from "../models/Shared/Parameters/ConnectionParameters";
import DroppedDeviceParams from "../models/Shared/Parameters/DroppedDeviceParams";
import DroppedItemParams from "../models/Shared/Parameters/DroppedItemParams";
import { ContextMenuService } from "./context-menu.service";

@Injectable({
  providedIn: "root",
})
export class CanvasService {
  // Fields
  routerCount: number = 0;
  switchCount: number = 0;
  phoneCount: number = 0;
  hostCount: number = 0;
  currentConnection: Connection;
  currentDevice: DeviceNode;

  //Observables
  private connectionStartedSubject: Subject<ConnectionParameters> = new Subject<ConnectionParameters>();
  connectionStarted$: Observable<ConnectionParameters> = this.connectionStartedSubject.asObservable();

  private connectSecondInterfaceSubject: Subject<ConnectionParameters> = new Subject<ConnectionParameters>();
  connectSecondInterface$: Observable<ConnectionParameters> = this.connectSecondInterfaceSubject.asObservable();

  private connectionAddedSubject: Subject<Connection> = new Subject<Connection>();
  connectionAdded$: Observable<Connection> = this.connectionAddedSubject.asObservable();

  private connectionLoadedSubject: Subject<IConnection[]> = new Subject<IConnection[]>();
  connectionLoaded$: Observable<IConnection[]> = this.connectionLoadedSubject.asObservable();

  private connectionEditedSubject: Subject<IConnection> = new Subject<IConnection>();
  connectionEdited$: Observable<IConnection> = this.connectionEditedSubject.asObservable();

  private deviceConfigurationCreatedSubject: Subject<IInventoryConfiguration> = new Subject<IInventoryConfiguration>();
  deviceConfigurationCreated$: Observable<IInventoryConfiguration> =
    this.deviceConfigurationCreatedSubject.asObservable();

  private deviceConfigurationEditedSubject: Subject<IInventoryConfiguration> = new Subject<IInventoryConfiguration>();
  deviceConfigurationEdited$: Observable<IInventoryConfiguration> =
    this.deviceConfigurationEditedSubject.asObservable();

  private dropOnCanvasSubject: Subject<DroppedItemParams> = new Subject<DroppedItemParams>();
  dropOnCanvas$: Observable<DroppedItemParams> = this.dropOnCanvasSubject.asObservable();

  private inventoryDroppedSubject: Subject<DroppedDeviceParams> = new Subject<DroppedDeviceParams>();
  inventoryDropped$: Observable<DroppedDeviceParams> = this.inventoryDroppedSubject.asObservable();

  private addDeviceNodeSubject: Subject<DeviceNode> = new Subject<DeviceNode>();
  addDeviceNode$ = this.addDeviceNodeSubject.asObservable();

  private newDocumentSubject: Subject<any> = new Subject<any>();
  newDocument$ = this.newDocumentSubject.asObservable();

  private loadDocumentSubject: Subject<any> = new Subject<any>();
  loadDocument$ = this.loadDocumentSubject.asObservable();

  constructor(private cms: ContextMenuService) {}

  connectionEdgesLoaded(connections: ConnectionEdge[]) {
    this.connectionLoadedSubject.next(connections);
  }

  getDeviceNodeSizes(): { height: number; width: number } {
    return { height: 70, width: 70 };
  }

  notifyNodeConnectionStarted(device: DeviceNode, edges: ConnectionEdge[]) {
    this.currentDevice = device;
    this.connectionStartedSubject.next({ currentConnection: null, interfaces: device.interfaces, edges: edges });
  }

  notifySelectSecondInterface(device: DeviceNode, edges: ConnectionEdge[]) {
    this.currentConnection.Device2Id = device.id;
    this.currentConnection.Device2Name = device.label;
    this.connectSecondInterfaceSubject.next({
      currentConnection: this.currentConnection,
      interfaces: device.interfaces,
      edges: edges,
    });
  }

  notifyCanvasDrop(params: DroppedItemParams) {
    this.dropOnCanvasSubject.next(params);
  }

  notifyInventoryDropped(params: DroppedDeviceParams) {
    this.inventoryDroppedSubject.next(params);
  }

  selectConnectionInterface(int: IInventoryInterface, isFirstInterface: boolean) {
    if (isFirstInterface) {
      this.currentConnection = int.createConnection();
      this.currentConnection.Device1Id = this.currentDevice.id;
      this.currentConnection.Device1Name = this.currentDevice.label;
      this.currentConnection.Device1InterfaceId = int.id;
      this.currentConnection.Device1InterfaceName = int.name;
    } else {
      this.currentConnection.Device2InterfaceId = int.id;
      this.currentConnection.Device2InterfaceName = int.name;
      this.connectionAddedSubject.next(this.currentConnection);
    }
  }

  notifyDeviceCreated(conf: IInventoryConfiguration) {
    this.deviceConfigurationCreatedSubject.next(conf);
  }

  getNewDeviceName(deviceType: string): string {
    switch (deviceType) {
      case "Router":
        return `Router${this.routerCount + 1}`;
      case "Switch":
        return `Switch${this.switchCount + 1}`;
      case "Phone":
        return `Phone${this.phoneCount + 1}`;
      case "Host":
        return `Host${this.hostCount + 1}`;
      default:
        return "New Device";
    }
  }

  updateNewDeviceName(deviceType: string, name: string) {
    // default name was not used short circuit
    if (name != this.getNewDeviceName(deviceType)) {
      return;
    }

    switch (deviceType) {
      case "Router":
        this.routerCount++;
        break;
      case "Switch":
        this.switchCount++;
        break;
      case "Phone":
        this.phoneCount++;
        break;
      case "Host":
        this.hostCount++;
        break;
      default:
        return;
    }
  }

  syncDeviceName(nodes: ITopologyNode[]) {
    let testName: string;
    let searchNode: ITopologyNode;
    for (let i = 0; i < nodes.length; i++) {
      testName = this.getNewDeviceName(nodes[i].type);
      searchNode = nodes.find((m) => m.label == testName);

      if (searchNode != null && searchNode != undefined && testName != "New Device") {
        this.updateNewDeviceName(nodes[i].type, testName);
        i = -1;
        continue;
      }
    }
  }

  resetDeviceNames() {
    this.routerCount = 0;
    this.switchCount = 0;
    this.hostCount = 0;
    this.phoneCount = 0;
  }

  deleteConnection(id: string) {
    this.cms.deleteConnection(id);
  }

  editConnection(connection: IConnection) {
    this.connectionEditedSubject.next(connection);
  }

  editDevice(config: IInventoryConfiguration, id: string, name: string) {
    let node = this.createDeviceNodeFromConfig(config, name, id);
    this.addDeviceNodeSubject.next(node);
    this.deviceConfigurationEditedSubject.next(config);
  }

  addDeviceToCanvas(config: IInventoryConfiguration, name: string, isNewConfig: boolean) {
    let node = this.createDeviceNodeFromConfig(config, name);

    if (isNewConfig == true) {
      this.notifyDeviceCreated(config);
    }

    this.updateNewDeviceName(config.inventoryDevice.deviceType, name);
    this.addDeviceNodeSubject.next(node);
  }

  createDeviceNodeFromConfig(config: IInventoryConfiguration, name: string, id?: string): DeviceNode {
    let node = config.inventoryDevice.createDeviceNode(config, this.cms);
    let dimensions = this.getDeviceNodeSizes();
    node.id = id == undefined ? Guid.create().toString() : id;
    node.label = name;
    node.dimension = dimensions;
    node.contextMenuItems = [new EditDeviceMenuItem(this.cms, node.id), new DeleteNodeMenuItem(this.cms, node.id)];
    return node;
  }

  isTheSameDevice(id: string): boolean {
    return this.currentDevice && this.currentDevice.id == id;
  }

  createNewDocument() {
    this.newDocumentSubject.next(true);
  }

  loadDocument(xml: string) {
    this.loadDocumentSubject.next(xml);
  }
}
