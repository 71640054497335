import { Observable } from "rxjs";

export class ProgressItem {
  action: string;
  status: string;
  startCallable: Observable<unknown>;
  endCallable: Observable<unknown>;

  constructor(action: string, status: string, startCallable: Observable<unknown>, endCallable: Observable<unknown>) {
    this.action = action;
    this.status = status;
    this.startCallable = startCallable;
    this.endCallable = endCallable;

    if (this.startCallable) {
      const subscription = this.startCallable.subscribe(() => {
        if (this.status == "todo") {
          this.status = "in_progress";
        }
        subscription.unsubscribe();
      });
    }

    if (this.endCallable) {
      const subscription = this.endCallable.subscribe(() => {
        this.status = "finished";
        subscription.unsubscribe();
      });
    }
  }
}
