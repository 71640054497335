import { ElementRef, EventEmitter, HostListener, ViewChild } from "@angular/core";
import { Component, OnInit, Output } from "@angular/core";
import User from "../../models/User";
import CustomerFeedback from "../../models/CustomerFeedback";
import ExtendedFeedback from "../../models/ExtendedFeedback";
import Star from "../../models/Star";
import { CustomerFeedbackService } from "../../services/customer-feedback.service";
import { LrsService } from "../../services/lrs.service";
import { AppService } from "src/app/core/services/app.service";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent implements OnInit {
  Stars: Star[] = [];
  ExtendedCollapsed: boolean = true;
  ScoreEntered: boolean = false;
  ThankYou: boolean = true;
  ExtendedFeedbackResponse: ExtendedFeedback = null;
  FeedbackResponse: CustomerFeedback = null;
  titles = [
    "This is awful!",
    "Did not meet my expectations.",
    "Meets my expectations.",
    "Exceeded my expectations.",
    "This is awesome!",
  ];

  @Output() ScoreSelected: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild("ExtendedFeedbackMenu") ExtendedMenu: ElementRef;

  constructor(private cfs: CustomerFeedbackService, private aps: AppService, private lrs: LrsService) {
    this.FeedbackResponse = this.cfs.constructFeedbackModel(0, this.ExtendedFeedbackResponse);
  }

  ngOnInit(): void {
    //Create star models
    for (let i = 1; i <= 5; i++) {
      this.Stars.push({ value: i, isActive: false, isSelected: false });
    }

    // Get the user ID
    if (!this.aps.CurrentUser) {
      this.aps.CurrentUser$.subscribe((user: User) => {
        this.FeedbackResponse.UserId = user.Id;
      });
    } else {
      this.FeedbackResponse.UserId = this.aps.CurrentUser.Id;
    }
  }

  starClicked(selectedStar: Star) {
    //If the user already entered a score then they cannot change it
    if (this.ScoreEntered == true) {
      return;
    }

    // Remove Active Flag
    if (this.FeedbackResponse.Score > 0) {
      this.Stars[this.FeedbackResponse.Score - 1].isSelected = false;
    }

    //Set score and active Flags
    this.FeedbackResponse.Score = selectedStar.value;
    this.ScoreEntered = true;
    this.Stars[selectedStar.value - 1].isSelected = true;
    this.setHighlightsToScore(this.FeedbackResponse.Score);

    this.cfs.saveCustomerFeedback(this.FeedbackResponse).subscribe((model: CustomerFeedback) => {
      this.FeedbackResponse = model;

      //Display the extended Feedback
      this.ExtendedCollapsed = false;
      this.FeedbackResponse.DoNotContactMe = false;

      //Fire the event that a score was selected
      this.ScoreSelected.next(this.FeedbackResponse.Score);
      this.lrs.LogUserInteractionWithUI("click", "feedback").subscribe();
    });
  }

  starEntered(selectedStar: Star) {
    if (this.ScoreEntered == true) {
      return;
    }

    this.setHighlightsToScore(selectedStar.value);
  }

  starLeft(selectedStar: Star) {
    if (this.ScoreEntered == true) {
      return;
    }

    this.setHighlightsToScore(this.FeedbackResponse.Score);
  }

  componentHover() {
    this.lrs.LogUserInteractionWithUI("hover", "feedback").subscribe();
  }

  extendedSubmit(extended: ExtendedFeedback) {
    this.FeedbackResponse.Response = extended;
    this.cfs.updateCustomerFeedback(this.FeedbackResponse).subscribe(() => {
      this.ExtendedCollapsed = true;
      this.showThankYou();
    });
  }

  private setHighlightsToScore(score: number) {
    //Set the active flags for each star
    for (let i = 0; i < this.Stars.length; i++) {
      if (i + 1 <= score) {
        this.Stars[i].isActive = true;
      } else {
        this.Stars[i].isActive = false;
      }
    }
  }

  //Shows the thank you message for 10 seconds
  private showThankYou() {
    this.ThankYou = false;

    setTimeout(() => {
      this.ThankYou = true;
    }, 10000);
  }

  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {
    // If there is a click outside the extended feedback menu it cancells the menu and closes it
    if (this.ExtendedCollapsed == false) {
      if (this.ExtendedMenu.nativeElement.contains(event.target) == false) {
        this.ExtendedCollapsed = true;
        this.showThankYou();
      } else {
        this.lrs.LogUserInteractionWithUI("click", "extended-feedback").subscribe();
      }
    }
  }
}
