<div class="modal-body">
  <img
    src="assets/images/NetSim-Network-Simulator.svg"
    class="light-mode-only"
    width="249"
    title="Boson NetSim
  Network Simulator"
    alt="Boson&trade; NetSim&trade; logo."
  />
  <img
    src="assets/images/NetSim-Network-Simulator-DarkMode.png"
    class="dark-mode-only"
    width="249"
    title="Boson NetSim Network Simulator"
    alt="Boson&trade; NetSim&trade; logo."
  />
  <button class="close float-right" (click)="onClose()">&times;</button>
  <p>
    NetSim&reg; Network Simulator&reg; software is an application that simulates Cisco&reg; Systems' networking hardware
    and software and is designed to aid the user in learning the Cisco IOS&reg; command structure.
  </p>

  <p>
    NetSim utilizes Boson's proprietary Network Simulator, Router Simulator&reg; and EROUTER&reg; software technologies,
    along with the Boson Virtual Packet Technology&reg; engine, to create individual packets. These packets are routed
    and switched through the simulated network, allowing NetSim to build an appropriate virtual routing table and
    simulate true networking.
  </p>

  <p>
    <strong>Version:</strong> &nbsp;<i>netsim-online</i><br />
    <a href="https://www.boson.com/user-agreement#NetSimEUA" target="_blank">View the License Agreement</a>
  </p>

  <p>
    Warning: This computer program is protected by copyright law and international treaties. Unauthorized reproduction
    or distribution of this program, or any portion of it, may result in severe criminal penalties, and will be
    prosecuted to the maximum extent possible under law.
  </p>

  <p>Copyright &copy; 1998 - {{ year }}, Boson Software, LLC. All rights reserved.</p>
</div>
