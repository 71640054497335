import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AppService } from "../../core/services/app.service";
import CustomerFeedback from "../models/CustomerFeedback";
import ExtendedFeedback from "../models/ExtendedFeedback";

@Injectable({
  providedIn: "root",
})
export class CustomerFeedbackService {
  private clientUri: string = environment.apiUri + "/customerfeedback";
  constructor(private http: HttpClient, private aps: AppService) {}

  getCustomerFeedback(id: string) {
    return this.http.get(`${this.clientUri}/${id}`);
  }

  saveCustomerFeedback(feedback: CustomerFeedback) {
    return this.http.post(this.clientUri, feedback);
  }

  updateCustomerFeedback(feedback: CustomerFeedback) {
    return this.http.patch(`${this.clientUri}/${feedback.Id}`, feedback);
  }

  constructFeedbackModel(score: number, extended: ExtendedFeedback) {
    let feedbackModel = new CustomerFeedback();

    feedbackModel.Score = score;
    feedbackModel.Response = extended;
    feedbackModel.NetSimVersion = environment.latestVersion;
    feedbackModel.UserAgentString = navigator.userAgent;
    feedbackModel.ScreenResolution = this.getScreenResolution();
    feedbackModel.DoNotContactMe = false;
    feedbackModel.Comment = "";
    feedbackModel.DisplayMethod = "Delayed Title Bar";
    feedbackModel.DisplayMethodMeta = [`${environment.displayFeedbackDelay} seconds`];
    return feedbackModel;
  }

  private getScreenResolution(): number[] {
    return [
      Math.round(window.screen.width * window.devicePixelRatio),
      Math.round(window.screen.height * window.devicePixelRatio),
    ];
  }
}
