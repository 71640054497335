import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NavigationBarService {
  private OpenFileSubject = new Subject<any>();
  OpenFile$: Observable<any> = this.OpenFileSubject.asObservable();

  private RunTopologySubject = new Subject<any>();
  RunTopologyFile$: Observable<any> = this.RunTopologySubject.asObservable();

  private SaveSubject = new Subject<any>();
  Save$: Observable<any> = this.SaveSubject.asObservable();

  private SaveAsSubject = new Subject<any>();
  SaveAs$: Observable<any> = this.SaveAsSubject.asObservable();

  private HasChangesSubject = new Subject<boolean>();
  HasChanges$: Observable<boolean> = this.HasChangesSubject.asObservable();

  private CreateNewTopologySubject = new Subject<boolean>();
  CreateNewTopology$: Observable<boolean> = this.CreateNewTopologySubject.asObservable();

  private CanCreateNewDocumentSubject = new Subject<boolean>();
  CanCreateNewDocument$: Observable<boolean> = this.CanCreateNewDocumentSubject.asObservable();

  private CanRunDesignerSubject = new Subject<boolean>();
  CanRunDesigner$ = this.CanRunDesignerSubject.asObservable();

  private ReferencesUpdatedSubject = new Subject<string[]>();
  ReferencesUpdated$ = this.ReferencesUpdatedSubject.asObservable();

  constructor() {}

  startOpenFile() {
    this.OpenFileSubject.next();
  }

  startSaveAs() {
    this.SaveAsSubject.next();
  }

  startSave() {
    this.SaveSubject.next();
  }

  startCreateNewTopology() {
    this.CreateNewTopologySubject.next();
  }

  notifyHasChanges(changes: boolean) {
    this.HasChangesSubject.next(changes);
  }

  enableCreateNew(canCreate: boolean) {
    this.CanCreateNewDocumentSubject.next(canCreate);
  }

  runTopology() {
    this.RunTopologySubject.next();
  }

  setRunDesigner(value: boolean) {
    this.CanRunDesignerSubject.next(value);
  }

  updateReferences(paths: string[]) {
    this.ReferencesUpdatedSubject.next(paths);
  }
}
