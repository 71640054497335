import { Injectable, OnInit } from "@angular/core";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { environment } from "../../../environments/environment";
import GetConnectionsResponse from "../models/GetConnectionResponse";
import GetDevicesResponse from "../models/GetDevicesResponse";
import { LabTreeService } from "../../features/lab/services/lab-tree.service";
import { LabsService } from "../../shared/services/labs.service";
import LabMeta from "../../shared/models/LabMeta";
import LabFileCollection from "../../shared/models/LabFileCollection";
import LoadConfigurationResponse from "../models/LoadConfigurationResponse";
import LoadConfigurationComplete from "../models/LoadConfigurationComplete";
import { combineLatest, Observable, of, Subject, zip } from "rxjs";
import { EngineMessage } from "../models/EngineMessage";
import Container from "../../shared/models/Container";
import Session from "../../shared/models/Session";
import { ContainersService } from "../../shared/services/containers.service";
import { SessionsService } from "../../shared/services/sessions.service";
import { AppService } from "../../core/services/app.service";
import GetConfigurationComplete from "../models/GetConfigurationComplete";
import { switchMap } from "rxjs/operators";
import Device from "../models/Device";
import Connection from "../models/Connection";
import { UserFilesService } from "src/app/features/user-files/services/user-files.service";
import { UserLabFileMeta } from "src/app/features/user-files/models/UserLabFileMeta";
import { NavigationBarService } from "src/app/shared/services/navigation-bar.service";
import OpenEditorResponse from "../models/OpenEditorResponse";
import EditorResponse from "../models/EditorResponse";
import { AuthService } from "src/app/core/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class EngineService {
  //Saveable state
  private HasChangesSource = new Subject<boolean>();
  HasChangesObservable = this.HasChangesSource.asObservable();

  //Sources
  private GetConfigurationSource = new Subject<string>();
  private GetConfigurationCompleteSource = new Subject<GetConfigurationComplete>();
  private LoadConfigurationSource = new Subject<LoadConfigurationResponse>();
  private LoadConfigurationCompleteSource = new Subject<LoadConfigurationComplete>();
  private SaveConfigurationSource = new Subject<string>();
  private GetConnectionsSource = new Subject<GetConnectionsResponse>();
  private GetDevicesSource = new Subject<GetDevicesResponse>();
  private LoadTopologySource = new Subject();
  private MessageReceivedSource = new Subject<string>();
  private SessionExpiredSource = new Subject<string>();
  private ClaimInstanceSource = new Subject();
  private LoadBsnSource = new Subject<LoadConfigurationResponse>();
  private LoadBsnCompleteSource = new Subject<LoadConfigurationComplete>();
  private RunDesignerSource = new Subject<LoadConfigurationResponse>();
  private RunDesignerCompleteSource = new Subject<LoadConfigurationComplete>();
  private SaveBsnSource = new Subject<LoadConfigurationResponse>();
  private SaveBsnCompleteSource = new Subject<LoadConfigurationComplete>();
  private SaveLabSource = new Subject<LoadConfigurationResponse>();
  private SaveLabCompleteSource = new Subject<LoadConfigurationComplete>();
  private OpenEditorSource = new Subject<OpenEditorResponse>();
  private EditorOperationSource = new Subject<EditorResponse>();

  //Observables
  GetConfigurationObservable = this.GetConfigurationSource.asObservable();
  GetConfigurationCompleteObservable = this.GetConfigurationCompleteSource.asObservable();
  LoadConfigurationObservable = this.LoadConfigurationSource.asObservable();
  LoadConfigurationCompleteObservable = this.LoadConfigurationCompleteSource.asObservable();
  SaveConfigurationObservable = this.LoadConfigurationSource.asObservable();
  GetConnectionsObservable = this.GetConnectionsSource.asObservable();
  GetDevicesObervable = this.GetDevicesSource.asObservable();
  LoadTopologyObservable = this.LoadTopologySource.asObservable();
  MessageReceivedObservable = this.MessageReceivedSource.asObservable();
  SessionExpiredObservable = this.SessionExpiredSource.asObservable();
  ClaimInstanceObservable = this.ClaimInstanceSource.asObservable();
  LoadBsnObservable = this.LoadBsnSource.asObservable();
  LoadBsnCompleteObservable = this.LoadBsnCompleteSource.asObservable();
  RunDesignerObservable = this.RunDesignerSource.asObservable();
  RunDesignerCompleteObservable = this.RunDesignerCompleteSource.asObservable();
  SaveBsnObservable = this.SaveBsnSource.asObservable();
  SaveBsnCompleteObservable = this.SaveBsnCompleteSource.asObservable();
  SaveLabObservable = this.SaveLabSource.asObservable();
  SaveLabCompleteObservable = this.SaveLabCompleteSource.asObservable();
  OpenEditorObservable = this.OpenEditorSource.asObservable();
  EditorOperationObservable = this.EditorOperationSource.asObservable();

  clientUri: string = environment.apiUri + "/clients";
  azureUri: string = environment.serverlessUri;
  TimoutInterval: NodeJS.Timeout;
  TimeoutLength: number = 3600;
  SecondsLeftUntilTimeout: number;

  webSocketConnection: WebSocketSubject<any>;

  CurrentLab: LabMeta;
  CurrentLabId: string;
  CurrentFileId: string;
  CurrentLabFiles: LabFileCollection;
  CurrentContainer: Container;
  CurrentSession: Session;
  CurrentDevices: Device[];
  CurrentConnections: Connection[];

  constructor(
    private lts: LabTreeService,
    private ls: LabsService,
    private cs: ContainersService,
    private ss: SessionsService,
    private as: AppService,
    private ufs: UserFilesService,
    private nbs: NavigationBarService,
    private auth: AuthService
  ) {}

  bootstrap() {
    if (this.as.CurrentSession) {
      this.CurrentSession = this.as.CurrentSession;
    } else {
      this.as.CurrentSession$.take(1).subscribe((session: Session) => {
        if (session != null) {
          console.log(`new session retrieved`);
          this.CurrentSession = session;
        }
      });
    }

    this.lts.labEnded.subscribe((labId: string) => {
      // Destroy Websocket connection
      if (this.webSocketConnection) {
        this.webSocketConnection.complete();
        this.webSocketConnection.unsubscribe();
        this.webSocketConnection = null;
      }

      //Release the container
      this.ss.releaseInstance(this.CurrentSession).subscribe(() => {
        this.CurrentSession.ContainerId = null;
        this.CurrentContainer = null;
        this.CurrentLab = null;
        this.CurrentLabId = null;
      });

      this.CurrentFileId = null;
    });

    this.as.StopPolling$.subscribe(() => {
      this.expireSession();
    });
  }

  sendMessage(message: string, data: any = {}) {
    try {
      data.NetSimEngineServerCommand = message;
      data.ContainerId = this.CurrentSession.ContainerId;
      data.Bearer = this.auth.getToken();
      if (this.webSocketConnection) {
        this.webSocketConnection.next(data);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (message != "stop-simulator") {
        this.ss.updateLastSeenAt(this.CurrentSession).toPromise();
      }
    } catch (e) {
      console.log(e);
    }
  }

  loadLab(labId: string, container: Container) {
    console.log(`inside load lab alt: ${labId}`);
    this.CurrentLabId = labId;
    this.CurrentContainer = container;
    this.CurrentSession.ContainerId = container.Id;
    this.CurrentSession.LabId = labId;

    this.ls
      .getLabMeta(labId)
      .pipe(
        switchMap((lab: LabMeta) => {
          this.CurrentLab = lab;
          this.CurrentLabFiles = new LabFileCollection(lab.Files);
          // console.log("about to claim instance");
          return combineLatest([of(this.claimInstance(container)), this.ClaimInstanceObservable]);
        }),
        switchMap(() => {
          // console.log("about to load top");
          return this.loadTopology(this.CurrentLabFiles.Topology.Id);
        }),
        switchMap(() => {
          // console.log("done loading top");
          if (!this.CurrentLabFiles.LoadingNwc) {
            // console.log("no loading");
            this.LoadConfigurationCompleteSource.next();
            return of("");
          } else {
            // console.log("loading exist");
            return combineLatest([
              this.loadConfigurations(
                this.CurrentLabFiles.LoadingNwc.Id,
                this.CurrentLabFiles.LoadingRtrs.map((l) => l.Id)
              ),
              this.LoadConfigurationCompleteObservable,
            ]);
          }
        }),
        switchMap(() => {
          return zip(this.getDevices(), this.getConnections());
        })
      )
      .take(1)
      .subscribe(([devicesResponse, connectionsResponse]: [GetDevicesResponse, GetConnectionsResponse]) => {
        this.startSimComplete(devicesResponse, connectionsResponse, labId);
      });
  }

  loadLabFile(fileId: string, container: Container) {
    console.log(`inside load lab file alt: ${fileId}`);
    this.CurrentLabId = null;
    this.CurrentFileId = fileId;
    this.CurrentContainer = container;
    this.CurrentSession.ContainerId = container.Id;
    this.CurrentSession.UserFileId = fileId;
    this.CurrentSession.LabId = null;

    this.ufs
      .getUserFileLabMeta(fileId)
      .pipe(
        switchMap((meta: UserLabFileMeta) => {
          this.CurrentLabId = meta.LabId;
          this.CurrentSession.LabId = meta.LabId;

          return this.ls.getLabMeta(meta.LabId);
        }),
        switchMap((lab: LabMeta) => {
          this.CurrentLab = lab;
          this.CurrentLabFiles = new LabFileCollection(lab.Files);

          return combineLatest([of(this.claimInstance(container)), this.ClaimInstanceObservable]);
        }),
        switchMap(() => {
          return combineLatest([this.loadBsnMessage(fileId), this.LoadBsnCompleteObservable]);
        }),
        switchMap(() => {
          return zip(this.getDevices(), this.getConnections());
        })
      )
      .take(1)
      .subscribe(([devicesResponse, connectionsResponse]: [GetDevicesResponse, GetConnectionsResponse]) => {
        this.startSimComplete(devicesResponse, connectionsResponse, this.CurrentLabId);
      });
  }

  loadBsnFile(fileId: string, container: Container) {
    console.log(`inside load bsn alt: ${fileId}`);
    this.CurrentLabId = null;
    this.CurrentFileId = fileId;
    this.CurrentContainer = container;
    this.CurrentSession.ContainerId = container.Id;
    this.CurrentSession.UserFileId = fileId;
    this.CurrentSession.LabId = null;

    combineLatest([of(this.claimInstance(container)), this.ClaimInstanceObservable])
      .pipe(
        switchMap(() => {
          return combineLatest([this.loadBsnMessage(fileId), this.LoadBsnCompleteObservable]);
        }),
        switchMap(() => {
          return zip(this.getDevices(), this.getConnections());
        })
      )
      .take(1)
      .subscribe(([devicesResponse, connectionsResponse]: [GetDevicesResponse, GetConnectionsResponse]) => {
        this.CurrentFileId = fileId;
        this.CurrentSession.UserFileId = this.CurrentFileId;
        this.startSimComplete(devicesResponse, connectionsResponse, "");
      });
  }

  runDesignerFile(fileId: string, container: Container) {
    this.CurrentLabId = null;
    this.CurrentContainer = container;
    this.CurrentSession.ContainerId = container.Id;
    this.CurrentSession.LabId = null;

    combineLatest([of(this.claimInstance(container)), this.ClaimInstanceObservable])
      .pipe(
        switchMap(() => {
          return combineLatest([this.runDesignerMessage(fileId), this.RunDesignerCompleteObservable]);
        }),
        switchMap(() => {
          return zip(this.getDevices(), this.getConnections());
        })
      )
      .take(1)
      .subscribe(([devicesResponse, connectionsResponse]: [GetDevicesResponse, GetConnectionsResponse]) => {
        this.CurrentFileId = fileId;
        this.CurrentSession.UserFileId = this.CurrentFileId;
        this.startSimComplete(devicesResponse, connectionsResponse, "");
      });
  }

  startSimComplete(devicesResponse: GetDevicesResponse, connectionsResponse: GetConnectionsResponse, labId: string) {
    this.CurrentConnections = connectionsResponse.Connections;
    this.CurrentDevices = devicesResponse.Devices;
    this.cacheTotalDevices(this.CurrentDevices?.length);
    this.ss.updateSession(this.CurrentSession).toPromise();
    this.lts.executeLabFinished(labId);
    this.HasChangesSource.next(true);
  }

  cacheTotalDevices(totalDevices: number) {
    try {
      window.localStorage.setItem(`totalDevices`, totalDevices.toString());
    } catch (error) {
      // log error
    }
  }

  initializeWebSocketsForStandAlone(container: Container) {
    this.claimInstance(container);
  }

  resumeLab() {
    console.log(`resuming lab in engine service.`);
    this.cs.getContainer(this.as.CurrentSession.ContainerId).subscribe(
      (container: Container) => {
        this.CurrentLabId = this.as.CurrentSession.LabId;
        this.CurrentFileId = this.as.CurrentSession.UserFileId;
        this.CurrentContainer = container;
        this.openWebSocketConnection(container);

        this.ls
          .getLabMeta(this.as.CurrentSession.LabId)
          .pipe(
            switchMap((lab: LabMeta) => {
              return zip(of(lab), this.getDevices(), this.getConnections());
            })
          )
          .subscribe(([lab, devices, connections]: [LabMeta, GetDevicesResponse, GetConnectionsResponse]) => {
            this.CurrentLab = lab;
            this.CurrentDevices = devices.Devices;
            this.CurrentConnections = connections.Connections;
            this.cacheTotalDevices(this.CurrentDevices?.length);

            console.log(this.as.CurrentSession.LabId);
            console.log(this.CurrentSession.LabId);
            this.lts.resumeLab(this.CurrentSession.LabId);
            this.nbs.notifyHasChanges(true);
          });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  claimInstance(container: Container) {
    this.openWebSocketConnection(container);

    this.sendMessage("claim-instance", {
      ClientId: "20b206d8-4b20-4647-bb4a-6e404f5c2185",
      UserId: this.CurrentSession.UserId,
      PersonId: this.as.CurrentUser.PersonId,
      UserEmail: this.as.CurrentUser.Email,
    });
  }

  loadTopology(id: string) {
    console.log("loading topology " + id);

    return of(this.sendMessage("load-topology", { TopologyFileId: id })).pipe(
      switchMap(() => {
        return this.LoadTopologyObservable;
      })
    );
  }

  loadConfigurations(id: string, configurations: string[]) {
    console.log("loading configurations");
    let data = {
      NwcFileId: id,
      Configurations: configurations,
    };

    return of(this.sendMessage("load-configurations", data)).pipe(
      switchMap(() => {
        return this.LoadConfigurationObservable;
      })
    );
  }

  loadBsnMessage(fileId: string) {
    let data = {
      UserId: this.as.CurrentUser.Id,
      UserFileId: fileId,
    };

    return of(this.sendMessage("load-bsn", data)).pipe(
      switchMap(() => {
        return this.LoadBsnObservable;
      })
    );
  }

  // loadBsn(fileId: string) {
  //   console.log("loading network from user bsn file");
  runDesignerMessage(fileId: string) {
    let data = {
      UserId: this.as.CurrentUser.Id,
      UserFileId: fileId,
    };

    return of(this.sendMessage("run-designer", data)).pipe(
      switchMap(() => {
        return this.RunDesignerObservable;
      })
    );
  }

  private getContainerSubscription(): Observable<Container> {
    if (!this.CurrentSession.ContainerId) {
      //claim container
      console.log("claim container");
      return this.ss.claimInstance(this.CurrentSession);
    } else {
      console.log("get container");
      return this.cs.getContainer(this.CurrentSession.ContainerId);
    }
  }

  getConfigurations(id: string) {
    console.log("get configurations");
    // GetConfigurationRequestId is returned
    this.sendMessage("get-configurations");
  }

  saveConfigurations(id: string) {
    console.log("save configurations");
    let data = {
      FileName: "",
      UserId: this.as.CurrentUser.Id,
    };
    // FileName
    // UserId
    // SaveConfigurationRequestId is returned

    this.sendMessage("save-configurations", data);
  }

  saveBsnMessage(name: string) {
    console.log("save bsn");
    let data = {
      FileName: name,
      UserId: this.as.CurrentUser.Id,
    };

    return of(this.sendMessage("save-bsn", data)).pipe(
      switchMap(() => {
        return this.SaveBsnObservable;
      })
    );
  }

  saveBsn(name: string) {
    console.log(this.CurrentContainer);
    if (this.CurrentContainer && this.CurrentContainer.Status == "Claimed") {
      combineLatest([this.saveBsnMessage(name), this.SaveBsnCompleteObservable]).subscribe(
        ([saveResponse, completedResponse]) => {
          console.log("saveBsn responses");

          // return of(completedResponse.LoadConfigurationRequestId);
          console.log(saveResponse);
          console.log(completedResponse);

          this.nbs.notifyHasChanges(false);
          console.log("changes saved");
        }
      );
    }
  }

  saveLabMessage(name: string) {
    console.log("save lab");
    let data = {
      FileName: name,
      UserId: this.as.CurrentUser.Id,
      LabId: this.CurrentLabId,
    };

    return of(this.sendMessage("save-lab", data)).pipe(
      switchMap(() => {
        return this.SaveLabObservable;
      })
    );
  }

  saveLab(name: string) {
    if (this.CurrentContainer && this.CurrentContainer.Status == "Claimed") {
      combineLatest([this.saveLabMessage(name), this.SaveLabCompleteObservable]).subscribe(
        ([saveResponse, completedResponse]) => {
          console.log("save lab responses");

          // return of(completedResponse.LoadConfigurationRequestId);
          console.log(saveResponse);
          console.log(completedResponse);

          this.nbs.notifyHasChanges(false);
          console.log("changes saved");
        }
      );
    }
  }

  startSimulator() {
    //console.log("start simulator");
    this.sendMessage("start-simulator");
  }

  stopSimulator() {
    //console.log("stop simulator");
    this.sendMessage("stop-simulator");
  }

  getConnections(): Observable<GetConnectionsResponse> {
    //console.log("getting connections");
    //this.sendMessage('get-connections');

    return of(this.sendMessage("get-connections")).pipe(
      switchMap(() => {
        //console.log("get-connections switchMap");
        return this.GetConnectionsObservable;
      })
    );
  }

  getDevices(): Observable<GetDevicesResponse> {
    //console.log("getting devices");
    //this.sendMessage('get-devices');

    return of(this.sendMessage("get-devices")).pipe(
      switchMap(() => {
        //console.log("get-devices switchMap");
        return this.GetDevicesObervable;
      })
    );
  }

  refreshEditor(deviceIndex: number) {
    return of(
      this.sendMessage("bedit-command", {
        deviceIndex: deviceIndex,
        contents: null,
        fileName: null,
        command: "refresh",
      })
    ).pipe(
      switchMap(() => {
        return this.EditorOperationObservable;
      })
    );
  }

  saveEditor(deviceIndex: number, contents: string) {
    return of(
      this.sendMessage("bedit-command", {
        deviceIndex: deviceIndex,
        contents: contents,
        fileName: null,
        command: "save",
      })
    ).pipe(
      switchMap(() => {
        return this.EditorOperationObservable;
      })
    );
  }

  expireSession() {
    if (this.webSocketConnection) {
      this.webSocketConnection.complete();
    }

    this.lts.endLab();
    this.SessionExpiredSource.next("Session Expired");
  }

  openWebSocketConnection(container: Container) {
    let fullUrl = "";
    if (this.webSocketConnection) {
      this.webSocketConnection.unsubscribe();
      this.webSocketConnection = null;
    }
    if (container.VMIP == "127.0.0.1" || container.VMIP == "localhost") {
      fullUrl = `ws://${container.VMIP}:${container.VMPort}`;
    } else {
      fullUrl = `wss://engine.netsim.boson.com/${container.VMGateway}?Port=${container.VMPort}`;
    }

    this.webSocketConnection = webSocket(fullUrl);
    this.webSocketConnection.asObservable().subscribe(
      (message) => this.routeWebSocketConnection(message),
      (error) => {
        if (typeof error === "object" && error instanceof CloseEvent) {
          if (this.CurrentContainer) {
            setTimeout(() => {
              this.openWebSocketConnection(this.CurrentContainer);
            }, 1000);
          }
        }
      },
      () => console.log("Connection completed")
    );
  }

  routeWebSocketConnection(message) {
    if (message.Code === 401 || (message.PersonID && message.PersonID !== this.as.CurrentUser.PersonId)) {
      this.expireSession();
      return;
    }

    let engineMessage: EngineMessage = message;
    switch (engineMessage.Command) {
      case "console_output":
        this.MessageReceivedSource.next(message);
        break;
      case "get-configurations-complete":
        this.GetConfigurationCompleteSource.next(message);
        break;
      case "load-configurations-complete":
        this.LoadConfigurationCompleteSource.next(message);
        break;
      case "save-configurations-complete":
        this.SaveConfigurationSource.next(message);
        this.nbs.notifyHasChanges(false);
        break;
      case "load-topology":
        this.LoadTopologySource.next(message);
        break;
      case "load-configurations":
        this.LoadConfigurationSource.next(message);
        break;
      case "get-devices":
        this.GetDevicesSource.next(message);
        break;
      case "get-connections":
        this.GetConnectionsSource.next(message);
        break;
      case "claim-instance":
        this.ClaimInstanceSource.next(message);
        break;
      case "save-bsn":
        this.SaveBsnSource.next(message);
        break;
      case "save-bsn-complete":
        this.SaveBsnCompleteSource.next(message);
        this.nbs.notifyHasChanges(false);
        break;
      case "save-lab":
        this.SaveLabSource.next(message);
        break;
      case "save-lab-complete":
        this.SaveLabCompleteSource.next(message);
        this.nbs.notifyHasChanges(false);
        break;
      case "load-bsn":
        this.LoadBsnSource.next(message);
        break;
      case "load-bsn-complete":
        this.LoadBsnCompleteSource.next(message);
        break;
      case "run-designer":
        this.RunDesignerSource.next(message);
        break;
      case "run-designer-complete":
        this.RunDesignerCompleteSource.next(message);
        break;
      case "open-editor":
        this.OpenEditorSource.next(message);
      case "bedit-command":
        this.EditorOperationSource.next(message);
      default:
        // console.log("Unknown message: " + message.Command);
        break;
    }

    return message;
  }
}
