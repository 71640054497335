<div
  class="netmap"
  appResizedDirective
  [class]="isStandaloneWindow ? 'standalone' : ''"
  (resized)="viewportResized($event)"
>
  <app-svg-graph
    #netmap
    class="chart-container"
    [curve]="curve"
    [autoCenter]="false"
    [draggingEnabled]="false"
    [links]="edges"
    [nodes]="nodes"
    [layout]="layout"
    [panToNode$]="panToNode$"
    [center$]="center$"
    [panningEnabled]="true"
    [zoomLevel]="1"
    [zoomToFit$]="zoomToFit$"
    [update$]="update$"
    [ngClass]="{ panCursor: true }"
  >
    <ng-template #nodeTemplate let-node style="display: block">
      <svg:g
        *ngIf="node.elementType == 'device'"
        class="node"
        app-device-node
        [node]="node"
        [activeCursor]="'panCursor'"
        [layout]="layout"
        (dblclick)="nodeDoubleClick(node)"
      ></svg:g>
      <svg:g *ngIf="node.elementType != 'device'" class="node">
        <defs>
          <filter id="nodeShadowFilter">
            <feDropShadow dx="2" dy="2" stdDeviation="2" flood-color="#222" />
          </filter>
        </defs>
        <svg
          app-note-node
          *ngIf="node.type == 'Note' && node.editInProgress != undefined && node.editInProgress == false"
          [node]="node"
          version="1.1"
          id="svg9"
          class="node-icon"
        ></svg>

        <svg
          app-label-node
          *ngIf="node.type == 'Label' && node.editInProgress != undefined && node.editInProgress == false"
          [node]="node"
          version="1.1"
          id="svg9"
          class="node-icon"
        ></svg>
      </svg:g>
    </ng-template>

    <ng-template #linkTemplate let-link>
      <svg:g class="edge">
        <svg:path class="line" stroke-width="2" marker-end="url(#arrow)"></svg:path>
        <svg:text class="edge-label" text-anchor="middle">
          <textPath
            class="text-path"
            [attr.href]="'#' + link.id"
            [style.dominant-baseline]="link.dominantBaseline"
            startOffset="50%"
          >
            {{ link.label }}
          </textPath>
        </svg:text>
      </svg:g>
    </ng-template>
  </app-svg-graph>
</div>
