import Point from "../math/Point";
import { ITopologyNode } from "./Nodes/ITopologyNode";

export default class BaseXmlSerialization {
  addKeyValue(doc: XMLDocument, parent: HTMLElement, key: string, value: string) {
    let newTag = doc.createElement(key);
    newTag.innerHTML = value;
    parent.appendChild(newTag);
  }

  addBlankTag(doc: XMLDocument, parent: HTMLElement, key: string): HTMLElement {
    let newTag = doc.createElement(key);
    parent.appendChild(newTag);
    return newTag;
  }

  addCoordinateToTag(doc: XMLDocument, parent: HTMLElement, location: Point, xOffset?: number) {
    this.addKeyValue(doc, parent, "X", location.x.toString());
    this.addKeyValue(doc, parent, "Y", location.y.toString());
  }

  addDeviceCoordinate(doc: XMLDocument, devicesMarkup: HTMLElement, node: ITopologyNode) {
    let markup = doc.createElement("DeviceCoordinate");
    this.addKeyValue(doc, markup, "ID", node.id);
    let locationTag = this.addBlankTag(doc, markup, "Location");
    this.addCoordinateToTag(doc, locationTag, { x: node.defaultPosition.x + 5000, y: node.defaultPosition.y + 5000 });
    devicesMarkup.appendChild(markup);
  }
}
