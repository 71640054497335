import { HttpErrorResponse } from "@angular/common/http";
import { HostListener, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { settings } from "cluster";
import { of, zip, Subject, Observable, timer } from "rxjs";
import { catchError, retry, share, switchMap, takeUntil } from "rxjs/operators";
import UserData from "src/app/core/models/UserData";
import { AuthService } from "src/app/core/services/auth.service";
import { environment } from "src/environments/environment";
import CustomerFeedback from "../../shared/models/CustomerFeedback";
import Session from "../../shared/models/Session";
import User from "../../shared/models/User";
import { SessionsService } from "../../shared/services/sessions.service";
import { UsersService } from "../../shared/services/users.service";

@Injectable({
  providedIn: "root",
})
export class AppService {
  private UserIsPresent: boolean = false;
  private MouseInterval;
  private CurrentUserSource = new Subject<User>();
  private CurrentSessionSource = new Subject<Session>();
  private DisplayFeedback = new Subject<boolean>();
  private StopPolling = new Subject();
  private FailedTokenRequestCount: number = 0;
  private HideNavbarSource = new Subject<boolean>();

  CurrentUser$ = this.CurrentUserSource.asObservable();
  CurrentSession$ = this.CurrentSessionSource.asObservable();
  DisplayFeedback$ = this.DisplayFeedback.asObservable();
  HideNavbar$ = this.HideNavbarSource.asObservable();
  StopPolling$ = this.StopPolling.asObservable();
  CurrentUser: User;
  CurrentSession: Session;

  constructor(private as: AuthService, private us: UsersService, private ss: SessionsService, private router: Router) {}

  ngOnDestroy() {
    this.StopPolling.next();
  }

  bootstrap() {
    console.log("app.service.ts bootstrap");
    this.as
      .getIsAuthorized()
      .pipe(
        switchMap((isAuthenticated) => {
          if (!isAuthenticated) {
            console.log(`pathname is ${window.location.pathname}`);
            if (
              !(
                ["/auto-login", "/", ""].includes(window.location.pathname) ||
                window.location.pathname.startsWith("/labs")
              )
            ) {
              //if ("/auto-login" !== window.location.pathname) {
              if (!(window.location.pathname + window.location.search).startsWith("/callback")) {
                this.as.RouteStorage = window.location.pathname + window.location.search;
              }
              console.log("navigating to auto-login");
              this.router.navigate(["/auto-login"]);
            }
          } else if (this.as.RouteStorage && this.as.RouteStorage != "/") {
            let oldRoute = this.as.RouteStorage;
            this.as.RouteStorage = null;
            this.router.navigateByUrl(oldRoute);
          }

          if (isAuthenticated) {
            return this.as.getUserData();
          } else {
            return of(null);
          }
        }),
        switchMap((userData: UserData) => {
          if (userData != null) {
            return this.us.getUserByEmail(userData.name);
          } else {
            return of(null);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          if (error.status == 401) {
            this.as.logout();
          }
          return of(null);
        }),
        switchMap((user: User) => {
          if (user != null) {
            return zip(of(user), this.us.getActiveSession(user.Id));
          } else {
            return zip(of(null), of(null));
          }
        })
      )
      .subscribe(([user, session]: [User, Session]) => {
        console.log("handling app service subscription to getIsAuthorized");
        console.log(user);
        if (user != null) {
          this.CurrentUser = user;
          this.CurrentUserSource.next(user);
          this.as.LoginInProgress = false;

          if (session == undefined || session == null) {
            this.ss.createSession(user.Id, "Active").subscribe((newSession: Session) => {
              this.CurrentSession = newSession;
              this.CurrentSessionSource.next(newSession);
              this.as.CurrentSession = newSession;
            });
          } else {
            this.ss.updateLastSeenAt(session).toPromise();
            this.CurrentSession = session;
            this.CurrentSessionSource.next(session);
            this.as.CurrentSession = session;
          }

          this.startTokenPolling();
          this.startIdleUserChecks();
        }
      });

    this.CurrentSession$.first()
      .pipe(
        switchMap((session: Session) => {
          return zip(this.us.getTimeInNetSim(this.CurrentUser.Id), this.us.getFeedback(this.CurrentUser.Id));
        })
      )
      .subscribe(([time, feedback]: [number, CustomerFeedback[]]) => {
        if (time >= environment.timeUntilFeedbackRequest && feedback.length == 0) {
          setTimeout(() => {
            this.DisplayFeedback.next(true);
          }, environment.displayFeedbackDelay * 1000);
        } else {
          this.DisplayFeedback.next(false);
        }
      });
  }

  updateSession() {
    this.us.getActiveSession(this.CurrentUser.Id).subscribe((userSession: Session) => {
      this.CurrentSessionSource.next(userSession);
    });
  }

  startIdleUserChecks() {
    this.MouseInterval = setInterval(() => {
      // If there is no session then it has expired. Log the user out
      let sessionCheckSub = this.us.getActiveSession(this.CurrentUser.Id).subscribe((userSession: Session) => {
        if (!userSession) {
          this.stopIdleUserChecks();
          this.stopTokenPolling();
          this.as.logout();
        } else {
          // Update the last seen at if the user has moved their mouse
          if (this.UserIsPresent == true) {
            let sub = this.ss.updateLastSeenAt(userSession).subscribe(() => {
              sub.unsubscribe();
            });

            this.CurrentSessionSource.next(userSession);
            this.UserIsPresent = false;
          }
        }

        sessionCheckSub.unsubscribe();
      });
    }, 10000);
  }

  stopIdleUserChecks() {
    clearInterval(this.MouseInterval);
  }

  startTokenPolling() {
    timer(1, 5000)
      .pipe(
        switchMap(() => this.as.inspectToken().pipe(catchError((x) => of(x)))),
        share(),
        takeUntil(this.StopPolling)
      )
      .subscribe(
        (result: any) => {
          if (result.active) {
            this.FailedTokenRequestCount = 0;
          } else {
            this.incrementFailedToken();
          }
        },
        (error) => {
          this.incrementFailedToken();
        }
      );
  }

  stopTokenPolling() {
    this.StopPolling.next();
  }

  incrementFailedToken() {
    if (this.FailedTokenRequestCount < 3) {
      this.FailedTokenRequestCount++;
    } else {
      this.as.logout();
    }
  }

  notifyUserIsPresent() {
    if (this.UserIsPresent == false) {
      this.UserIsPresent = true;
    }
  }

  setNavbarAsHidden(value: boolean) {
    this.HideNavbarSource.next(value);
  }
}
