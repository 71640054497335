import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter1004 extends BaseInventoryRouter {
  constructor() {
    super("1004", "1000", 4, 4);
    this.maxAddons = 0;
    this.defaultInterfaces.addEthernetInterfaces("Ethernet", 1, null, null);
    this.defaultInterfaces.addBriInterfaces(1, null, null);
  }
}
