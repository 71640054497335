import IHasInterfaces from "../../Shared/interfaces/IHasInterfaces";
import IInventoryDevice from "../Devices/IInventoryDevice";
import InventoryEthernetInterface from "../Interfaces/InventoryEthernetInterface";
import IAddon from "./IAddon";

export default class PC1Eth implements IAddon {
  name: string = "PC-1-Eth";
  description: string = "1 Ethernet";

  addToDevice(device: IHasInterfaces, rack: number, slot: number) {
    device.interfaces.push(new InventoryEthernetInterface("Ethernet", rack, slot, 0));
  }
}
