<p class="lead">
  NetSim is a tool that simulates Cisco Systems' networking hardware and software and is designed to give you the
  hands-on experience entering commands and configuring protocols needed to pass your certification exam.
</p>
<p class="lead" style="margin-top: inherit">
  NetSim comes with guided labs covering the topics that are covered on the corresponding certification exam. NetSim
  labs are preconfigured and designed to improve your understanding and master the skill necessary to complete your
  certification.
</p>
<div class="row">
  <div class="col-sm-4">
    <h2>Quick Start Guide</h2>
    <ol>
      <li data-number="1">Select the lab tree for the exam that you want to prepare for.</li>
      <li data-number="2">Then select a specific lab title. You will see a brief description of the lab and the topology diagram.</li>
      <li data-number="3">Load Lab. Then follow the instructions and enter the commands necessary to complete each step.</li>
      <li data-number="4">When you have finished a lab, click the Grade button to verify that you have completed a lab successfully. NetSim compares your running configuration to what is expected and highlights any differences.</li>
      <li data-number="5">Continue working through labs until you can complete each lab without skipping ahead to the lab solutions. We're confident that our collection of labs will comfortably prepare you for any configuration item necessary to pass your exam.</li>
    </ol>
  </div>
  <div class="col-sm-8">
    <img
      src="assets/images/getting-started/numbered.png"
      alt="A screenshot of NetSim&trade; with numbers corresponding to the Quick Start Guide steps."
    />
  </div>
</div>
