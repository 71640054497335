import IHasInterfaces from "../../Shared/interfaces/IHasInterfaces";
import IInventoryDevice from "../Devices/IInventoryDevice";
import InventoryBriInterface from "../Interfaces/InventoryBriInterface";
import InventorySerialInterface from "../Interfaces/InventorySerialInterface";
import IAddon from "./IAddon";

export default class Hwic4Serial implements IAddon {
  name: string = "HWIC-4T";
  description: string = "4 Serial";

  addToDevice(device: IHasInterfaces, rack: number, slot: number) {
    for (let i = 0; i < 4; i++) {
      device.interfaces.push(new InventorySerialInterface(rack, slot, i));
    }
  }
}
