<div class="root-container" *ngIf="!isNsx">
  <div class="menu-container" *ngIf="!isHome && !isConsole">
    <app-main-menu></app-main-menu>
  </div>

  <div *ngIf="hasNotice" class="container" role="alert">
    <div class="alert alert-info alert-dismissible fade show">
      {{ noticeMessage }}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <div class="router-outlet-container">
    <router-outlet></router-outlet>
  </div>
</div>
<router-outlet *ngIf="isNsx"></router-outlet>
