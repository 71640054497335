import { Injectable, Output, EventEmitter, Directive } from "@angular/core";

@Directive()
@Injectable({
  providedIn: "root",
})
export class LabTreeService {
  @Output() labSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() labExecuted: EventEmitter<string> = new EventEmitter<string>();
  @Output() labFinished: EventEmitter<string> = new EventEmitter<string>();
  @Output() labEnded: EventEmitter<string> = new EventEmitter<string>();
  @Output() labResumed: EventEmitter<string> = new EventEmitter<string>();

  private _lastLabSelected: string;
  private _lastLabExecuted: string;

  constructor() {}

  get lastLabSelected(): string {
    return this._lastLabSelected;
  }

  protected onLabSelected(lab: string) {
    this.labSelected.emit(lab);
  }

  public selectLab(lab: string) {
    this._lastLabSelected = lab;
    this.onLabSelected(lab);
  }

  get lastLabExecuted(): string {
    return this._lastLabExecuted;
  }

  protected onLabExecuted(lab: string) {
    // legacy guid because product hierarchy hasn't been updated.
    this.labExecuted.emit(lab);
  }

  public executeLab(lab: string) {
    // legacy guid because product hierarchy hasn't been updated.
    this._lastLabExecuted = lab;
    this.onLabExecuted(lab);
  }

  protected onLabFinished(lab: string) {
    this.labFinished.emit(lab);
  }

  public executeLabFinished(lab: string) {
    this.labFinished.emit(lab);
  }

  public endLab() {
    this.labEnded.emit(this.lastLabExecuted);
  }

  public resumeLab(labId: string) {
    this._lastLabExecuted = labId;
    this.labResumed.emit(labId);
  }
}
