import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Observable, of, zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LabsService {
  uri = environment.apiUri + "/labs";

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  getLabById(id) {
    return this.http.get(`${this.uri}/${id}`);
  }

  getLabByLabId(id) {
    return this.http.get(`${this.uri}/${id}/labId`);
  }

  getLabMeta(id) {
    return this.http.get(`${this.uri}/${id}/meta?onlydisplaylatest=true&limit=9999&labid=${id}`);
  }

  getLabTopology(id) {
    return this.http.get(`${this.uri}/${id}/topology`);
  }

  getLabTopologyImage(id) {
    return this.http.get(`${this.uri}/${id}/topology-image`);
  }

  getLightandDarkTopologyImage(id) {
    return this.http.get(`${this.uri}/${id}/topology-image`).pipe(
      switchMap((lightImage) => {
        return zip(of(lightImage), this.http.get(`${this.uri}/${id}/dark-topology-image`));
      })
    );
  }

  getLabDocument(id) {
    return this.http.get(`${this.uri}/${id}/document`);
  }
}
