<div class="container-fluid">
  <div *ngIf="Score < 5" class="row">
    <div class="col-lg-12">
      <div class="form-check mt-4">
        <input
          id="issue_with_commands"
          type="checkbox"
          class="form-check-input"
          [checked]="feedbackModel.IssueWithCommands"
          (change)="feedbackModel.IssueWithCommands = !feedbackModel.IssueWithCommands"
        />
        <label for="issue_with_commands" class="form-check-label"
          >Lack of commands supported or incorrect command behavior</label
        >
      </div>

      <div class="form-check">
        <input
          id="issue_with_labs"
          type="checkbox"
          class="form-check-input"
          [checked]="feedbackModel.IssueWithLabs"
          (change)="feedbackModel.IssueWithLabs = !feedbackModel.IssueWithLabs"
        />
        <label for="issue_with_labs" class="form-check-label">Insufficient lab coverage or incorrect lab content</label>
      </div>

      <div class="form-check">
        <input
          id="issue_with_performance"
          type="checkbox"
          class="form-check-input"
          [checked]="feedbackModel.IssueWithPerformance"
          (change)="feedbackModel.IssueWithPerformance = !feedbackModel.IssueWithPerformance"
        />
        <label for="issue_with_performance" class="form-check-label">Latency, lag, or other performance issues</label>
      </div>

      <div class="form-check">
        <input
          id="missing_features"
          type="checkbox"
          class="form-check-input"
          [checked]="feedbackModel.MissingFeatures"
          (change)="feedbackModel.MissingFeatures = !feedbackModel.MissingFeatures"
        />
        <label for="missing_features" class="form-check-label">It’s missing features</label>
      </div>

      <div class="form-check">
        <input
          id="difficult_to_use"
          type="checkbox"
          class="form-check-input"
          [checked]="feedbackModel.DifficultToUse"
          (change)="feedbackModel.DifficultToUse = !feedbackModel.DifficultToUse"
        />
        <label for="difficult_to_use" class="form-check-label">Difficult to use</label>
      </div>

      <div class="form-check">
        <input
          id="other_issues"
          type="checkbox"
          class="form-check-input"
          [checked]="feedbackModel.OtherIssue"
          (change)="feedbackModel.OtherIssue = !feedbackModel.OtherIssue"
        />
        <label for="other_issues" class="form-check-label">Other</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="form-group">
        <label class="mt-4">Comments</label>
        <textarea
          [ngClass]="hasError ? 'form-control is-invalid' : 'form-control'"
          style="font-size: small; display: inline-block"
          rows="3"
          #commentBox
          [(ngModel)]="Comment"
          (input)="commentChanged()"
        ></textarea>
      </div>
    </div>
  </div>

  <div class="row" style="flex: auto">
    <div class="col-lg-12">
      <div class="form-check float-left">
        <input
          id="do_not_contact"
          type="checkbox"
          class="form-check-input"
          [checked]="DoNotContactMe"
          (change)="doNotContactMeChanged()"
        />
        <label for="do_not_contact" class="form-check-label">Do not contact me.</label>
      </div>
      <button class="btn btn-primary btn-sm float-right" (click)="submitClick()">Submit</button>
    </div>
  </div>
</div>
