import { ThisReceiver } from "@angular/compiler";
import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import Line from "src/app/shared/models/math/Line";
import NoteNode from "src/app/shared/models/TopologyCanvas/Nodes/NoteNode";

@Component({
  selector: "[app-note-node]",
  templateUrl: "./note-node.component.svg",
  styleUrls: ["./note-node.component.scss"],
})
export class NoteNodeComponent implements OnInit, DoCheck {
  constructor(private changes: ChangeDetectorRef) {}

  @Input() node: NoteNode;
  @ViewChild("markupText") textEditor: ElementRef;
  padding: number = 10;
  lineHeightOffset: number = 2.5;
  textLines: string[];
  linePoints: Line[];

  oldText: string;
  textEditorLoaded: boolean = false;

  ngOnInit(): void {}

  ngDoCheck(): void {
    if (this.node && this.node.text != this.oldText) {
      this.oldText = this.node.text;
      this.configureTextLines();

      if (this.textEditorLoaded == true) {
        this.sizeRectangleToText();
      }
    }

    if (this.textEditorLoaded == false && this.textEditor) {
      this.textEditorLoaded = true;
      this.sizeRectangleToText();
      this.changes.detectChanges();
    }
  }

  configureTextLines() {
    //split the text by lines
    this.textLines = this.node.text.split("\n");

    //Format the text
    if (this.textLines.length > 1 && this.textLines[this.textLines.length - 1].trim() == "") {
      this.textLines.pop();
    }

    for (let i = 0; i < this.textLines.length; i++) {
      if (this.textLines[i] == "") {
        this.textLines[i] = "!";
      }
    }
  }

  sizeRectangleToText() {
    if (!this.textEditor) return;

    //Get text element SVG size
    let lineHeight: number;
    let boundingBox = this.textEditor.nativeElement.getBBox();

    //Configure the rectangle to match the text
    this.node.dimension.height = boundingBox.height + 2 * this.padding;
    this.node.dimension.width = boundingBox.width + 2 * this.padding;

    //Draw lines to make UI look like a note
    this.linePoints = [];
    if (this.textLines.length > 1) {
      // Get height
      lineHeight = boundingBox.height / this.textLines.length;

      //Calculate line coordinates
      for (let i = 1; i < this.textLines.length; i++) {
        this.linePoints.push(
          new Line(
            { x: 0, y: this.padding + this.lineHeightOffset + i * lineHeight },
            { x: this.node.dimension.width, y: this.padding + this.lineHeightOffset + i * lineHeight }
          )
        );
      }
    }
  }

  calculateCenter(dimension: number) {
    return (dimension + this.padding) / 2;
  }

  contentChange(content: HTMLElement) {
    this.node.label = content.textContent;
  }
}
