import { NodePosition, NodeDimension } from "@swimlane/ngx-graph";
import DeleteNodeMenuItem from "src/app/features/network-designer/models/Context Menu/DeleteNodeMenuItem";
import IContextMenuItem from "src/app/features/network-designer/models/Context Menu/IContextMenuItem";
import { ContextMenuService } from "src/app/features/network-designer/services/context-menu.service";
import BaseXmlSerialization from "../BaseXmlSerialization";
import ITextNode from "./ITextNode";
import { ITopologyNode } from "./ITopologyNode";

export default class LabelNode extends BaseXmlSerialization implements ITextNode {
  type: string;
  elementType: string;
  defaultPosition: { x: number; y: number };
  contextMenuItems: IContextMenuItem[];
  id: string;
  position?: NodePosition;
  dimension?: NodeDimension;
  transform?: string;
  label?: string;
  data?: any;
  meta?: any;
  text: string;
  multiLine: boolean;
  editInProgress: boolean = false;

  addToSerializedTopology(doc: XMLDocument, parent?: HTMLElement): HTMLElement {
    let data: HTMLElement = null;

    parent.querySelectorAll(`Labels`).forEach((elem: HTMLElement) => {
      let data = this.addBlankTag(doc, elem, "LabelData");
      this.addKeyValue(doc, data, "ID", this.id);
      let location = this.addBlankTag(doc, data, "Location");
      this.addCoordinateToTag(doc, location, { x: this.defaultPosition.x + 5000, y: this.defaultPosition.y + 5000 });
      this.addKeyValue(doc, data, "Text", this.text);
    });

    return data;
  }

  initializeContextMenu(cms: ContextMenuService) {
    this.contextMenuItems = [new DeleteNodeMenuItem(cms, this.id)];
  }
}
