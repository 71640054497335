import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { BrowserCommunicationMessage } from "../models/BrowserCommunicationMessage";

@Injectable({
  providedIn: "root",
})
export class BrowserCommunicationService {
  private LabExecutedSubject: Subject<string> = new Subject<string>();
  private LabEndedSubject: Subject<string> = new Subject<string>();
  private LabFinishedSubject: Subject<string> = new Subject<string>();
  private LoggedOutSubject: Subject<boolean> = new Subject<boolean>();

  LabExecutedObservable: Observable<string> = this.LabExecutedSubject.asObservable();
  LabEndedObservable: Observable<string> = this.LabEndedSubject.asObservable();
  LabFinishedObservable: Observable<string> = this.LabFinishedSubject.asObservable();
  LoggedOutObservable: Observable<boolean> = this.LoggedOutSubject.asObservable();

  constructor() {}

  public addListener(): void {
    window.addEventListener("storage", this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage && event.newValue) {
      let eventValue: BrowserCommunicationMessage;
      try {
        eventValue = JSON.parse(event.newValue) as BrowserCommunicationMessage;

        //Verify this is a netsim defined message
        if (eventValue && "type" in eventValue && "message" in eventValue) {
          switch (eventValue.type) {
            case "LabExecuted":
              this.LabExecutedSubject.next(eventValue.message);
              break;
            case "LabFinished":
              this.LabFinishedSubject.next(eventValue.message);
              break;
            case "LabEnded":
              this.LabEndedSubject.next(eventValue.message);
              break;
            case "LoggedOut":
              this.LoggedOutSubject.next(true);
            default:
              console.log("Unreconized Event");
              break;
          }
        }
      } catch (e) {}
    }
  }

  public removeListener(): void {
    window.removeEventListener("storage", this.storageEventListener.bind(this));
  }

  public broadcastMessage(type: string, content: any) {
    let storageMessage: BrowserCommunicationMessage = {
      type: type,
      message: content,
    };

    this.removeStorage(type);
    this.writeStorage(type, storageMessage);
  }

  private readStorage(key: string): any {
    const item: string = window.localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    } else {
      return null;
    }
  }

  private writeStorage(key: string, value: any): void {
    try {
      value = value || null;
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // log error
    }
  }

  private removeStorage(key: string): void {
    window.localStorage.removeItem(key);
  }
}
