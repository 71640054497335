import { ContextMenuService } from "../../services/context-menu.service";
import IContextMenu from "./IContextMenuItem";

export default class EditDeviceMenuItem implements IContextMenu {
  label: string;
  tooltip: string;
  id: string;

  constructor(private cms: ContextMenuService, id: string) {
    this.label = "Edit";
    this.tooltip = "Edit Device";
    this.id = id;
  }

  execute() {
    this.cms.editNode(this.id);
  }
}
