import { Component, HostListener } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Subscription, of, zip } from "rxjs";
import { filter, pairwise, switchMap } from "rxjs/operators";
import Session from "./shared/models/Session";
import User from "./shared/models/User";
import { AuthService } from "./core/services/auth.service";
import { AppService } from "./core/services/app.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EngineService } from "./engine/services/engine.service";
import Device from "./engine/models/Device";
import { environment } from "src/environments/environment";
import { PreferencesService } from "./features/preferences/services/preferences.service";
import { UserPreferences } from "./shared/models/UserPreferences";
import { BrowserCommunicationService } from "./core/services/browser-communication.service";
import { AppSettingsService } from "./core/services/app-settings.service";
import { AppSetting } from "./core/models/AppSetting";
import { UserService } from "angular-auth-oidc-client/lib/userData/user-service";
import { LrsService } from "./shared/services/lrs.service";
import { Location } from "@angular/common";
import { NavigationBarService } from "./shared/services/navigation-bar.service";
import { UsersService } from "./shared/services/users.service";
import { LoadingService } from "./shared/services/loading.service";
import { LabTreeService } from "./features/lab/services/lab-tree.service";
import { GettingStartedModalComponent } from "./shared/modals/getting-started-modal/getting-started-modal.component";
import { ShortcutsComponent } from "./shared/modals/shortcuts/shortcuts.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "NetSim Web";

  isAuthorized: boolean = false;
  isLabLoaded: boolean = false;
  isContainerClaimed: boolean = false;
  isDesigner: boolean = false;
  loadedLab: string = "";
  isMenuCollapsed: boolean = true;
  currentLabId: string = "";
  currentDevices: Device[];
  shouldDisplayFeedback: boolean = false;

  lastLabSelected: string = "";
  subscriptions: Subscription[] = [];

  CurrentUser: User = null;
  CurrentSession: Session = null;

  canCreateNew: boolean = false;
  canSave: boolean = false;
  canSaveAs: boolean = false;
  canRunDesigner: boolean = false;
  bosonUrl: string = environment.bosonUri;

  hasNotice: boolean = false;
  noticeMessage: string = "";

  isHome: boolean = false;
  isConsole: boolean = false;
  isNsx: boolean = "bee" === environment.sessionApplication;

  constructor(
    private as: AuthService,
    private bcs: BrowserCommunicationService,
    private aps: AppService,
    private modalService: NgbModal,
    private lts: LabTreeService,
    private es: EngineService,
    private preferencesService: PreferencesService,
    private appSettingsService: AppSettingsService,
    private us: UsersService,
    private load: LoadingService,
    private router: Router,
    private lrs: LrsService,
    private location: Location,
    private nbs: NavigationBarService
  ) {}

  ngOnInit() {
    this.bcs.addListener();

    //initially select dark mode based on the system
    this.preferencesService.setThemeBasedOnSystem();

    this.subscriptions.push(
      this.lts.labExecuted.subscribe((labId: string) => {
        this.isLabLoaded = labId !== "" || labId !== undefined;
        this.currentLabId = labId;
        this.isContainerClaimed = true;
        this.currentDevices = [];
        this.bcs.broadcastMessage("LabExecuted", labId);
        this.router.navigate(["lab"]);
        this.load.loadLab(labId);
      })
    );

    this.subscriptions.push(
      this.lts.labEnded.subscribe((labId: string) => {
        this.isLabLoaded = false;
        this.isContainerClaimed = false;
        this.currentDevices = [];
        this.bcs.broadcastMessage("LabEnded", labId);
        // should notify api to end lab.
      })
    );

    this.subscriptions.push(
      this.appSettingsService
        .getAppSettingByKey("HasNotice")
        .pipe(
          switchMap((setting: AppSetting) => {
            return zip(of(setting), this.appSettingsService.getAppSettingByKey("NoticeMessage"));
          })
        )
        .subscribe(([hasNoticeSetting, noticeMessageSetting]: [AppSetting, AppSetting]) => {
          if (
            "True" === hasNoticeSetting.Value &&
            noticeMessageSetting.Value.length > 0 &&
            this.router.url !== "/license"
          ) {
            this.hasNotice = true;
            this.noticeMessage = noticeMessageSetting.Value;
          } else {
            this.hasNotice = false;
          }
        })
    );

    this.subscriptions.push(
      this.lts.labSelected.subscribe((labId) => {
        this.lastLabSelected = labId;
      })
    );

    this.subscriptions.push(
      this.nbs.HasChanges$.subscribe((hasChanges: boolean) => {
        this.canSaveAs = hasChanges;
      })
    );

    this.subscriptions.push(
      this.lts.labFinished.subscribe(() => {
        this.bcs.broadcastMessage("LabFinished", this.currentLabId);
      })
    );

    this.subscriptions.push(
      this.nbs.CanRunDesigner$.subscribe((value: boolean) => {
        this.canRunDesigner = value;
      })
    );

    this.subscriptions.push();
    this.subscriptions.push(
      this.nbs.CanCreateNewDocument$.subscribe((createEnabled: boolean) => {
        this.canCreateNew = createEnabled;
      })
    );

    this.subscriptions.push(
      this.aps.CurrentUser$.subscribe(() => {
        if (this.aps.CurrentUser) {
          this.preferencesService.getCurrentPreferences().subscribe((settings: UserPreferences) => {
            this.preferencesService.setThemeBasedOnPreferences(settings);
            if (settings.acceptedLicenseAgreement === false) {
              this.router.navigate(["license"]);
              this.hasNotice = false;
            } else if (
              (settings.displayGettingStarted === undefined || settings.displayGettingStarted === true) &&
              this.router.url === "/"
            ) {
              this.OpenGettingStarted();
            }
          });
        }
      })
    );

    this.subscriptions.push(
      this.bcs.LoggedOutObservable.subscribe((value) => {
        this.as.logout();
      })
    );

    this.subscriptions.push(
      this.nbs.CreateNewTopology$.subscribe(() => {
        //this.OpenDesigner();
      })
    );

    // This will set the color scheme in the event the user changes their system preferences
    // Some versions of safari will not load this function so check to see if it is present first
    try {
      window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => {
        this.preferencesService.getCurrentPreferences().subscribe((settings: UserPreferences) => {
          this.preferencesService.setThemeBasedOnPreferences(settings);
        });
      });
    } catch (ex) {
      console.log("This browser does not support integration with system dark mode preferences.");
    }
    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.isHome = event.url === "/";
          this.isConsole = event.url.startsWith("/console");
        }

        this.isDesigner = window.location.pathname.startsWith("/designer");
      })
    );

    this.aps.bootstrap();
    this.es.bootstrap();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    this.as.ngOnDestroy();
    this.aps.stopIdleUserChecks();
    this.aps.stopTokenPolling();

    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  @HostListener("window:mousemove")
  @HostListener("window:keyup")
  refreshUserState() {
    this.aps.notifyUserIsPresent();
  }

  private escapeDown: boolean = false;
  private navShortcuts = {
    "esc+h": "",
    "esc+o": "open",
    "esc+s": "save",
    "esc+l": "labs",
    "esc+d": "designer",
    "esc+p": "preferences",
    "esc+n": "lab",
  };
  @HostListener("window:keydown", ["$event"])
  keyDown(event: KeyboardEvent) {
    if (event.key.toLocaleLowerCase() === "escape") {
      this.escapeDown = true;
    }
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    const shift = event.shiftKey ? "shift+" : "";
    const ctrl = event.ctrlKey ? "ctrl+" : "";
    const alt = event.altKey ? "alt+" : "";
    const esc = this.escapeDown ? "esc+" : "";
    const key = event.key.toLowerCase();

    // Include all of the modifiers for exclusion ('Ctrl + Esc + N' is not the same as 'Esc + N')
    const lookup = ctrl + shift + esc + alt + key;

    if ("esc+escape" === lookup) {
      this.escapeDown = false;
    } else if ("ctrl+shift+?" === lookup) {
      this.OpenKeyboardShortcuts();
    } else if (lookup in this.navShortcuts) {
      this.router.navigate([this.navShortcuts[lookup]]);
    }
  }

  OpenGettingStarted() {
    this.modalService.open(GettingStartedModalComponent, { size: "xl", scrollable: true });
  }

  OpenKeyboardShortcuts() {
    this.modalService.open(ShortcutsComponent, { size: "lg", scrollable: false });
  }
}
