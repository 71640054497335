import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-text-editor",
  templateUrl: "./text-editor.component.html",
  styleUrls: ["./text-editor.component.scss"],
})
export class TextEditorComponent implements OnInit {
  @Input() content: string = "";
  @Output() contentChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  contentChanged(event: string) {
    console.log(event);
    this.contentChange.emit(event);
  }
}
