import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter802 extends BaseInventoryRouter {
  constructor() {
    super("802", "800", 4, 12);
    this.maxAddons = 0;
    this.defaultInterfaces.addEthernetInterfaces("Ethernet", 1, null, this.maxAddons);
    this.defaultInterfaces.addBriInterfaces(1, null, this.maxAddons);
  }
}
