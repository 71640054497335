import { LogLevel, OidcConfigService } from "angular-auth-oidc-client";
//https://app-apiv2-netsim-prod-eastus-001-nsx.azurewebsites.net
export const environment = {
  production: true,
  title: "NetSimX Online",
  apiUri: "https://app-apiv2-netsim-prod-eastus-001-nsx.azurewebsites.net",
  serverlessUri: "https://netsim-web-serverless.azurewebsites.net/api",
  identityServerUri: "https://login.boson.com",
  bosonUri: "https://www.boson.com",
  secureRoutes: "https://app-apiv2-netsim-prod-eastus-001-nsx.azurewebsites.net",
  homePageVideoId: "039ilb7A67I",
  latestVersion: "14.6.0",
  sessionApplication: "bee",
  timeUntilFeedbackRequest: 120,
  displayFeedbackDelay: 30,
  defaultPreferences: {
    consoleFontName: "Courier New",
    consoleFontFamily: "'Courier New', monospace",
    consoleFontSize: 11,
    consoleForeground: "#32cd32",
    consoleBackground: "#000000",
    documentTextFontName: "Arial",
    documentTextFontFamily: "'Arial', 'sans-serif'",
    documentTextFontSize: 12,
    documentCodeFontName: "Courier New",
    documentCodeFontFamily: "'Courier New', monospace",
    documentCodeFontSize: 12,
    documentContainerSize: "full",
    documentShowPreview: true,
    designerSnapEnabled: true,
    designerSnapSensitivity: 25,
    designerShowLabels: true,
    designerShowNotes: true,
    designerSimplifiedInventory: true,
    graderExtraCommandColor: "#2ccce4",
    graderMissingCommandColor: "#f47373",
    autoCollapseTree: true,
    logLevel: LogLevel.Error,
    maxIdTokenIatOffsetAllowedInSeconds: 8600,
    acceptedLicenseAgreement: false,
    theme: "Auto",
    externalConsole: "tab", //other option is "window"
    remindMeAboutCaseSensitiveGradeResults: true,
  },
};

export function LoadAuthenticationConfig(oidcConfigService: OidcConfigService) {
  let originUrl: string = document.getElementsByTagName("base")[0].href.slice(0, -1);
  return () => {
    return oidcConfigService.withConfig({
      stsServer: environment.identityServerUri,
      redirectUrl: originUrl + "/callback",
      clientId: "spaCodeClient",
      responseType: "code",
      scope: "openid profile resourceApi NetSimApi",
      postLogoutRedirectUri: originUrl,
      forbiddenRoute: "/forbidden",
      unauthorizedRoute: "/unauthorized",
      silentRenew: true,
      silentRenewUrl: originUrl + "/assets/silent-renew.html",
      historyCleanupOff: true,
      autoUserinfo: true,
      logLevel: environment.defaultPreferences.logLevel,
      renewTimeBeforeTokenExpiresInSeconds: 10,
      maxIdTokenIatOffsetAllowedInSeconds: environment.defaultPreferences.maxIdTokenIatOffsetAllowedInSeconds,
      secureRoutes: [environment.secureRoutes],
    });
  };
}
