import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter2522 extends BaseInventoryRouter {
  constructor() {
    super("2522", "2500", 16, 8);
    this.maxAddons = 0;
    this.defaultInterfaces.addEthernetInterfaces("Ethernet", 1, null, this.maxAddons);
    this.defaultInterfaces.addBriInterfaces(1, null, this.maxAddons);
    this.defaultInterfaces.addSerialInterfaces(10, null, this.maxAddons);
  }
}
