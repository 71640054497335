import { Injectable } from "@angular/core";
import { Subject } from "rxjs-compat";

@Injectable({
  providedIn: "root",
})
export class NetMapService {
  private CenterGraphSubject = new Subject<boolean>();
  CenterGraphObservable = this.CenterGraphSubject.asObservable();
  fileToLoad: number;

  constructor() {}

  public CenterNetMap() {
    this.CenterGraphSubject.next(true);
  }
}
