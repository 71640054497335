import Point from "./Point";

export default class Line {
  P1: Point;
  P2: Point;

  public constructor(point1: Point, point2: Point) {
    this.P1 = point1;
    this.P2 = point2;
  }

  toArray(): Point[] {
    return [this.P1, this.P2];
  }
}
