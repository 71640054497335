import IHasInterfaces from "../../Shared/interfaces/IHasInterfaces";
import IInventoryDevice from "../Devices/IInventoryDevice";
import InventoryEthernetInterface from "../Interfaces/InventoryEthernetInterface";
import IAddon from "./IAddon";

export default class NM4Ethernet implements IAddon {
  name: string = "NM-4E";
  description: string = "4 Ethernet";

  addToDevice(device: IHasInterfaces, rack: number, slot: number) {
    for (let i = 0; i < 4; i++) {
      device.interfaces.push(new InventoryEthernetInterface("Ethernet", rack, slot, i));
    }
  }
}
