import { Node, NodeDimension, NodePosition } from "@swimlane/ngx-graph";
import DeleteNodeMenuItem from "src/app/features/network-designer/models/Context Menu/DeleteNodeMenuItem";
import EditDeviceMenuItem from "src/app/features/network-designer/models/Context Menu/EditDeviceMenuItem";
import IContextMenuItem from "src/app/features/network-designer/models/Context Menu/IContextMenuItem";
import IContextMenuSupport from "src/app/features/network-designer/models/Context Menu/IContextMenuSupport";
import { IInventoryConfiguration } from "src/app/features/network-designer/models/Inventory/Interfaces/IInventoryConfiguration";
import IInventoryInterface from "src/app/features/network-designer/models/Inventory/Interfaces/IInventoryInterface";
import IHasInterfaces from "src/app/features/network-designer/models/Shared/interfaces/IHasInterfaces";
import { ContextMenuService } from "src/app/features/network-designer/services/context-menu.service";
import { ITopologyNode } from "./ITopologyNode";

export default class DeviceNode implements ITopologyNode, IHasInterfaces, IContextMenuSupport {
  constructor() {
    this.contextMenuItems = [];
  }

  // Default Node Properties
  id: string;
  position?: NodePosition;
  dimension?: NodeDimension;
  transform?: string;
  label?: string;
  data?: any;
  meta?: any;

  //NetSim Device Properties
  type: string;
  elementType: string;
  model: string;
  series: string;
  flashMemory: number;
  systemMemory: number;
  interfaces: IInventoryInterface[];
  configuration: IInventoryConfiguration;
  defaultPosition: { x: number; y: number };
  contextMenuItems: IContextMenuItem[];

  initializeContextMenu(cms: ContextMenuService) {
    this.contextMenuItems = [new EditDeviceMenuItem(cms, this.id), new DeleteNodeMenuItem(cms, this.id)];
  }
}
