export default class BosonVector {
  x: number;
  y: number;

  public constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  public get Magnitude(): number {
    return Math.pow(Math.pow(this.x, 2) + Math.pow(this.y, 2), 0.5);
  }

  public getNormalizedVector(): BosonVector {
    return new BosonVector(this.x / this.Magnitude, this.y / this.Magnitude);
  }

  public multiplyScalar(scalar: number): BosonVector {
    return new BosonVector(this.x * scalar, this.y * scalar);
  }

  public dotProduct(b: BosonVector): number {
    return this.x * b.x + this.y * b.y;
  }

  public angleBetweenVectors(b: BosonVector): number {
    let cosTheta: number = this.dotProduct(b) / (this.Magnitude * b.Magnitude);

    return 1 / cosTheta;
  }
}
