import IHasInterfaces from "../../Shared/interfaces/IHasInterfaces";
import IInventoryDevice from "../Devices/IInventoryDevice";
import InventoryEthernetInterface from "../Interfaces/InventoryEthernetInterface";
import IAddon from "./IAddon";

export default class Hwic2FastEthernet implements IAddon {
  name: string = "HWIC-2FE";
  description: string = "2 Fast Ethernet";

  addToDevice(device: IHasInterfaces, rack: number, slot: number) {
    for (let i = 0; i < 2; i++) {
      device.interfaces.push(new InventoryEthernetInterface("FastEthernet", rack, slot, i));
    }
  }
}
