<div class="d-flex feedback-nav-item" (mouseenter)="componentHover()">
  <span class="mr-2 ml-2 mt-1">Feedback</span>
  <div
    class="star-feedback-container"
    *ngFor="let star of Stars"
    (click)="starClicked(star)"
    (mouseenter)="starEntered(star)"
    (mouseleave)="starLeft(star)"
    [class.score-complete]="ScoreEntered == true"
    [title]="titles[star.value - 1]"
  >
    <span
      class="mdi m-1"
      [class.mdi-star]="star.isActive == true"
      [class.mdi-star-outline]="star.isActive == false"
    ></span>
  </div>
</div>
<div #ExtendedFeedbackMenu class="feedback-popup-container small" [ngbCollapse]="ExtendedCollapsed">
  <div class="extended-feedback-wrapper">
    <app-extended-feedback
      #ExtendedFeedbackMenu
      (FeedbackSumbitted)="extendedSubmit($event)"
      [Score]="FeedbackResponse.Score"
      [(Comment)]="FeedbackResponse.Comment"
      [(DoNotContactMe)]="FeedbackResponse.DoNotContactMe"
    >
    </app-extended-feedback>
  </div>
</div>
<div class="feedback-popup-container small" [ngbCollapse]="ThankYou">
  <div class="thank-you-wrapper">
    <strong>Thank You!</strong>
  </div>
</div>
