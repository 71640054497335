<nav class="navbar navbar-expand-lg navbar-light" [ngClass]="{ 'nav-hidden': isHidden }">
  <a class="navbar-brand" id="homeButton" routerLink="." routerLinkActive="active">
    <img class="home-icon" src="../../../assets/images/netsim-spin-white.png" alt="Boson&trade; logo." />
  </a>

  <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">&#9776;</button>

  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item dropdown" *ngIf="isAuthorized" routerLinkActive="active">
        <span
          class="nav-link dropdown-toggle"
          routerLinkActive="active"
          id="fileDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="mdi mdi-content-save"></span> File
        </span>
        <div class="dropdown-menu" aria-labelledby="fileDropdown">
          <a class="dropdown-item" (click)="OpenFileClick()" routerLink="/open">Open...</a>
          <a class="dropdown-item" *ngIf="isDesigner" (click)="NewTopology()">New Topology...</a>
          <!-- <span class="dropdown-item" (click)="isMenuCollapsed = true" [ngClass]="!canSave ? 'disabled':''">Save</span> -->
          <a class="dropdown-item" (click)="SaveFileClick()" routerLink="/save" [ngClass]="!canSaveAs ? 'disabled' : ''"
            >Save As...</a
          >
        </div>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/labs"> <span class="mdi mdi-flask"></span> Labs </a>
      </li>
      <li class="nav-item" *ngIf="isAuthorized" routerLinkActive="active">
        <a class="nav-link" routerLink="/designer"> <span class="mdi mdi-lan"></span> Designer </a>
      </li>
      <li class="nav-item dropdown multi-level-dropdown" *ngIf="canView">
        <span
          class="nav-link dropdown-toggle"
          routerLinkActive="active"
          id="viewDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="mdi mdi-open-in-new"></span> View
        </span>
        <ul class="dropdown-menu" aria-labelledby="viewDropdown">
          <li>
            <span class="dropdown-item" *ngIf="isLabLoaded" (click)="OpenLabDocument()">Lab Document</span>
          </li>
          <li>
            <span class="dropdown-item" *ngIf="isLabLoaded" (click)="OpenNetMap()">Topology</span>
          </li>
          <li>
            <span class="dropdown-item" *ngIf="isEngineRunning && !isLabLoaded" (click)="OpenNetMapForUserFile()"
              >Topology</span
            >
          </li>
          <li class="dropdown-submenu">
            <span class="dropdown-item dropdown-toggle" *ngIf="isEngineRunning"
              >Devices<span class="mdi mdi-menu-right"></span
            ></span>
            <ul class="dropdown-menu" *ngIf="isEngineRunning">
              <li *ngFor="let device of currentDevices">
                <span class="dropdown-item" (click)="OpenConsole(device.DeviceNumber)"
                  >Console {{ device.DeviceName }}</span
                >
              </li>
            </ul>
          </li>
          <li class="dropdown-submenu">
            <span
              class="dropdown-item dropdown-toggle"
              *ngIf="references && references.length > 0"
              [ngClass]="!isLabLoaded ? 'disabled' : ''"
              >References<span class="mdi mdi-menu-right"></span
            ></span>
            <ul class="dropdown-menu" *ngIf="isLabLoaded">
              <li>
                <span
                  class="dropdown-item"
                  *ngIf="references.includes('/command-summary')"
                  [ngClass]="!isLabLoaded ? 'disabled' : ''"
                  (click)="OpenCommandSummary()"
                  >Command Summary</span
                >
              </li>
              <li>
                <span
                  class="dropdown-item"
                  *ngIf="references.includes('/topology')"
                  [ngClass]="!isLabLoaded ? 'disabled' : ''"
                  (click)="OpenTopology()"
                  >Detailed Topology</span
                >
              </li>
              <li>
                <span
                  class="dropdown-item"
                  *ngIf="references.includes('/ip-addresses')"
                  [ngClass]="!isLabLoaded ? 'disabled' : ''"
                  (click)="OpenIpAddresses()"
                  >IP Address Table</span
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="nav-item" *ngIf="canGrade">
        <a class="nav-link" role="button" routerLink="/grade"> <span class="mdi mdi-progress-check"></span> Grade </a>
      </li>
      <li class="nav-item dropdown" *ngIf="isDesigner">
        <span
          class="nav-link dropdown-toggle"
          role="button"
          [ngClass]="!canRunDesigner ? 'disabled' : ''"
          (click)="OnRunTopologyClick()"
        >
          <span class="mdi mdi-play-circle-outline"></span> Run
        </span>
      </li>
      <li class="nav-item dropdown" *ngIf="canEnd">
        <span class="nav-link dropdown-toggle" role="button" (click)="OnEndLabClick()">
          <span class="mdi mdi-stop-circle-outline"></span> End
        </span>
      </li>

      <li class="nav-item dropdown">
        <span
          class="nav-link dropdown-toggle"
          routerLinkActive="active"
          id="helpDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="mdi mdi-help-circle"></span> Help
        </span>
        <div class="dropdown-menu" aria-labelledby="helpDropdown">
          <a
            class="dropdown-item"
            (click)="isMenuCollapsed = true"
            href="https://boson.com/help/netsim/v1/#/"
            target="_blank"
            rel="noopener"
            >User Guide</a
          >
          <a
            class="dropdown-item"
            (click)="isMenuCollapsed = true"
            href="{{ bosonUrl }}/Account/SupportTickets"
            target="_blank"
            rel="noopener"
            >Create Support Ticket</a
          >
          <span class="dropdown-item" (click)="isMenuCollapsed = true" (click)="OpenGettingStarted()"
            >Quick Start Guide</span
          >
          <span class="dropdown-item" (click)="isMenuCollapsed = true" (click)="OpenKeyboardShortcuts()"
            >Keyboard Shortcuts</span
          >
        </div>
      </li>
    </ul>
    <app-feedback *ngIf="shouldDisplayFeedback == true" class="ml-auto"></app-feedback>
    <ul class="navbar-nav">
      <li *ngIf="canResumeLab" class="nav-item inverted">
        <a class="nav-link" role="button" routerLink="/lab">
          <span class="mdi mdi-keyboard-backspace"></span> Resume Lab
        </a>
      </li>
      <li *ngIf="isAuthorized" class="nav-item dropdown">
        <span
          class="nav-link"
          routerLinkActive="active"
          id="accountDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          aria-label="Expand user account dropdown."
        >
          <span id="user-icon" class="mdi mdi-account"></span>
        </span>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="accountDropdown">
          <a class="dropdown-item" routerLink="/preferences"><span class="mdi mdi-cog"></span> Preferences</a>
          <a class="dropdown-item" routerLink="/activate"
            ><span class="mdi mdi-plus-box-multiple"></span> Activate Keys</a
          >
          <a class="dropdown-item" (click)="OpenAbout()"><span class="mdi mdi-information"></span> About NetSim</a>
          <div class="dropdown-divider"></div>
          <span class="dropdown-item" (click)="OnLogoutClick()"><span class="mdi mdi-logout"></span> Logout</span>
        </div>
      </li>
      <li *ngIf="!isAuthorized" class="nav-item">
        <a class="nav-link" role="button" routerLink="/auto-login"> Sign In </a>
      </li>
    </ul>
  </div>
</nav>
