import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import SerialConnectionEdge from "src/app/shared/models/TopologyCanvas/Edges/SerialConnectionEdge";

@Component({
  selector: "app-serial-connection-options",
  templateUrl: "./serial-connection-options.component.html",
  styleUrls: ["./serial-connection-options.component.scss"],
})
export class SerialConnectionOptionsComponent implements OnInit {
  selectedDevice: string;
  deviceOneName: string;
  deviceTwoName: string;
  deviceOneId: string;
  deviceTwoId: string;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  closeEditor() {
    this.activeModal.close(this.selectedDevice == this.deviceOneId);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  initialize(serial: SerialConnectionEdge) {
    this.deviceOneName = serial.Device1Name;
    this.deviceTwoName = serial.Device2Name;
    this.deviceOneId = serial.Device1Id;
    this.deviceTwoId = serial.Device2Id;

    if (serial.dceEnd) {
      this.selectedDevice = serial.dceEnd == serial.Device1InterfaceId ? serial.Device1Id : serial.Device2Id;
    } else {
      this.selectedDevice = this.deviceOneId;
      serial.dceEnd = serial.Device1InterfaceId;
      serial.dteEnd = serial.Device2InterfaceId;
    }
  }
}
