import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "../../../core/services/app.service";
import { UserPreferences } from "../../models/UserPreferences";
import { PreferencesService } from "../../../features/preferences/services/preferences.service";

@Component({
  selector: "app-getting-started-modal",
  templateUrl: "./getting-started-modal.component.html",
  styleUrls: ["./getting-started-modal.component.scss"],
})
export class GettingStartedModalComponent implements OnInit {
  public displayGettingStarted: boolean;
  public darkModeActive: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private as: AppService,
    private preferencesService: PreferencesService
  ) {}

  ngOnInit(): void {
    if (this.as.CurrentUser) {
      this.preferencesService.getCurrentPreferences().subscribe((settings: UserPreferences) => {
        this.displayGettingStarted =
          settings.displayGettingStarted === undefined ? true : settings.displayGettingStarted;
      });
    }

    this.darkModeActive = this.preferencesService.getIsDarkModeActive();

    this.preferencesService.SetToDarkModeObservable.subscribe((isDark: boolean) => {
      this.darkModeActive = isDark;
    });
  }

  onClose() {
    this.activeModal.close();
  }

  checkChanged(event) {
    this.preferencesService.updatePreferences({ displayGettingStarted: event.target.checked }).toPromise();
  }
}
