import { Injectable, Inject } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { OidcSecurityService } from "angular-auth-oidc-client";
import UserData from "../models/UserData";
import { SessionsService } from "../../shared/services/sessions.service";
import { first, switchMap, tap } from "rxjs/operators";
import Session from "../../shared/models/Session";
import { UsersService } from "../../shared/services/users.service";
import { BrowserCommunicationService } from "./browser-communication.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthService {
  //Fields
  private checkAuthCompleted$ = new ReplaySubject(1);

  CurrentSession: Session;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private ss: SessionsService,
    private us: UsersService,
    private bcs: BrowserCommunicationService,
    private http: HttpClient,
    @Inject("BASE_URL") private originUrl: string,
    @Inject("AUTH_URL") private authUrl: string,
    @Inject("BOSON_URL") private bosonUrl: string
  ) {}

  ngOnDestroy(): void {}

  public get isAuthenticated$(): Observable<boolean> {
    return this.checkAuthCompleted$.pipe(
      first(),
      switchMap((_) => this.oidcSecurityService.isAuthenticated$)
    );
  }

  public getIsAuthorized(): Observable<boolean> {
    return this.oidcSecurityService.checkAuth().pipe(tap((_) => this.checkAuthCompleted$.next()));
  }

  public getIsAuthorizedWithServer(): Observable<boolean> {
    return this.oidcSecurityService.checkAuthIncludingServer();
  }

  getUserData(): Observable<UserData> {
    return this.oidcSecurityService.userData$;
  }

  inspectToken() {
    return this.http.get(`${environment.apiUri}/account/inspect`);
  }

  // Trigger the sign in redirect
  login() {
    const urlHandler = (url: string) => {
      let BosonLoginURL = url.replace(`${this.authUrl}/connect/authorize`, `${this.bosonUrl}/Account/Default.aspx`);
      window.location.href = BosonLoginURL;
      return;
    };

    if ((window.location.pathname == "/" || this.RouteStorage == "/") && !this.LoginInProgress) {
      this.LoginInProgress = true;
      localStorage.clear();
    }

    this.oidcSecurityService.authorize({ urlHandler });
  }

  private oidcLogout() {
    this.oidcSecurityService.logoff((url) => {
      //console.log('navigating to logout url');
      window.location.href = `${this.bosonUrl}/Api/Logout.aspx?redirect_uri=${encodeURIComponent(this.originUrl)}`;
    });
  }

  // Trigger the logout redirect since this is not an observable a switchmap is not necessary
  logout() {
    if (this.CurrentSession == null) {
      this.oidcLogout();
    } else {
      this.us.releaseSession(this.CurrentSession.UserId).subscribe(
        () => {
          this.bcs.broadcastMessage("LoggedOut", null);
          this.oidcLogout();
        },
        (error) => {
          this.oidcLogout();
        }
      );
    }
  }

  logoutLocal() {
    this.oidcSecurityService.logoffLocal();
  }

  logoutAndRevoke() {
    this.oidcSecurityService.logoffAndRevokeTokens();
  }

  public getToken() {
    const token = this.oidcSecurityService.getToken();
    return token;
  }

  public get RouteStorage(): string {
    return window.localStorage.getItem("NetSimPath");
  }

  public set RouteStorage(value: string) {
    try {
      if (!value) {
        // if the value is null or undefined remove it localstorage will convert null and undefined to strings
        window.localStorage.removeItem("NetSimPath");
      } else {
        window.localStorage.setItem("NetSimPath", value);
      }
    } catch (error) {
      // log error
    }
  }

  public get LoginInProgress(): boolean {
    return this.readStorage("LoginInProgress") as boolean;
  }

  public set LoginInProgress(value: boolean) {
    if (!value) {
      this.removeStorage("LoginInProgress");
    } else {
      this.writeStorage("LoginInProgress", value);
    }
  }

  private readStorage(key: string): any {
    const item: string = window.localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    } else {
      return null;
    }
  }

  private writeStorage(key: string, value: any): void {
    try {
      value = value || null;
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // log error
    }
  }

  private removeStorage(key: string): void {
    window.localStorage.removeItem(key);
  }
}
