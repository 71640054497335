import IHasInterfaces from "../../Shared/interfaces/IHasInterfaces";
import IInventoryDevice from "../Devices/IInventoryDevice";
import InventoryBriInterface from "../Interfaces/InventoryBriInterface";
import InventorySerialInterface from "../Interfaces/InventorySerialInterface";
import IAddon from "./IAddon";

export default class Hwic8Serial implements IAddon {
  name: string = "HWIC-8A/S-232";
  description: string = "8 Serial";

  addToDevice(device: IHasInterfaces, rack: number, slot: number) {
    for (let i = 0; i < 8; i++) {
      device.interfaces.push(new InventorySerialInterface(rack, slot, i));
    }
  }
}
