import IHasInterfaces from "../../Shared/interfaces/IHasInterfaces";
import IInventoryDevice from "../Devices/IInventoryDevice";
import InventoryBriInterface from "../Interfaces/InventoryBriInterface";
import IAddon from "./IAddon";

export default class Wic1Bri implements IAddon {
  name: string = "WIC-1B-S/T";
  description: string = "1 Bri";

  addToDevice(device: IHasInterfaces, rack: number, slot: number) {
    device.interfaces.push(new InventoryBriInterface(rack, slot, 0));
  }
}
