import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter2507 extends BaseInventoryRouter {
  constructor() {
    super("2507", "2500", 8, 16);
    this.maxAddons = 0;
    this.defaultInterfaces.addEthernetInterfaces("Ethernet", 1, null, this.maxAddons);
    this.defaultInterfaces.addSerialInterfaces(2, null, this.maxAddons);
  }
}
