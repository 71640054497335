<div [class]="isStandalone ? 'container-fluid' : ''">
  <div *ngIf="isExpired" class="container-fluid">
    <div class="row justify-content-center">
      <div class="col">
        <p>Your Session has expired</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col">
        <div class="btn btn-primary" (click)="reloadLab()">Reload Lab</div>
      </div>
    </div>
  </div>
  <div
    *ngIf="file && file.Data && (isLabLoaded || isViewer) && !isExpired"
    [innerHTML]="file.Data | safe : 'html'"
    class="lab-document viewport-settings"
    [class]="isStandalone ? 'standalone' : ''"
  ></div>
  <div *ngIf="file && !file.Data && isLabLoaded && !isExpired" class="m-auto">
    <p>This resource could not be found or does not exist for this lab.</p>
  </div>
  <div *ngIf="(!file || !file.Data) && isViewer">
    <p>Select a file using the button above.</p>
  </div>
  <progress-spinner *ngIf="!isLabLoaded && !isViewer" style="display: block; text-align: center; padding-top: 40%">
  </progress-spinner>
</div>
