import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const expectedRoles = next.data["expectedRoles"] as Array<string>;
    return this.authService
      .getIsAuthorizedWithServer()
      .map((authorized) => {
        if (!authorized) {
          this.authService.RouteStorage = window.location.pathname + window.location.search;
          this.router.navigate(["/forbidden"]);
          return false;
        } else if (!this.checkTokenRoles(this.authService.getToken(), expectedRoles)) {
          this.router.navigate(["/forbidden"]);
          return false;
        } else {
          return true;
        }
      })
      .catch((err) => {
        console.log(err);
        return of(false);
      });
  }

  checkTokenRoles(token: any, expectedRoles: Array<string>): boolean {
    // check if token has expected roles...
    return true;
  }
}
