import { ContextMenuService as ContextMenuService } from "../../services/context-menu.service";
import IContextMenu from "./IContextMenuItem";

export default class DeleteNodeMenuItem implements IContextMenu {
  label: string;
  tooltip: string;
  nodeId: string;

  constructor(private cms: ContextMenuService, id: string) {
    this.label = "Delete";
    this.tooltip = "Delete Device";
    this.nodeId = id;
  }

  execute() {
    this.cms.deleteNode(this.nodeId);
  }
}
