<div class="modal-header">
  <h5 class="modal-title">Select DCE End</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-check">
    <input class="form-check-input" value="{{ deviceOneId }}" type="radio" [(ngModel)]="selectedDevice" />
    <label class="form-check-label">
      {{ deviceOneName }}
    </label>
  </div>
  <div class="form-check">
    <input class="form-check-input" value="{{ deviceTwoId }}" type="radio" [(ngModel)]="selectedDevice" />
    <label class="form-check-label">
      {{ deviceTwoName }}
    </label>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="closeEditor()">Submit</button>
  <button type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
</div>
