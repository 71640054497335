import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import Container from "../models/Container";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ContainersService {
  private clientUri: string = environment.apiUri + "/containers";

  constructor(private http: HttpClient) {}

  getContainer(id: string): Observable<Container> {
    return this.http.get<Container>(`${this.clientUri}/${id}`);
  }
}
