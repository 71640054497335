<div class="modal-header">
  <h5>Select Interface</h5>
</div>
<div class="modal-body">
  <div *ngIf="deviceInterfaces && deviceInterfaces.length > 0" class="list-group">
    <a
      *ngFor="let interface of deviceInterfaces"
      class="list-group-item list-group-item-action"
      (click)="interfaceClick(interface)"
      >{{ interface.name }}</a
    >
  </div>
  <div *ngIf="!deviceInterfaces || deviceInterfaces.length == 0" class="list-group">
    <p>There are no available interfaces on this device</p>
  </div>
</div>
