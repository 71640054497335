<div class="modal-header" style="border: none">
  <div style="display: flex; width: 100%">
    <img
      style="width: 147px"
      src="assets/images/NetSim-Network-Simulator.svg"
      class="light-mode-only"
      alt="The NetSim&trade; Network Simulator&trade; logo."
    />
    <img
      style="width: 147px"
      src="assets/images/NetSim-Network-Simulator-DarkMode.png"
      class="dark-mode-only"
      alt="The NetSim&trade; Network Simulator&trade; logo."
    />
    <span
      style="
        background-color: var(--NetSimPrimary);
        width: 100%;
        flex: auto;
        height: 10px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 48px;
      "
      >&nbsp;</span
    >
    <img
      class="light-mode-only"
      style="height: 48px; margin-left: auto; margin-top: 10px"
      src="assets/images/060514_boson_logo.png"
      alt="The Boson&trade; logo."
    />
    <img
      class="dark-mode-only"
      style="height: 48px; margin-left: auto; margin-top: 10px"
      src="assets/images/060514_boson_logo.dark.png"
      alt="The Boson&trade; logo."
    />
  </div>
</div>
<div class="modal-body" style="overflow: hidden">
  <app-getting-started></app-getting-started>
</div>
<div class="modal-footer" style="border: none">
  <input type="checkbox" [checked]="displayGettingStarted" (change)="checkChanged($event)" /> Display Quick Start Guide
  on startup.
  <button type="button" ngbAutofocus class="btn btn-primary" (click)="onClose()">Close</button>
</div>
