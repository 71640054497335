import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-shortcuts",
  templateUrl: "./shortcuts.component.html",
  styleUrls: ["./shortcuts.component.scss"],
})
export class ShortcutsComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onClose() {
    this.activeModal.close();
  }
}
