import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter1005 extends BaseInventoryRouter {
  constructor() {
    super("1005", "1000", 4, 4);
    this.maxAddons = 0;
    this.defaultInterfaces.addEthernetInterfaces("Ethernet", 1, null, null);
    this.defaultInterfaces.addSerialInterfaces(1, null, null);
  }
}
