export default class UserAgentData {
  UserAgentString: string;
  DeviceFamily: string;
  UserAgentFamily: string;
  UserAgentMajorVersion: string;
  UserAgentMinorVersion: string;
  OSFamily: string;
  OSMajorVersion: string;
  OSMinorVersion: string;
}
