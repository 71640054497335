import { ContextMenuService } from "../../services/context-menu.service";
import IContextMenu from "./IContextMenuItem";

export default class DeleteConnectionMenuItem implements IContextMenu {
  label: string;
  tooltip: string;
  id: string;
  private cms: ContextMenuService;

  constructor(cms: ContextMenuService, id: string) {
    this.id = id;
    this.cms = cms;
    this.label = "Delete";
    this.tooltip = "Delete this connection";
  }

  execute() {
    this.cms.deleteConnection(this.id);
  }
}
