import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import GetQueryParameters from "../models/GetQueryParameters";
import { getLocaleDateTimeFormat } from "@angular/common";
import UserSaveFile from "src/app/shared/models/Files/UserSaveFile";

@Injectable({
  providedIn: "root",
})
export class UserFilesService {
  uri = environment.apiUri + "/Users";

  constructor(private http: HttpClient) {}

  getFiles(parameters: GetQueryParameters) {
    var httpParams: HttpParams = new HttpParams();

    Object.keys(parameters).map(function (index) {
      httpParams = httpParams.append(index, parameters[index]);
    });

    return this.http.get(`${environment.apiUri}/user-files`, { params: httpParams });
  }

  getSupportedFileTypes(filter: string[]) {
    const fileTypes = [
      {
        extension: "bsn",
        name: "Network",
      },
      {
        extension: "lab",
        name: "Lab",
      },
      {
        extension: "nwc",
        name: "Network Configuration",
      },
      {
        extension: "top",
        name: "Topology",
      },
      {
        extension: "rtr",
        name: "Router Configuration",
      },
    ];

    if (!filter || filter.length == 0) {
      return fileTypes.filter((fileType) => {
        return ["bsn", "lab", "top"].includes(fileType.extension);
      });
    } else {
      return fileTypes.filter((fileType) => {
        return filter.includes(fileType.extension);
      });
    }
  }

  shareFile(id) {
    return this.http.post(`${environment.apiUri}/user-file-shares/`, { FileId: id });
  }

  saveFile(file: UserSaveFile, type: string) {
    return this.http.post(`${environment.apiUri}/user-files/save/${type}`, file);
  }

  getUserFileLabMeta(id) {
    return this.http.get(`${environment.apiUri}/user-files/${id}/meta`);
  }

  getUserFileTopology(id) {
    return this.http.get(`${environment.apiUri}/user-files/${id}/topology`, { responseType: "text" });
  }

  getFileDownload(id) {
    return this.http.get(`${environment.apiUri}/user-files/${id}/download`);
  }

  trashFile(id) {
    let obj = {
      FileId: id,
      DeletedAt: new Date().toISOString(),
    };

    return this.http.patch(`${environment.apiUri}/user-files/${id}`, obj);
  }

  recoverFile(id) {
    let obj = {
      FileId: id,
      DeletedAt: "0001-01-01T00:00:00",
    };

    return this.http.patch(`${environment.apiUri}/user-files/${id}`, obj);
  }

  deleteFile(id) {
    return this.http.delete(`${environment.apiUri}/user-files/${id}`);
  }
  getFile(userId, fileId) {
    return this.http.get(`${this.uri}/${userId}/file/${fileId}`, { responseType: "arraybuffer" });
  }

  emptyTrash(ids: number[]) {
    return this.http.post(`${environment.apiUri}/user-files/delete/`, ids);
  }

  verifyAndUploadFile(file: any) {
    const formData = new FormData();
    formData.append("file", file);

    return this.http.post(`${environment.apiUri}/user-files/verify/upload/`, formData);
  }
}
