import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import OpenEditorResponse from "src/app/engine/models/OpenEditorResponse";
import Device from "../../../engine/models/Device";
import { EngineService } from "../../../engine/services/engine.service";

@Injectable({
  providedIn: "root",
})
export class ConsoleService {
  constructor(private es: EngineService) {
    es.MessageReceivedObservable.subscribe((payload) => this.NotifyMessageRecieved(payload));
  }

  //Sources
  private MessageRecievedSource = new Subject<string>();
  private OpenConsolesSource = new Subject<Array<Device>>();
  private OpenExternalConsolesSource = new Subject<number>();
  private CloseConsolesSource = new Subject<Array<number>>();
  private EditorOpenedSource = new Subject<OpenEditorResponse>();

  //Observables
  MessageRecievedObservable = this.MessageRecievedSource.asObservable();
  OpenConsolesObservable = this.OpenConsolesSource.asObservable();
  OpenExternalConsoleObservable = this.OpenExternalConsolesSource.asObservable();
  CloseConsolesObservable = this.CloseConsolesSource.asObservable();
  EditorOpenedObservable = this.EditorOpenedSource.asObservable();

  NotifyMessageRecieved(payload: string) {
    this.MessageRecievedSource.next(payload);
  }

  OpenConsoles(Devices: Array<Device>) {
    this.OpenConsolesSource.next(Devices);
  }

  OpenExternalConsole(device: number) {
    this.OpenExternalConsolesSource.next(device);
  }

  CloseConsoles(Devices: Array<number>) {
    this.CloseConsolesSource.next(Devices);
  }

  sendMessage(deviceIndex: number, input: string) {
    this.es.sendMessage("execute-input", { DeviceIndex: deviceIndex, Data: [input] });
  }

  openEditor(response: OpenEditorResponse) {
    this.EditorOpenedSource.next(response);
  }
}
