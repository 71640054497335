import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter2520 extends BaseInventoryRouter {
  constructor() {
    super("2521", "2500", 16, 8);
    this.maxAddons = 0;
    this.defaultInterfaces.addBriInterfaces(1, null, this.maxAddons);
    this.defaultInterfaces.addSerialInterfaces(4, null, this.maxAddons);
  }
}
