import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter803 extends BaseInventoryRouter {
  constructor() {
    super("803", "800", 4, 8);
    this.maxAddons = 0;
    this.defaultInterfaces.addEthernetInterfaces("Ethernet", 1, null, this.maxAddons);
    this.defaultInterfaces.addBriInterfaces(1, null, this.maxAddons);
  }
}
