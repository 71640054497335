import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { Observable, of, Subject, zip } from "rxjs";
import Session from "../../../../shared/models/Session";
import User from "../../../../shared/models/User";
import { AppService } from "../../../../core/services/app.service";
import { EngineService } from "src/app/engine/services/engine.service";
import Container from "../../../../shared/models/Container";
import { ContainersService } from "../../../../shared/services/containers.service";
import { Title } from "@angular/platform-browser";
import { environment } from "../../../../../environments/environment";
import GetDevicesResponse from "src/app/engine/models/GetDevicesResponse";
import { BrowserCommunicationService } from "src/app/core/services/browser-communication.service";

@Component({
  selector: "app-standalone-console",
  templateUrl: "./standalone-console.component.html",
  styleUrls: ["./standalone-console.component.scss"],
})
export class StandaloneConsoleComponent implements OnInit {
  public DeviceId: number;
  public CurrentUser: User;
  public CurrentSession: Session;
  public CurrentContainer: Container;

  constructor(
    private route: ActivatedRoute,
    private es: EngineService,
    private as: AppService,
    private cs: ContainersService,
    private bcs: BrowserCommunicationService,
    private title: Title
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.DeviceId = params["id"];
    });

    if (this.as.CurrentUser) {
      this.CurrentUser = this.as.CurrentUser;
    }

    if (this.as.CurrentSession) {
      this.CurrentSession = this.as.CurrentSession;

      if (this.as.CurrentSession.ContainerId) {
        this.cs.getContainer(this.as.CurrentSession.ContainerId).subscribe((container) => {
          this.CurrentContainer = container;
        });
      }
    }

    this.as.CurrentUser$.subscribe((user) => {
      this.CurrentUser = user;
    });

    this.as.CurrentSession$.first()
      .pipe(
        switchMap((session: Session) => {
          this.CurrentSession = session;
          if (session.ContainerId) {
            return this.cs.getContainer(session.ContainerId);
          } else {
            return of("");
          }
        }),
        switchMap((container: Container) => {
          this.CurrentContainer = container;
          this.es.initializeWebSocketsForStandAlone(container);
          return this.route.params;
        }),
        switchMap((params: Params) => {
          return zip(of(parseInt(params["id"])), this.es.getDevices());
        })
      )
      .subscribe(([deviceNum, devices]: [number, GetDevicesResponse]) => {
        let singleDevice = devices.Devices.filter((m) => m.DeviceNumber == deviceNum);

        if (singleDevice) {
          this.title.setTitle(`${singleDevice[0].DeviceName} - ${environment.title}`);
        } else {
          window.close();
        }
      });

    this.bcs.LabEndedObservable.subscribe((labId: string) => {
      window.close();
    });

    this.bcs.LabFinishedObservable.subscribe((labId: string) => {
      window.close();
    });
  }
}
