import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import IInventoryInterface from "../../models/Inventory/Interfaces/IInventoryInterface";
import ConnectionParameters from "../../models/Shared/Parameters/ConnectionParameters";
import { CanvasService } from "../../services/canvas.service";

@Component({
  selector: "app-select-interface",
  templateUrl: "./select-interface.component.html",
  styleUrls: ["./select-interface.component.scss"],
})
export class SelectInterfaceComponent implements OnInit {
  constructor(private cs: CanvasService, private ms: NgbActiveModal) {}

  deviceInterfaces: IInventoryInterface[];
  isFirstInterface: boolean;

  ngOnInit(): void {
    this.cs.connectionStarted$.first().subscribe((param: ConnectionParameters) => {
      this.isFirstInterface = true;

      // Filter out console interfaces
      this.deviceInterfaces = param.interfaces.filter((m) => this.connectionsFilter(m, param));
    });

    // The second device was selected filter the available interfaces
    this.cs.connectSecondInterface$.subscribe((param: ConnectionParameters) => {
      this.isFirstInterface = false;
      this.deviceInterfaces = param.interfaces.filter((m) => this.connectionsFilter(m, param));
    });
  }

  connectionsFilter(deviceInterface: IInventoryInterface, params: ConnectionParameters) {
    let connectionCompatibility: boolean = true;
    if (params.currentConnection) {
      connectionCompatibility = deviceInterface.type == params.currentConnection.ConnectorType;
    }

    return (
      connectionCompatibility &&
      !params.edges.some(
        (n) => n.Device1InterfaceId == deviceInterface.id || n.Device2InterfaceId == deviceInterface.id
      ) &&
      deviceInterface.type != "Console"
    );
  }

  interfaceClick(int: IInventoryInterface) {
    this.cs.selectConnectionInterface(int, this.isFirstInterface);
    this.ms.close();
  }
}
