import Wic1Bri from "../../Addons/Wic1Bri";
import Wic1Ethernet from "../../Addons/Wic1Ethernet";
import Wic1Serial from "../../Addons/Wic1Serial";
import Wic2Serial from "../../Addons/Wic2Serial";
import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter1750 extends BaseInventoryRouter {
  constructor() {
    super("1750", "1700", 16, 48);
    this.maxAddons = 2;
    this.defaultInterfaces.addEthernetInterfaces("FastEthernet", 1, null, this.maxAddons);
    this.supportedAddons.push(new Wic1Serial(), new Wic2Serial(), new Wic1Bri(), new Wic1Ethernet());
  }
}
