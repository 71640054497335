import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { createVerb } from "@ht2-labs/xapi-js-client/statementConstants/verbs";
import createActivity from "@ht2-labs/xapi-js-client/statementUtils/createActivity";
import createAgent, { CreateAgentOpts } from "@ht2-labs/xapi-js-client/statementUtils/createAgent";
import createTimestamp from "@ht2-labs/xapi-js-client/statementUtils/createTimestamp";
import { Statement } from "@ht2-labs/xapi-js-client/statementUtils/types";
import { environment } from "src/environments/environment";
import User from "../models/User";
import { AppService } from "../../core/services/app.service";

@Injectable({
  providedIn: "root",
})
export class LrsService {
  private CurrentUser: User;
  private uri = environment.apiUri + "/lrs";
  private agentOptions: CreateAgentOpts;

  constructor(private as: AppService, private http: HttpClient) {
    this.as.CurrentUser$.subscribe((user) => {
      this.CurrentUser = user;

      this.agentOptions = {
        displayName: this.CurrentUser.Email,
        email: this.CurrentUser.Email,
        idProviderUrl: "http://boson.com",
      };
    });
  }

  LogUserInteractionWithUI(activity: string, componentId: string) {
    let statement: Statement = {
      timestamp: createTimestamp(new Date()),
      actor: createAgent(this.agentOptions),
      verb: createVerb(`http://adlnet.gov/expapi/verbs/ui-interaction/${activity}`, `${activity}-ui`),
      object: createActivity({
        type: `https://boson.com/activitytype/ui-component`,
        url: `https://boson.com/exapi/activities/ui/${componentId}`,
        name: componentId,
        extensions: { "https://boson.com/platform": "https://boson.com/platform/NetSim/Online" },
      }),
    };

    return this.http.post(this.uri, statement);
  }
}
