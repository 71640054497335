import Geometry from "../../Math/Geometry";
import Point from "../../math/Point";
import { ITopologyNode } from "../Nodes/ITopologyNode";

export default class SnapResult {
  private geometry: Geometry = new Geometry();
  DistanceFromX: number;
  DistanceFromY: number;
  BestAxis: string;
  BestDistance: number;
  SnapLocation: Point;
  SnappableNode: ITopologyNode;

  constructor(draggingNode: ITopologyNode, otherNode: ITopologyNode) {
    this.SnappableNode = otherNode;
    let xSnapCompare: Point = { x: otherNode.position.x, y: draggingNode.position.y };
    let ySnapCompare: Point = { x: draggingNode.position.x, y: otherNode.position.y };
    this.DistanceFromX = Math.abs(this.geometry.DistanceBetweenPoints(otherNode.position, xSnapCompare));
    this.DistanceFromY = Math.abs(this.geometry.DistanceBetweenPoints(otherNode.position, ySnapCompare));

    if (this.DistanceFromX <= this.DistanceFromY) {
      this.BestAxis = "X";
      this.BestDistance = this.DistanceFromX;
      this.SnapLocation = { x: draggingNode.position.x, y: otherNode.position.y };
    } else {
      this.BestAxis = "Y";
      this.BestDistance = this.DistanceFromY;
      this.SnapLocation = { x: otherNode.position.x, y: draggingNode.position.y };
    }
  }
}
