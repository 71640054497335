import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter2514 extends BaseInventoryRouter {
  constructor() {
    super("2514", "2500", 16, 8);
    this.maxAddons = 0;
    this.defaultInterfaces.addEthernetInterfaces("Ethernet", 2, null, this.maxAddons);
    this.defaultInterfaces.addSerialInterfaces(2, null, this.maxAddons);
  }
}
