import IHasInterfaces from "../../Shared/interfaces/IHasInterfaces";
import IInventoryDevice from "../Devices/IInventoryDevice";
import InventoryEthernetInterface from "../Interfaces/InventoryEthernetInterface";
import IAddon from "./IAddon";

export default class EHwic1Gigabit implements IAddon {
  name: string = "EHWIC-1GE-SFP-CU";
  description: string = "1 Gigabit Ethernet";

  addToDevice(device: IHasInterfaces, rack: number, slot: number) {
    device.interfaces.push(new InventoryEthernetInterface("GigabitEthernet", rack, slot, 0));
  }
}
