export class UserPreferences {
  Id: string;
  consoleFontName: string;
  consoleFontFamily: string;
  consoleFontSize: number;
  consoleForeground: string;
  consoleBackground: string;
  documentTextFontName: string;
  documentTextFontFamily: string;
  documentTextFontSize: number;
  documentCodeFontName: string;
  documentCodeFontFamily: string;
  documentCodeFontSize: number;
  documentContainerSize: string;
  documentShowPreview: boolean;
  graderExtraCommandColor: string;
  graderMissingCommandColor: string;
  autoCollapseTree: boolean;
  displayGettingStarted: boolean;
  acceptedLicenseAgreement: boolean;
  theme: string;
  designerSnapEnabled: boolean;
  designerSnapSensitivity: number;
  designerShowLabels: boolean;
  designerShowNotes: boolean;
  externalConsole: string;
  designerSimplifiedInventory: boolean;
  remindMeAboutCaseSensitiveGradeResults: boolean;
}
