import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-forbidden",
  template: "<p>You are not authorized to access this content!</p>",
})
export class ForbiddenComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
