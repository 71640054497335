import Wic1Bri from "../../Addons/Wic1Bri";
import Wic1Ethernet from "../../Addons/Wic1Ethernet";
import Wic1Serial from "../../Addons/Wic1Serial";
import Wic2Serial from "../../Addons/Wic2Serial";
import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter1941 extends BaseInventoryRouter {
  constructor() {
    super("1941", "1900", 256, 512);
    this.maxAddons = 3;
    this.defaultInterfaces.addEthernetInterfaces("GigabitEthernet", 2, null, this.maxAddons);
    this.defaultInterfaces.addSerialInterfaces(1, null, this.maxAddons);
    this.supportedAddons.push(new Wic1Serial(), new Wic2Serial(), new Wic1Bri(), new Wic1Ethernet());
  }
}
