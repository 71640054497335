import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter2509 extends BaseInventoryRouter {
  constructor() {
    super("2509", "2500", 8, 16);
    this.maxAddons = 0;
    this.defaultInterfaces.addEthernetInterfaces("Ethernet", 1, null, this.maxAddons);
    this.defaultInterfaces.addSerialInterfaces(10, null, this.maxAddons);
  }
}
