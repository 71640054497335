import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter805 extends BaseInventoryRouter {
  constructor() {
    super("805", "800", 8, 12);
    this.maxAddons = 0;
    this.defaultInterfaces.addEthernetInterfaces("Ethernet", 1, null, this.maxAddons);
    this.defaultInterfaces.addSerialInterfaces(1, null, this.maxAddons);
  }
}
