import NM1Ethernet from "../../Addons/NM1Ethernet";
import NM4ASSerial from "../../Addons/NM4ASSerial";
import NM4Bri from "../../Addons/NM4Bri";
import NM4Ethernet from "../../Addons/NM4Ethernet";
import NM8ASSerial from "../../Addons/NM8ASSerial";
import NM8Bri from "../../Addons/NM8Bri";
import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter2611 extends BaseInventoryRouter {
  constructor() {
    super("2611", "2600", 16, 32);
    this.maxAddons = 4;
    this.defaultInterfaces.addEthernetInterfaces("Ethernet", 1, null, this.maxAddons);
    this.supportedAddons.push(
      new NM4ASSerial(),
      new NM4Bri(),
      new NM8ASSerial(),
      new NM8Bri(),
      new NM4Ethernet(),
      new NM1Ethernet()
    );
  }
}
