import { Guid } from "guid-typescript";
import Connection from "src/app/engine/models/Connection";
import IInventoryInterface from "./IInventoryInterface";
import { CreateInterfaceName } from "./InterfaceModule";

export default class InventoryEthernetInterface implements IInventoryInterface {
  id: string;
  baseName: string;
  name: string;
  type: string;
  rack: number;
  slot: number;
  port: number;

  constructor(subtype: string, rack: number, slot: number, port: number) {
    this.id = Guid.create().toString();
    this.type = "Ethernet";
    this.baseName = subtype;
    this.name = CreateInterfaceName(subtype, rack, slot, port);
    this.rack = rack;
    this.slot = slot;
    this.port = port;
  }

  addToSerializedTopology(doc: XMLDocument, parent?: HTMLElement) {
    let node = doc.createElement("Interface");
    let nameNode = doc.createElement("Name");
    let idNode = doc.createElement("ID");

    nameNode.innerHTML = this.name;
    idNode.innerHTML = this.id;

    node.appendChild(idNode);
    node.appendChild(nameNode);

    if (parent) {
      parent.querySelectorAll(`${this.baseName}, ${this.baseName}Interfaces`).forEach((elem: HTMLElement) => {
        elem.appendChild(node);
      });
    }

    return node;
  }

  createConnection(): Connection {
    let newConnection = new Connection();
    newConnection.ConnectorType = this.type;
    return newConnection;
  }
}
