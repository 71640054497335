import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import ExtendedFeedback from "../../models/ExtendedFeedback";
import { CustomerFeedbackService } from "../../services/customer-feedback.service";

@Component({
  selector: "app-extended-feedback",
  templateUrl: "./extended-feedback.component.html",
  styleUrls: ["./extended-feedback.component.scss"],
})
export class ExtendedFeedbackComponent implements OnInit {
  @Input() Score: number;
  @Input() DoNotContactMe: boolean;
  @Input() Comment: string;
  @Output() FeedbackSumbitted: EventEmitter<ExtendedFeedback> = new EventEmitter<ExtendedFeedback>();
  @Output() DoNotContactMeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() CommentChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild("commentBox") CommentBox: ElementRef;

  feedbackModel: ExtendedFeedback = {
    IssueWithCommands: false,
    IssueWithLabs: false,
    IssueWithPerformance: false,
    MissingFeatures: false,
    DifficultToUse: false,
    OtherIssue: false,
  };

  public hasError: boolean;

  constructor(private cf: CustomerFeedbackService) {}

  ngOnInit(): void {}

  submitClick() {
    if (0 < this.Comment.length || 5 === this.Score) {
      this.FeedbackSumbitted.next(this.processFeedback());
    } else {
      this.hasError = true;
      this.CommentBox.nativeElement.focus();
    }
  }

  doNotContactMeChanged() {
    this.DoNotContactMe = !this.DoNotContactMe;
    this.DoNotContactMeChange.emit(this.DoNotContactMe);
  }

  commentChanged() {
    if (0 < this.Comment.length) {
      this.hasError = false;
    }
    this.CommentChange.emit(this.Comment);
  }

  // If every field is false then the model should be null
  private processFeedback(): ExtendedFeedback {
    for (var i in this.feedbackModel) {
      if (this.feedbackModel[i] === true) {
        return this.feedbackModel;
      }
    }

    return null;
  }
}
