import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TabsComponent } from "src/app/shared/components/tabs.component";
import { TabComponent } from "src/app/shared/components/tab.component";
import { SafePipe } from "./pipes/safe.pipe";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { DynamicTabsDirective } from "./directives/dynamic-tabs.directive";
import { DynamicTabDirective } from "./directives/dynamic-tab.directive";
import { ProgressComponent } from "./components/progress.component";
import { MainMenuComponent } from "./components/main-menu/main-menu.component";
import { FeedbackComponent } from "./components/feedback/feedback.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { ExtendedFeedbackComponent } from "./components/extended-feedback/extended-feedback.component";
import { FormsModule } from "@angular/forms";
import { ResumeProgressComponent } from "./components/resume-progress/resume-progress.component";
import { ConsoleComponent } from "../features/console/components/console/console.component";
import { ShortcutsComponent } from "./modals/shortcuts/shortcuts.component";
import { SplitPipe } from "./pipes/split.pipe";
import { FileDragDropDirective } from "./directives/file-drag-drop.directive";
import { ConfirmationDialogComponent } from "./modals/confirmation-dialog/confirmation-dialog.component";
import { NoAccessComponent } from "./pages/no-access/no-access.component";
import { SvgGraphComponent } from "../features/network-designer/components/svg-graph/svg-graph.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { DeviceNodeComponent } from "../features/network-designer/components/nodes/device-node/device-node.component";
import { NoteNodeComponent } from "../features/network-designer/components/nodes/note-node/note-node.component";
import { LabelNodeComponent } from "../features/network-designer/components/nodes/label-node/label-node.component";
import { ResizedDirective } from "./directives/resized.directive";
import { TextEditorComponent } from "./components/text-editor/text-editor.component";
import { MomentDatePipe } from "./pipes/moment-format.pipe";
import { CaseSensitiveResultsComponent } from './modals/case-sensitive-results/case-sensitive-results.component';

@NgModule({
  declarations: [
    DynamicTabsDirective,
    DynamicTabDirective,
    TabsComponent,
    TabComponent,
    SafePipe,
    TruncatePipe,
    SplitPipe,
    ProgressComponent,
    MainMenuComponent,
    FeedbackComponent,
    ExtendedFeedbackComponent,
    ResumeProgressComponent,
    ConsoleComponent,
    ShortcutsComponent,
    FileDragDropDirective,
    TextEditorComponent,
    ConfirmationDialogComponent,
    NoAccessComponent,
    SvgGraphComponent,
    DeviceNodeComponent,
    NoteNodeComponent,
    LabelNodeComponent,
    ResizedDirective,
    MomentDatePipe,
    CaseSensitiveResultsComponent,
  ],
  imports: [CommonModule, RouterModule, NgbModule, FormsModule, NgxChartsModule],
  exports: [
    DynamicTabsDirective,
    DynamicTabDirective,
    TabsComponent,
    TabComponent,
    SafePipe,
    TruncatePipe,
    SplitPipe,
    ProgressComponent,
    MainMenuComponent,
    ResumeProgressComponent,
    ConsoleComponent,
    FileDragDropDirective,
    TextEditorComponent,
    FileDragDropDirective,
    NoAccessComponent,
    SvgGraphComponent,
    DeviceNodeComponent,
    LabelNodeComponent,
    NoteNodeComponent,
    ResizedDirective,
    MomentDatePipe,
    CaseSensitiveResultsComponent
  ],
})
export class SharedModule {}
