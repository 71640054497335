import ExtendedFeedback from "./ExtendedFeedback";

export default class CustomerFeedback {
  Id: string;
  UserId: string;
  Score: number;
  Response: ExtendedFeedback;
  CreatedAt: Date;
  UpdatedAt: Date;
  IPAddress: string;
  GeoLocation: any;
  UserAgentString: string;
  NetSimVersion: string;
  ScreenResolution: number[];
  Comment: string;
  DoNotContactMe: boolean;
  DisplayMethod: string;
  DisplayMethodMeta: string[];
}
