import Hwic2Bri from "../../Addons/Hwic2Bri";
import Hwic2FastEthernet from "../../Addons/Hwic2FastEthernet";
import Hwic2Serial from "../../Addons/Hwic2Serial";
import Hwic4Serial from "../../Addons/Hwic4Serial";
import Hwic8Serial from "../../Addons/Hwic8Serial";
import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter2911 extends BaseInventoryRouter {
  constructor() {
    super("2911", "2900", 256, 512);
    this.maxAddons = 4;
    this.defaultInterfaces.addEthernetInterfaces("GigabitEthernet", 3, null, this.maxAddons);
    this.supportedAddons.push(
      new Hwic2Serial(),
      new Hwic4Serial(),
      new Hwic8Serial(),
      new Hwic2Bri(),
      new Hwic2FastEthernet()
    );
  }
}
