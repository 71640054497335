import { Injectable, Output } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppSetting } from "../models/AppSetting";
import { environment } from "../../../environments/environment";
import { Observable, of } from "rxjs";
import { mergeMap, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AppSettingsService {
  //Fields
  uri = environment.apiUri + "/app-settings";

  //Constructor
  constructor(private http: HttpClient) {}

  getAppSettingByKey(key: string) {
    return this.http.get(`${this.uri}/?key=${key}`).pipe(
      switchMap((settings: any) => {
        return of(settings[0]);
      })
    );
  }

  updateAppSetting(Id: string, Value: string) {
    const obj = {
      Value,
    };
    return this.http.patch(`${this.uri}/${Id}`, obj);
  }
}
