import { Xmb } from "@angular/compiler";
import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import KeyPair from "src/app/shared/models/KeyPair";
import Point from "src/app/shared/models/math/Point";
import BaseXmlSerialization from "src/app/shared/models/TopologyCanvas/BaseXmlSerialization";
import ConnectionEdge from "src/app/shared/models/TopologyCanvas/Edges/ConnectionEdge";
import SerialConnectionEdge from "src/app/shared/models/TopologyCanvas/Edges/SerialConnectionEdge";
import ITopologyEdge from "src/app/shared/models/TopologyCanvas/Edges/TopologyEdge";
import { NetMapLayout } from "src/app/shared/models/TopologyCanvas/NetMapLayout";
import DeviceNode from "src/app/shared/models/TopologyCanvas/Nodes/DeviceNode";
import ITextNode from "src/app/shared/models/TopologyCanvas/Nodes/ITextNode";
import { ITopologyNode } from "src/app/shared/models/TopologyCanvas/Nodes/ITopologyNode";
import IInventoryInterface from "../models/Inventory/Interfaces/IInventoryInterface";
import InventoryBriInterface from "../models/Inventory/Interfaces/InventoryBriInterface";
import InventoryEthernetInterface from "../models/Inventory/Interfaces/InventoryEthernetInterface";
import InventorySerialInterface from "../models/Inventory/Interfaces/InventorySerialInterface";
import ITopologyUI from "../models/Shared/interfaces/ITopologyUI";
import SaveTopologyParams from "../models/Shared/Parameters/SaveTopologyParams";
import { InventoryTreeService } from "./inventory-tree.service";
import { parseString } from "xml2js";
import SelectionNode from "src/app/shared/models/TopologyCanvas/Nodes/SelectionNode";
import { CanvasService } from "./canvas.service";
import { ContextMenuService } from "./context-menu.service";
import NoteNode from "src/app/shared/models/TopologyCanvas/Nodes/NoteNode";
import LabelNode from "src/app/shared/models/TopologyCanvas/Nodes/LabelNode";

@Injectable({
  providedIn: "root",
})
export class TopologySerializationService extends BaseXmlSerialization {
  constructor(private its: InventoryTreeService, private cs: CanvasService, private cms: ContextMenuService) {
    super();
  }

  serializeTopology(nodes: ITopologyNode[], edges: ITopologyEdge[], params: SaveTopologyParams): string {
    //Initialize Document
    let doc = document.implementation.createDocument("", "", null);
    let topologyTag = doc.createElement("Topology");
    topologyTag.setAttribute("xmlns:xsi", "http://www.w3.org/2001/XMLSchema-instance");
    topologyTag.setAttribute("xmlns:xsd", "http://www.w3.org/2001/XMLSchema");
    doc.appendChild(topologyTag);

    //Add Meta
    this.addKeyValue(doc, topologyTag, "ID", Guid.create().toString());
    this.addKeyValue(doc, topologyTag, "Created", new Date().toISOString());

    //Add Default Collections
    let Routers = this.addBlankTag(doc, topologyTag, "Routers");
    let Switches = this.addBlankTag(doc, topologyTag, "Switches");
    let PCs = this.addBlankTag(doc, topologyTag, "PCs");
    let Phones = this.addBlankTag(doc, topologyTag, "Phones");
    let FrameRelaySwitches = this.addBlankTag(doc, topologyTag, "FrameRelaySwitches");
    let Connections = this.addBlankTag(doc, topologyTag, "Connections");
    let SerialConnections = this.addBlankTag(doc, topologyTag, "SerialConnections");
    let BriConnections = this.addBlankTag(doc, topologyTag, "BriConnections");
    let DefaultMarkup = this.addBlankTag(doc, topologyTag, "DefaultMarkup");

    //Initialize Default Markup
    let DevicesMarkup = this.addBlankTag(doc, DefaultMarkup, "Devices");
    let NotesMarkup = this.addBlankTag(doc, DefaultMarkup, "Notes");
    let LabelsMarkup = this.addBlankTag(doc, DefaultMarkup, "Labels");
    let NotesLookupMarkup = this.addBlankTag(doc, DefaultMarkup, "NoteLookup");

    //Serialize Topology data
    this.serializeDevices(nodes, doc, topologyTag, DevicesMarkup);
    this.serializeConnections(edges, doc, topologyTag);
    this.serializeMarkup(nodes, doc, DefaultMarkup, params);

    return `<?xml version="1.0" encoding="utf-8"?>` + new XMLSerializer().serializeToString(doc.documentElement);
  }

  serializeDevices(nodes: ITopologyNode[], doc: XMLDocument, topology: HTMLElement, markup: HTMLElement) {
    let devices: DeviceNode[] = <DeviceNode[]>nodes.filter((m) => m.elementType == "device");

    //Serialize the device
    devices.forEach((device: DeviceNode) => {
      //Insert into topology
      if (!device.configuration.inventoryDevice) {
        console.error(`The device ${device.label} is an unsupported device`);
      }

      if (!device.configuration.inventoryDevice) {
        console.log("FOUND ERROR", device);
      }
      let deviceElement = device.configuration.inventoryDevice.addToSerializedTopology(doc, topology);
      this.addKeyValue(doc, deviceElement, "ID", device.id);
      this.addKeyValue(doc, deviceElement, "Name", device.label);

      //Insert Device interfaces
      device.interfaces.forEach((value) => {
        value.addToSerializedTopology(doc, deviceElement);
      });

      //Add markup for the device
      this.addDeviceCoordinate(doc, markup, device);

      // add device optional parameters
      device.configuration.options.forEach((pair) => {
        this.addKeyValue(doc, deviceElement, pair.Key, pair.Value.toString());
      });
    });
  }

  serializeConnections(edges: ITopologyEdge[], doc: XMLDocument, topology: HTMLElement) {
    edges.forEach((connection) => {
      connection.addToSerializedTopology(doc, topology);
    });
  }

  serializeMarkup(nodes: ITopologyNode[], doc: XMLDocument, markup: HTMLElement, params: SaveTopologyParams) {
    let markupNodes = <ITextNode[]>nodes.filter((m) => m.elementType == "markup");

    markupNodes.forEach((node) => {
      node.addToSerializedTopology(doc, markup);
    });

    this.addKeyValue(doc, markup, "Title", params.Title);
    this.addKeyValue(doc, markup, "Zoom", params.Zoom.toString());
    let centerNode = this.addBlankTag(doc, markup, "Center");
    this.addCoordinateToTag(doc, centerNode, { x: params.Center.x + 5000, y: params.Center.y + 5000 });
    this.addKeyValue(doc, markup, "ScrollOffsetX", "0");
    this.addKeyValue(doc, markup, "ScrollOffsetY", "0");
  }

  deserializeTopology(ui: ITopologyUI, xml: string) {
    // convert xml to JSON
    ui.topology = this.parseXmlSync(xml);

    // Make shortcut of console positions relative to thier guid
    ui.topology.Topology.DefaultMarkup[0].Devices[0].DeviceCoordinate.forEach((c) => {
      ui.positionMap.set(c.ID[0], { x: c.Location[0].X[0] - 5000, y: c.Location[0].Y[0] - 5000 });
    });

    // Get all device nodes
    ui.nodes = [].concat(
      this.GetNodesFromDevices(ui.topology.Topology.Routers[0].Router, "Router", ui.positionMap, ui.layout),
      this.GetNodesFromDevices(ui.topology.Topology.Switches[0].Switch, "Switch", ui.positionMap, ui.layout),
      this.GetNodesFromDevices(ui.topology.Topology.PCs[0].PCIO, "Host", ui.positionMap, ui.layout),
      this.GetNodesFromDevices(ui.topology.Topology.Phones[0].PhoneIO, "Phone", ui.positionMap, ui.layout),
      //Wrapping a prop that should be an array of length = 1
      this.GetNodesFromDevices(
        ui.topology.Topology.FrameRelaySwitches[0].FrameRelaySwitch,
        "Frame Relay",
        ui.positionMap,
        ui.layout
      )
    );

    // Map out interfaces to a device
    ui.nodes.forEach((device: DeviceNode) => {
      device.initializeContextMenu(this.cms);

      device.interfaces.forEach((devInterface: IInventoryInterface) => {
        ui.interfaceMap.set(devInterface.id, devInterface);
      });
    });

    // Add Markup
    ui.nodes = ui.nodes.concat(
      this.GetNoteNodes(ui.topology.Topology.DefaultMarkup[0]),
      this.GetLabelNodes(ui.topology.Topology.DefaultMarkup[0])
    );

    //Update auto naming feature
    this.cs.syncDeviceName(ui.nodes);

    // Convert connections to edges/lines on a graph
    ui.edges = [].concat(
      this.GetEdgesFromConnections(
        ui.nodes,
        ui.topology.Topology.Connections[0].Connection,
        "Connection",
        ui.interfaceMap
      ),
      this.GetEdgesFromConnections(
        ui.nodes,
        ui.topology.Topology.SerialConnections[0].SerialConnection,
        "Serial",
        ui.interfaceMap
      ),
      this.GetEdgesFromConnections(
        ui.nodes,
        ui.topology.Topology.BriConnections[0].BriConnection,
        "Bri",
        ui.interfaceMap
      )
    );

    // Initialize the context menu for connections
    ui.edges.forEach((value) => {
      (<ConnectionEdge>value).initializeContextMenu(this.cms);
    });

    // //Add UI Helpers to this graph
    ui.selectionSquare = new SelectionNode("SelectionSquare", 0, 0);
    ui.nodes.push(ui.selectionSquare);

    //The edges have been loaded notify the UI
    this.cs.connectionEdgesLoaded(<ConnectionEdge[]>ui.edges);

    ui.isLabLoaded = true;
  }

  deserializeAndPan(ui: ITopologyUI, xml: string) {
    this.deserializeTopology(ui, xml);
    this.defaultPanAndZoom(ui, xml);
  }

  defaultPanAndZoom(ui: ITopologyUI, xml: string) {
    //Set location and zoom
    ui.zoomTo(parseFloat(ui.topology.Topology.DefaultMarkup[0].Zoom[0]));
    ui.panTo(
      parseFloat(ui.topology.Topology.DefaultMarkup[0].Center[0].X[0]) - 5000,
      parseFloat(ui.topology.Topology.DefaultMarkup[0].Center[0].Y[0]) - 5000
    );
  }

  private parseXmlSync(xml) {
    var error = null;
    var json = null;
    parseString(xml, function (innerError, innerJson) {
      error = innerError;
      json = innerJson;
    });

    if (error) {
      throw error;
    }
    return json;
  }

  GetNoteNodes(markup): NoteNode[] {
    let node: NoteNode;
    let noteLookup: any;
    let results = [];
    let location: Point;

    if (!markup.Notes[0].NoteIO) {
      return [];
    }

    markup.Notes[0].NoteIO.forEach((element) => {
      node = new NoteNode();
      node.id = element.ID[0];
      node.text = element.Text[0];
      node.label = "";
      noteLookup = markup.NoteLookup[0].DeviceCoordinate.find((m) => m.ID[0] == node.id);

      //Get Location
      if (!noteLookup) {
        return;
      } else {
        location = { x: noteLookup.Location[0].X[0] - 5000, y: noteLookup.Location[0].Y[0] - 5000 };
      }

      // Set Markup Data
      node.position = location;
      node.defaultPosition = location;
      node.dimension = { width: 70, height: 70 };
      node.elementType = "markup";
      node.type = "Note";
      node.initializeContextMenu(this.cms);
      node.multiLine = true;
      results.push(node);
    });

    return results;
  }

  GetLabelNodes(markup) {
    let node: NoteNode;
    let results = [];
    let location: Point;

    if (!markup.Labels || !markup.Labels[0] || !markup.Labels[0].LabelData) {
      return [];
    }

    markup.Labels[0].LabelData.forEach((element) => {
      node = new LabelNode();
      node.id = element.ID[0];
      node.text = element.Text[0];
      node.label = "";
      location = { x: element.Location[0].X[0] - 5000, y: element.Location[0].Y[0] - 5000 };
      node.position = location;
      node.defaultPosition = location;
      node.dimension = { width: 70, height: 70 };
      node.elementType = "markup";
      node.type = "Label";
      node.multiLine = false;
      node.initializeContextMenu(this.cms);
      results.push(node);
    });

    return results;
  }

  //Make Convert Devices to Nodes
  GetNodesFromDevices(
    devices: any[],
    deviceType: string,
    positionMap: Map<any, any>,
    layout: NetMapLayout
  ): DeviceNode[] {
    let nodes: DeviceNode[] = [];
    let currentNode: DeviceNode = new DeviceNode();
    if (!devices) return nodes;

    devices.forEach((value) => {
      currentNode = new DeviceNode();
      currentNode.id = value.ID[0];
      currentNode.label = value.Name[0];
      currentNode.defaultPosition = positionMap.get(value.ID[0]);
      currentNode.position = positionMap.get(value.ID[0]);
      currentNode.dimension = { height: layout.nodeHeight, width: layout.nodeWidth };
      currentNode.type = deviceType;
      currentNode.elementType = "device";
      currentNode.configuration = null;
      currentNode.model = value.Model ? value.Model[0] : undefined;
      currentNode.series = value.Series ? value.Series[0] : undefined;
      currentNode.flashMemory = value.FlashMemory ? parseInt(value.FlashMemory[0]) : undefined;
      currentNode.systemMemory = value.SystemMemory ? parseInt(value.SystemMemory[0]) : undefined;
      currentNode.interfaces = this.getInterfacesFromDevice(value);
      currentNode.configuration = {
        id: currentNode.id,
        name: currentNode.label,
        inventoryDevice: this.its.allDevices.find(
          (m) => m.model == currentNode.model && m.series == currentNode.series
        ),
        addonKeys: [],
        options: this.unwrapOptions(value),
        location: currentNode.defaultPosition,
      };

      nodes.push(currentNode);
    });

    return nodes;
  }

  //unwrap options
  unwrapOptions(obj): KeyPair[] {
    let options = [];
    let excludedProperties = [
      "Bri",
      "Ethernet",
      "FastEthernet",
      "GigabitEthernet",
      "Serial",
      "Token",
      "ID",
      "FlashMemory",
      "SystemMemory",
      "Name",
      "Series",
      "Model",
      "EthernetInterfaces",
    ];

    Object.keys(obj).forEach((property) => {
      if (excludedProperties.some((m) => m == property)) return;

      if (
        Array.isArray(obj[property]) &&
        obj[property].length === 1 &&
        (typeof obj[property][0] === "string" || obj[property][0] instanceof String)
      ) {
        options.push(new KeyPair(property, obj[property][0]));
      } else {
        options.push(new KeyPair(property, obj[property]));
      }
    });
    options.forEach((val) => {
      try {
        val.Value = JSON.parse(val.Value);
      } catch (error) {
        //console.log("caught error", val.Key, val.Value, error);
        return;
      }
    });
    return options;
  }

  // Convert connections from the topology to graph edges (lines)
  GetEdgesFromConnections(
    nodes: ITopologyNode[],
    connections: any[],
    connectionType: string,
    interfaceMap: Map<any, any>
  ): ConnectionEdge[] {
    let connectionEdges: ConnectionEdge[] = [];
    let node: ITopologyNode;
    let edge: ConnectionEdge;

    if (!connections) return connectionEdges;

    connections.forEach((value) => {
      //Set concrete class
      if (connectionType == "Serial") {
        let serialEdge = new SerialConnectionEdge();
        serialEdge.dceEnd = value.DceEnd[0];
        serialEdge.dteEnd = value.DteEnd[0];
        edge = serialEdge;
      } else {
        edge = new ConnectionEdge();
      }

      // edge.id = Guid.create().toString();
      edge.uid = Guid.create().toString();
      edge.groupUid = null;
      edge.source = value.DeviceOne[0];
      edge.target = value.DeviceTwo[0];
      edge.Device1Id = edge.source;
      edge.Device2Id = edge.target;
      edge.type = connectionType;
      edge.Device1InterfaceId = value.DeviceInterfaceOne[0];
      edge.Device2InterfaceId = value.DeviceInterfaceTwo[0];
      edge.Device1InterfaceName = interfaceMap.get(value.DeviceInterfaceOne[0])
        ? interfaceMap.get(value.DeviceInterfaceOne[0]).Name
        : undefined;
      edge.Device2InterfaceName = interfaceMap.get(value.DeviceInterfaceTwo[0])
        ? interfaceMap.get(value.DeviceInterfaceTwo[0]).Name
        : undefined;

      // Get Device Names
      node = null;
      node = nodes.find((m) => m.id == edge.Device1Id);

      if (node) {
        edge.Device1Name = node.label;
      }

      node = null;
      node = nodes.find((m) => m.id == edge.Device2Id);

      if (node) {
        edge.Device2Name = node.label;
      }

      //Fill in serial specific settings
      if (connectionType === "Serial") {
      }

      // Connections are given a uid which are a blend of the device/interface guids
      // groupUID is a blend of the device guids specifing a series of links between two devices
      let uidArray = [edge.source, edge.target];
      uidArray.sort();
      edge.groupUid = uidArray.join("+");
      uidArray.concat([edge.Device1InterfaceId, edge.Device2InterfaceId]);
      edge.uid = uidArray.join("+");
      connectionEdges.push(edge);
    });

    return connectionEdges;
  }

  private getInterfacesFromDevice(device: any): any[] {
    let interfaces = [];

    if (!device) {
      return interfaces;
    }

    if (device.Bri && device.Bri[0] && device.Bri[0].Interface) {
      device.Bri[0].Interface.forEach((int) => {
        interfaces.push(this.unwrapInterfaceArrays(int));
      });
    }

    if (device.Ethernet && device.Ethernet[0] && device.Ethernet[0].Interface) {
      device.Ethernet[0].Interface.forEach((int) => {
        interfaces.push(this.unwrapInterfaceArrays(int));
      });
    }

    if (device.EthernetInterfaces && device.EthernetInterfaces[0] && device.EthernetInterfaces[0].Interface) {
      device.EthernetInterfaces[0].Interface.forEach((int) => {
        interfaces.push(this.unwrapInterfaceArrays(int));
      });
    }

    if (device.FastEthernet && device.FastEthernet[0] && device.FastEthernet[0].Interface) {
      device.FastEthernet[0].Interface.forEach((int) => {
        interfaces.push(this.unwrapInterfaceArrays(int));
      });
    }

    if (device.GigabitEthernet && device.GigabitEthernet[0] && device.GigabitEthernet[0].Interface) {
      device.GigabitEthernet[0].Interface.forEach((int) => {
        interfaces.push(this.unwrapInterfaceArrays(int));
      });
    }

    if (device.Serial && device.Serial[0] && device.Serial[0].Interface) {
      device.Serial[0].Interface.forEach((int) => {
        interfaces.push(this.unwrapInterfaceArrays(int));
      });
    }

    return interfaces;
  }

  private unwrapInterfaceArrays(obj: any): any {
    let deviceInterface: any = {};

    Object.keys(obj).forEach((property) => {
      if (
        Array.isArray(obj[property]) &&
        obj[property].length === 1 &&
        (typeof obj[property][0] === "string" || obj[property][0] instanceof String)
      ) {
        deviceInterface[property] = obj[property][0];
      } else {
        deviceInterface[property] = obj[property];
      }
    });

    return this.parseXMLIntoInterface(deviceInterface.ID, deviceInterface.Name);
  }

  private parseXMLIntoInterface(id: string, name: string): IInventoryInterface {
    let newInterface: IInventoryInterface;
    let regex = new RegExp(/^([a-zA-Z]+)(.+)$/g);
    let parts: any = regex.exec(name);
    let interfaceType = parts[1];
    parts = parts[2].split("/");
    let rack: number, slot: number, port: number;

    if (parts.length == 3) {
      rack = parseInt(parts[0]);
      slot = parseInt(parts[1]);
      port = parseInt(parts[2]);
    } else if (parts.length == 2) {
      slot = parseInt(parts[0]);
      port = parseInt(parts[1]);
    } else {
      port = parseInt(parts[0]);
    }

    if (name.toLowerCase().includes("ethernet")) {
      newInterface = new InventoryEthernetInterface(interfaceType, rack, slot, port);
    } else if (name.toLowerCase().startsWith("serial")) {
      newInterface = new InventorySerialInterface(rack, slot, port);
    } else if (name.toLowerCase().startsWith("bri")) {
      newInterface = new InventoryBriInterface(rack, slot, port);
    } else {
      return null;
    }

    newInterface.id = id;
    return newInterface;
  }
}
