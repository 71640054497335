<div class="modal-body">
  <h3>Keyboard Shortcuts <button class="close float-right" (click)="onClose()">&times;</button></h3>
  <h4>Everywhere</h4>
  <table>
    <thead>
      <tr>
        <th>Action</th>
        <th>Shortcut</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Navigate to Home</td>
        <td><kbd>Esc</kbd>+<kbd>H</kbd></td>
      </tr>
      <tr>
        <td>Navigate to File (Open)</td>
        <td><kbd>Esc</kbd>+<kbd>O</kbd></td>
      </tr>
      <tr>
        <td>Navigate to File (Save)</td>
        <td><kbd>Esc</kbd>+<kbd>S</kbd></td>
      </tr>
      <tr>
        <td>Navigate to Labs</td>
        <td><kbd>Esc</kbd>+<kbd>L</kbd></td>
      </tr>
      <tr>
        <td>Navigate to Designer</td>
        <td><kbd>Esc</kbd>+<kbd>D</kbd></td>
      </tr>
      <tr>
        <td>Navigate to Preferences</td>
        <td><kbd>Esc</kbd>+<kbd>P</kbd></td>
      </tr>
      <tr>
        <td>Navigate to Lab</td>
        <td><kbd>Esc</kbd>+<kbd>N</kbd></td>
      </tr>
    </tbody>
  </table>
  <h4>Lab</h4>
  <table>
    <thead>
      <tr>
        <th>Action</th>
        <th>Shortcut</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Open Lab Document</td>
        <td><kbd>Esc</kbd>+<kbd>U</kbd></td>
      </tr>
      <tr>
        <td>Open Topology</td>
        <td><kbd>Esc</kbd>+<kbd>I</kbd></td>
      </tr>
      <tr>
        <td>Open Tab with Search</td>
        <td><kbd>Esc</kbd>+<kbd>K</kbd></td>
      </tr>
      <tr>
        <td>Cycle Recently Opened Tabs</td>
        <td><kbd>Esc</kbd>+<kbd>J</kbd></td>
      </tr>
      <tr>
        <td>Close Console</td>
        <td><kbd>Esc</kbd>+<kbd>X</kbd></td>
      </tr>
      <tr>
        <td>Cycle Open Tabs (Left)</td>
        <td><kbd>Esc</kbd>+<kbd>&lt;</kbd></td>
      </tr>
      <tr>
        <td>Cycle Open Tabs (Right)</td>
        <td><kbd>Esc</kbd>+<kbd>&gt;</kbd></td>
      </tr>
      <tr>
        <td>Grade Lab</td>
        <td><kbd>Esc</kbd>+<kbd>G</kbd></td>
      </tr>
      <tr>
        <td>End Lab</td>
        <td><kbd>Esc</kbd>+<kbd>E</kbd></td>
      </tr>
    </tbody>
  </table>
  <h4>Console</h4>
  <table>
    <thead>
      <tr>
        <th>Action</th>
        <th>Shortcut</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Move cursor to beginning of the line</td>
        <td><kbd>Ctrl</kbd>+<kbd>A</kbd></td>
      </tr>
      <tr>
        <td>Move cursor back one character</td>
        <td><kbd>Ctrl</kbd>+<kbd>B</kbd></td>
      </tr>
      <tr>
        <td>Abort current command and exit configuration</td>
        <td><kbd>Ctrl</kbd>+<kbd>C</kbd></td>
      </tr>
      <tr>
        <td>Erase a single character to the right of the cursor</td>
        <td><kbd>Ctrl</kbd>+<kbd>D</kbd></td>
      </tr>
      <tr>
        <td>Move the cursor to the end of the line</td>
        <td><kbd>Ctrl</kbd>+<kbd>E</kbd></td>
      </tr>
      <tr>
        <td>Move cursor forward one character</td>
        <td><kbd>Ctrl</kbd>+<kbd>F</kbd></td>
      </tr>
      <tr>
        <td>Display the next command in history</td>
        <td><kbd>&darr;</kbd></td>
      </tr>
      <tr>
        <td>Display the previous command in history</td>
        <td><kbd>&uarr;</kbd></td>
      </tr>
      <tr>
        <td>Interrupt a Cisco IOS process</td>
        <td><kbd>Ctrl</kbd>+<kbd>Shift</kbd>+<kbd>6</kbd></td>
      </tr>
      <tr>
        <td>Display the current command line</td>
        <td><kbd>Ctrl</kbd>+<kbd>R</kbd></td>
      </tr>
      <tr>
        <td>Erase a line</td>
        <td><kbd>Ctrl</kbd>+<kbd>U</kbd></td>
      </tr>
      <tr>
        <td>Pages forward one line<small>(When text is paginated)</small></td>
        <td><kbd>Enter</kbd></td>
      </tr>
      <tr>
        <td>Pages forward one page<small>(When text is paginated)</small></td>
        <td><kbd>Spacebar</kbd></td>
      </tr>
      <tr>
        <td>Completes a partially entered command if unambiguous</td>
        <td><kbd>Tab</kbd></td>
      </tr>
    </tbody>
  </table>
</div>
