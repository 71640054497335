import Wic1Bri from "../../Addons/Wic1Bri";
import Wic1Serial from "../../Addons/Wic1Serial";
import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter1604 extends BaseInventoryRouter {
  constructor() {
    super("1604", "1600", 8, 16);
    this.maxAddons = 1;
    this.defaultInterfaces.addEthernetInterfaces("Ethernet", 1, null, this.maxAddons);
    this.defaultInterfaces.addBriInterfaces(1, null, null);
    this.supportedAddons.push(new Wic1Bri(), new Wic1Serial());
  }
}
