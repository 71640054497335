import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { combineLatest, of, Subject } from "rxjs";
import { AppService } from "src/app/core/services/app.service";
import { ProgressItem } from "src/app/features/lab/models/ProgressItem";
import { SessionsService } from "./sessions.service";
import { EngineService } from "src/app/engine/services/engine.service";
import { LabsService } from "./labs.service";
import Container from "../models/Container";
import { ContainersService } from "./containers.service";
import { Session } from "protractor";
import LabFileCollection from "../models/LabFileCollection";
import { UsersService } from "./users.service";
import { LabTreeService } from "src/app/features/lab/services/lab-tree.service";
import { Router } from "@angular/router";
import { UserFilesService } from "src/app/features/user-files/services/user-files.service";
import { UserLabFileMeta } from "src/app/features/user-files/models/UserLabFileMeta";
import { switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  private ContainerAvailableSource = new Subject<Container>();
  ContainerAvailableObservable = this.ContainerAvailableSource.asObservable();

  private LoadInProgressSource = new Subject<boolean>();
  LoadInProgressObservable = this.LoadInProgressSource.asObservable();

  CurrentContainer: Container;
  CurrentSession: Session;
  Files: LabFileCollection;
  Tasks: ProgressItem[] = [];
  IsError: boolean;
  IsLoading: boolean = false;

  constructor(
    private as: AppService,
    private es: EngineService,
    private ls: LabsService,
    private ufs: UserFilesService,
    private cs: ContainersService,
    private ss: SessionsService,
    private us: UsersService,
    private router: Router
  ) {}

  loadNetSimXItem(labId: string) {
    this.LoadInProgressSource.next(true);
    this.IsLoading = true;
    this.Tasks = [];

    try {
      console.log(`clearing the console cache...`);
      const totalDevices = parseInt(window.localStorage.getItem("totalDevices"));
      for (let i = 1; i <= totalDevices; i++) {
        console.log(`removing cached console output for 'console_${i}'`);
        window.localStorage.removeItem(`console_${i}`);
      }
    } catch (error) {
      // log error
    }

    this.Tasks.push(
      new ProgressItem("Retrieving available container", "in_progress", null, this.ContainerAvailableObservable)
    );
    this.Tasks.push(
      new ProgressItem("Loading topology", "todo", this.ContainerAvailableObservable, this.es.LoadTopologyObservable)
    );
    this.Tasks.push(
      new ProgressItem(
        "Loading network configurations",
        "todo",
        this.es.LoadConfigurationObservable,
        this.es.LoadConfigurationCompleteObservable
      )
    );
    this.Tasks.push(
      new ProgressItem(
        "Retrieving devices and connections",
        "todo",
        this.es.LoadConfigurationCompleteObservable,
        combineLatest([this.es.GetConnectionsObservable, this.es.GetDevicesObervable])
      )
    );

    this.ContainerAvailableObservable.take(1).subscribe((container: Container) => {
      this.es.loadLab(labId, container);
    });

    this.reserveContainer();
  }

  loadLabFile(fileId: string, fileName: string) {
    this.LoadInProgressSource.next(true);
    this.IsLoading = true;
    this.Tasks = [];

    this.Tasks.push(
      new ProgressItem("Retrieving available container", "in_progress", null, this.ContainerAvailableObservable)
    );
    this.Tasks.push(
      new ProgressItem(
        `Loading "${fileName}" file`,
        "todo",
        this.ContainerAvailableObservable,
        this.es.LoadBsnCompleteObservable
      )
    );
    // Should retrieve lab document here.
    this.Tasks.push(
      new ProgressItem(
        "Retrieving devices and connections",
        "todo",
        this.es.LoadBsnCompleteObservable,
        combineLatest([this.es.GetConnectionsObservable, this.es.GetDevicesObervable])
      )
    );

    this.ufs
      .getUserFileLabMeta(fileId)
      .pipe(
        switchMap((meta: UserLabFileMeta) => {
          return this.us.checkLabAccess(meta.LabId);
        }),
        switchMap((hasAccess: boolean) => {
          if (hasAccess) {
            return combineLatest([of(this.reserveContainer()), this.ContainerAvailableObservable.take(1)]);
          } else {
            throw new Error("No Access");
          }
        })
      )
      .subscribe(
        ([reserve, container]: [void, Container]) => {
          this.es.loadLabFile(fileId, container);
        },
        (error) => {
          if (error && error.message && error.message == "No Access") {
            this.router.navigate(["no-access"]);
          }
        }
      );
  }

  loadBsnFile(fileId: string, fileName: string) {
    this.LoadInProgressSource.next(true);
    this.IsLoading = true;
    this.Tasks = [];

    this.Tasks.push(
      new ProgressItem("Retrieving available container", "in_progress", null, this.ContainerAvailableObservable)
    );
    this.Tasks.push(
      new ProgressItem(
        `Loading "${fileName}" file`,
        "todo",
        this.ContainerAvailableObservable,
        this.es.LoadBsnCompleteObservable
      )
    );
    this.Tasks.push(
      new ProgressItem(
        "Retrieving devices and connections",
        "todo",
        this.es.LoadBsnCompleteObservable,
        combineLatest([this.es.GetConnectionsObservable, this.es.GetDevicesObervable])
      )
    );

    this.ContainerAvailableObservable.take(1).subscribe((container: Container) => {
      this.es.loadBsnFile(fileId, container);
    });

    this.reserveContainer();
  }

  runDesigner(fileId: string) {
    this.LoadInProgressSource.next(true);
    this.IsLoading = true;
    this.Tasks = [];

    this.Tasks.push(
      new ProgressItem("Retrieving available container", "in_progress", null, this.ContainerAvailableObservable)
    );
    this.Tasks.push(
      new ProgressItem(
        `Loading topology file`,
        "todo",
        this.es.RunDesignerObservable,
        this.es.RunDesignerCompleteObservable
      )
    );
    this.Tasks.push(
      new ProgressItem(
        "Retrieving devices and connections",
        "todo",
        this.es.LoadConfigurationCompleteObservable,
        combineLatest([this.es.GetConnectionsObservable, this.es.GetDevicesObervable])
      )
    );

    this.ContainerAvailableObservable.take(1).subscribe((container: Container) => {
      this.es.runDesignerFile(fileId, container);
    });

    this.reserveContainer();
  }

  continue() {
    console.log("continue is clicked");
    this.IsLoading = false;
    this.LoadInProgressSource.next(false);
  }

  loadLab(labId: string) {
    this.LoadInProgressSource.next(true);
    this.IsLoading = true;
    this.Tasks = [];

    this.Tasks.push(
      new ProgressItem("Retrieving available container", "in_progress", null, this.ContainerAvailableObservable)
    );
    this.Tasks.push(
      new ProgressItem("Loading topology", "todo", this.ContainerAvailableObservable, this.es.LoadTopologyObservable)
    );
    this.Tasks.push(
      new ProgressItem(
        "Loading network configurations",
        "todo",
        this.es.LoadConfigurationObservable,
        this.es.LoadConfigurationCompleteObservable
      )
    );
    this.Tasks.push(new ProgressItem("Retrieving lab guide", "in_progress", null, this.ls.getLabDocument(labId)));
    this.Tasks.push(
      new ProgressItem(
        "Retrieving devices and connections",
        "todo",
        this.es.LoadConfigurationCompleteObservable,
        combineLatest([this.es.GetConnectionsObservable, this.es.GetDevicesObervable])
      )
    );

    this.us.checkLabAccess(labId).subscribe((hasAccess: boolean) => {
      if (hasAccess) {
        this.us.createRecentLab(this.as.CurrentUser.PersonId, labId);

        this.ContainerAvailableObservable.take(1).subscribe((container: Container) => {
          this.es.loadLab(labId, container);
        });

        this.reserveContainer();
      } else {
        this.router.navigate(["no-access"]);
      }
    });
  }

  prepareToLoadTopology(fileId: string) {}

  reserveContainer() {
    console.log("begin reserve container");
    if (this.as.CurrentSession.ContainerId) {
      console.log("release old container before claiming a new one.");
      this.ss.releaseInstance(this.as.CurrentSession).toPromise();
    }
    this.ss
      .claimInstance(this.as.CurrentSession)
      .toPromise()
      .then(
        (container: Container) => {
          this.CurrentContainer = container;
          console.log("container claimed");
          console.log(container);
          if ("Claimed" === container.Status) {
            // Claimed is good. Claimed means it belongs to me.
            this.ContainerAvailableSource.next(container);
          } else {
            // It's either Initialiing-Claimed or Ready-Claimed; both indicate we need to wait for Claimed.
            setTimeout(() => {
              this.checkContainerStatus();
            }, 10000);
          }
        },
        (error) => {
          if (error.status && error.status == 503) {
            // A 503 error means that no container was avaliable in the system. We should try again later.
            setTimeout(() => {
              this.reserveContainer();
            }, 10000);
          } else {
            console.log(error);
          }
        }
      );
  }

  checkContainerStatus() {
    this.cs
      .getContainer(this.CurrentContainer.Id)
      .toPromise()
      .then(
        (latest: Container) => {
          this.CurrentContainer = latest;

          if ("Claimed" == latest.Status) {
            setTimeout(() => {
              this.ContainerAvailableSource.next(latest);
            }, 10000);
          } else if ("Initializing-Claimed" == latest.Status || "Ready-Claimed" == latest.Status) {
            setTimeout(() => {
              this.checkContainerStatus();
            }, 10000);
          } else {
            this.IsError = true;
          }
        },
        (error: HttpErrorResponse) => {
          this.IsError = true;
          console.log(error);
        }
      );
  }
}
