import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from "@angular/core";
import { ResizedEvent } from "../models/Events/ResizedEvent";
@Directive({
  selector: "[appResizedDirective]",
})
export class ResizedDirective {
  private observer: ResizeObserver = new ResizeObserver((entries) => this.zone.run(() => this.observe(entries)));
  private oldRect?: DOMRectReadOnly;

  @Output()
  public readonly resized = new EventEmitter<ResizedEvent>();

  public constructor(private readonly element: ElementRef, private readonly zone: NgZone) {}

  public ngOnInit(): void {
    this.observer.observe(this.element.nativeElement);
  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private observe(entries: ResizeObserverEntry[]): void {
    const domSize = entries[0];
    const resizedEvent = new ResizedEvent(domSize.contentRect, this.oldRect);
    this.oldRect = domSize.contentRect;
    this.resized.emit(resizedEvent);
  }
}

// These are types lifted straight from Typescript ES2020
interface ResizeObserverEntry {
  readonly target: Element;
  readonly contentRect: DOMRectReadOnly;
  readonly borderBoxSize: ReadonlyArray<ResizeObserverSize>;
  readonly contentBoxSize: ReadonlyArray<ResizeObserverSize>;
  readonly devicePixelContentBoxSize: ReadonlyArray<ResizeObserverSize>;
}

interface ResizeObserverSize {
  readonly inlineSize: number;
  readonly blockSize: number;
}

interface ResizeObserver {
  disconnect(): void;
  observe(target: Element, options?: ResizeObserverOptions): void;
  unobserve(target: Element): void;
}

declare var ResizeObserver: {
  new (callback: ResizeObserverCallback): ResizeObserver;
  prototype: ResizeObserver;
};

interface ResizeObserverOptions {
  box?: "content-box" | "border-box" | "device-pixel-content-box" | undefined;
}

interface ResizeObserverCallback {
  (entries: ResizeObserverEntry[], observer: ResizeObserver): void;
}
