import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "src/app/core/services/app.service";
import { AboutNetSimComponent } from "src/app/shared/modals/about-netsim/about-netsim.component";
import { GettingStartedModalComponent } from "src/app/shared/modals/getting-started-modal/getting-started-modal.component";
import { LabTreeService } from "src/app/features/lab/services/lab-tree.service";
import { Location } from "@angular/common";
import { NavigationService } from "src/app/core/services/navigation.service";
import { AuthService } from "src/app/core/services/auth.service";
import { environment } from "src/environments/environment";
import Device from "src/app/engine/models/Device";
import User from "src/app/shared/models/User";
import { switchMap } from "rxjs/operators";
import { EngineService } from "src/app/engine/services/engine.service";
import { LrsService } from "src/app/shared/services/lrs.service";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import Session from "src/app/shared/models/Session";
import RecentLab from "../../models/RecentLab";
import { NavigationBarService } from "../../services/navigation-bar.service";
import { ConsoleService } from "src/app/features/console/services/console.service";
import { ShortcutsComponent } from "../../modals/shortcuts/shortcuts.component";

@Component({
  selector: "app-main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.scss"],
})
export class MainMenuComponent implements OnInit {
  bosonUrl: string = environment.bosonUri;

  isAuthorized: boolean = false;
  isLabLoaded: boolean = false;
  isEngineRunning: boolean = false;
  isMenuCollapsed: boolean = true;
  isDesigner: boolean = false;
  isHidden: boolean = false;
  shouldDisplayFeedback: boolean = false;

  currentUser: User;
  currentLabId: string;
  currentDevices: Device[];
  references: string[] = [];
  previousUrl: string;

  canSave: boolean = false;
  canSaveAs: boolean = false;
  canResumeLab: boolean = false;
  canView: boolean = false;
  canEnd: boolean = false;
  canGrade: boolean = false;
  canRunDesigner: boolean = false;

  private handlers = new Array();

  constructor(
    private ns: NavigationService,
    private as: AuthService,
    private lts: LabTreeService,
    private aps: AppService,
    private modalService: NgbModal,
    private es: EngineService,
    private lrs: LrsService,
    private location: Location,
    private router: Router,
    private nbs: NavigationBarService,
    private cs: ConsoleService
  ) {}

  ngOnDestroy() {
    this.handlers.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.isDesigner = window.location.pathname == "/designer";
    console.log("main menu init");

    this.handlers.push(
      this.lts.labExecuted.subscribe((labId: string) => {
        console.log(`main menu handles labExecuted`);
        this.isLabLoaded = labId && labId.length > 0;
        this.currentLabId = labId;
        this.currentDevices = [];
        this.EvaluateMenu(this.router.url);
      })
    );

    this.handlers.push(
      this.lts.labEnded.subscribe((labId: string) => {
        console.log(`main menu handles labEnded`);
        this.isLabLoaded = false;
        this.isEngineRunning = false;
        this.currentDevices = [];
        this.EvaluateMenu(this.router.url);
      })
    );

    this.handlers.push(
      this.lts.labResumed.subscribe((labId: string) => {
        console.log(`main menu handles labResumed: ${labId}`);
        this.isLabLoaded = labId && labId.length > 0;
        this.isEngineRunning = true;
        this.currentLabId = labId;
        this.currentDevices = this.es.CurrentDevices;
        this.EvaluateMenu(this.router.url);
      })
    );

    this.handlers.push(
      this.lts.labFinished.subscribe((labId: string) => {
        this.isLabLoaded = labId && labId.length > 0;
        this.isEngineRunning = true;
        this.currentLabId = labId;
        this.currentDevices = this.es.CurrentDevices;
        this.EvaluateMenu(this.router.url);
      })
    );

    this.handlers.push(
      this.es.HasChangesObservable.subscribe((hasChanges: boolean) => {
        this.canSaveAs = hasChanges;
      })
    );

    this.handlers.push(
      this.nbs.HasChanges$.subscribe((hasChanges: boolean) => {
        this.canSaveAs = hasChanges;
      })
    );

    this.handlers.push(
      this.nbs.CanRunDesigner$.subscribe((canRun: boolean) => {
        this.canRunDesigner = canRun;
      })
    );

    this.handlers.push(
      this.nbs.ReferencesUpdated$.subscribe((paths: string[]) => {
        this.references = paths;
      })
    );

    this.handlers.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.EvaluateMenu(this.router.url);
        } else if (event instanceof NavigationStart) {
          this.previousUrl = this.router.url;
        }
      })
    );

    this.handlers.push(
      this.aps.DisplayFeedback$.subscribe((shouldDisplay: boolean) => {
        // Log change to LRS
        if (this.shouldDisplayFeedback == false && shouldDisplay == true) {
          this.lrs.LogUserInteractionWithUI("display", "feedback").subscribe();
        }

        // Change feedback status of feedback component
        this.shouldDisplayFeedback = shouldDisplay;
      })
    );

    this.handlers.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.EvaluateMenu(event.url);
        }
      })
    );

    this.handlers.push(
      this.aps.HideNavbar$.subscribe((value) => {
        this.isHidden = value;
      })
    );

    this.handlers.push(
      this.aps.CurrentSession$.subscribe((session: Session) => {
        if (session != null) {
          this.isLabLoaded = session.LabId && session.LabId.length > 0;
          this.isEngineRunning = session.ContainerId && 0 < session.ContainerId.length;
          this.EvaluateMenu(this.router.url);
        }
      })
    );
    this.isLabLoaded =
      this.aps.CurrentSession && this.aps.CurrentSession.LabId && this.aps.CurrentSession.LabId.length > 0;
    this.EvaluateMenu(this.router.url);

    this.handlers.push(
      this.aps.CurrentUser$.subscribe((user: User) => {
        this.currentUser = user;
        this.isAuthorized = true;
      })
    );
    if (this.aps.CurrentUser) {
      this.currentUser = this.aps.CurrentUser;
      this.isAuthorized = true;
    }
  }

  EvaluateMenu(withUrl: string) {
    this.canResumeLab =
      this.isEngineRunning &&
      withUrl !== "/lab" &&
      ["/console", "/netmap", "/document"].filter((url) => {
        return withUrl.startsWith(url);
      }).length === 0;
    this.canView = this.isEngineRunning && withUrl === "/lab";
    this.canEnd = this.isEngineRunning && withUrl === "/lab";
    this.canGrade = this.isLabLoaded && withUrl === "/lab";
    this.isDesigner = withUrl == "/designer";
  }

  OpenFileClick() {
    this.nbs.startOpenFile();
  }

  SaveFileClick() {
    this.nbs.startSaveAs();
  }

  OpenItemClick(name: string) {
    this.ns.OpenItem(name);
  }

  OnLoginClick() {
    this.as.login();
  }

  OnLogoutClick() {
    this.as.logout();
  }

  OpenAbout() {
    this.modalService.open(AboutNetSimComponent, { size: "md", scrollable: false });
  }

  OpenKeyboardShortcuts() {
    this.modalService.open(ShortcutsComponent, { size: "lg", scrollable: false });
  }

  OpenGettingStarted() {
    this.modalService.open(GettingStartedModalComponent, { size: "xl", scrollable: true });
  }

  OnEndLabClick() {
    this.lts.endLab();
  }

  OnRunTopologyClick() {
    this.nbs.runTopology();
  }

  OpenLabDocument() {
    const url = `./document/${this.currentLabId}`;
    window.open(url, "_blank");
  }

  OpenTopology() {
    const url = `./document/${this.currentLabId}/topology`;
    window.open(url, "_blank");
  }

  OpenCommandSummary() {
    const url = `./document/${this.currentLabId}/command-summary`;
    window.open(url, "_blank");
  }

  OpenIpAddresses() {
    const url = `./document/${this.currentLabId}/ip-addresses`;
    window.open(url, "_blank");
  }

  OpenNetMap() {
    const url = `./netmap/${this.currentLabId}`;
    window.open(url, "_blank");
  }

  OpenNetMapForUserFile() {
    const url = `./netmap/${this.es.CurrentFileId}`;
    window.open(url, "_blank");
  }

  NewTopology() {
    this.nbs.startCreateNewTopology();
  }

  OpenConsole(deviceNumber: number) {
    this.cs.OpenExternalConsole(deviceNumber);
  }

  GoBack() {
    this.location.back();
  }
}
