import { Guid } from "guid-typescript";
import BaseXmlSerialization from "src/app/shared/models/TopologyCanvas/BaseXmlSerialization";
import DeviceNode from "src/app/shared/models/TopologyCanvas/Nodes/DeviceNode";
import { ContextMenuService } from "../../../services/context-menu.service";
import DeleteNodeMenuItem from "../../Context Menu/DeleteNodeMenuItem";
import EditDeviceMenuItem from "../../Context Menu/EditDeviceMenuItem";
import IContextMenu from "../../Context Menu/IContextMenuItem";
import DefaultConfiguration from "../Addons/DefaultConfiguration";
import IAddon from "../Addons/IAddon";
import { IInventoryConfiguration } from "../Interfaces/IInventoryConfiguration";
import IInventoryInterface from "../Interfaces/IInventoryInterface";

export default class BaseInventoryDevice extends BaseXmlSerialization {
  id: string;
  series: string;
  model: string;
  flashMemory: number;
  systemMemory: number;
  maxAddons: number;
  interfaces: IInventoryInterface[];
  supportedAddons: IAddon[];
  defaultInterfaces: DefaultConfiguration;

  constructor(model: string, series: string, flash: number, systemMemory: number) {
    super();
    this.id = Guid.create().toString();
    this.model = model;
    this.series = series;
    this.flashMemory = flash;
    this.systemMemory = systemMemory;
    this.interfaces = [];
    this.supportedAddons = [];
    this.defaultInterfaces = new DefaultConfiguration();
  }

  protected initializeDeviceNode(node: DeviceNode, config: IInventoryConfiguration, cms: ContextMenuService) {
    let defaultSlotModifier: number = 0;
    node.id = config.id;
    node.label = config.name;
    node.interfaces = [];
    node.model = this.model;
    node.series = this.series;
    node.flashMemory = this.flashMemory;
    node.systemMemory = this.systemMemory;
    node.defaultPosition = config.location;
    node.position = config.location;
    node.elementType = "device";
    config.inventoryDevice.defaultInterfaces.addToDevice(node, null, 0);
    node.configuration = config;

    // Some devices have no interfaces and rely on addons instead so the first addon is in slot 0
    if (node.interfaces.length > 1) {
      defaultSlotModifier = 1;
    }

    config.addonKeys.forEach((addonConfig) => {
      addonConfig.addon.addToDevice(node, addonConfig.rack, addonConfig.slot + defaultSlotModifier);
    });
  }

  protected serializeDeviceFields(doc: XMLDocument, elem: HTMLElement) {
    this.addKeyValue(doc, elem, "Model", this.model);
    this.addKeyValue(doc, elem, "Series", this.series);
    this.addKeyValue(doc, elem, "SystemMemory", this.flashMemory.toString());
    this.addKeyValue(doc, elem, "FlashMemory", this.systemMemory.toString());
  }

  private GetContextMenuItemsFactory(
    config: IInventoryConfiguration,
    cms: ContextMenuService,
    id: string
  ): IContextMenu[] {
    let menuItems: IContextMenu[] = [new EditDeviceMenuItem(cms, id), new DeleteNodeMenuItem(cms, id)];

    return menuItems;
  }
}
