import FileMeta from "./FileMeta";

export default class LabFileCollection {
  Topology: FileMeta;
  Document: FileMeta;
  LoadingNwc: FileMeta;
  LoadingRtrs: FileMeta[];
  CompletedNwc: FileMeta;
  CompletedRtrs: FileMeta[];

  constructor(private Files: FileMeta[]) {
    let PossiblyUnsortedLoading: FileMeta[] = [];
    let PossiblyUnsortedCompleted: FileMeta[] = [];

    Files.forEach((file: FileMeta) => {
      switch (file.FileType) {
        case 10:
          this.Document = file;
          break;

        case 20:
          this.Topology = file;
          break;

        case 30:
          this.LoadingNwc = file;
          break;

        case 40:
          this.CompletedNwc = file;
          break;

        case 50:
          PossiblyUnsortedLoading.push(file);
          break;

        case 60:
          PossiblyUnsortedCompleted.push(file);
          break;

        default:
          // error
          break;
      }
    });

    this.LoadingRtrs = PossiblyUnsortedLoading.sort(this.routerConfigurationsComparer);
    this.CompletedRtrs = PossiblyUnsortedCompleted.sort(this.routerConfigurationsComparer);
  }

  private routerConfigurationsComparer(left: FileMeta, right: FileMeta): number {
    if (left == null && right == null) return 0;
    if (left == null) return -1;
    if (right == null) return 1;

    if (left.Name == null && right.Name == null) return 0;
    if (left.Name == null) return -1;
    if (right.Name == null) return 1;

    var leftMatch = left.Name.match(/\D+(\d+)/);
    var rightMatch = right.Name.match(/\D+(\d+)/);

    if (leftMatch.length != 2 && rightMatch.length != 2) return 0;
    if (leftMatch.length != 2) return -1;
    if (rightMatch.length != 2) return 1;

    let leftDeviceNum = parseInt(leftMatch[1]);
    let rightDeviceNum = parseInt(rightMatch[1]);

    return leftDeviceNum - rightDeviceNum;
  }
}
