import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { Observable, Subject } from "rxjs";
import Point from "src/app/shared/models/math/Point";
import ITextNode from "src/app/shared/models/TopologyCanvas/Nodes/ITextNode";
import LabelNode from "src/app/shared/models/TopologyCanvas/Nodes/LabelNode";
import NoteNode from "src/app/shared/models/TopologyCanvas/Nodes/NoteNode";

@Injectable({
  providedIn: "root",
})
export class ContextMenuService {
  // variables
  canvasLocation: Point;

  // Observables
  private nodeDeletedSubject: Subject<string> = new Subject<string>();
  nodeDeleted$: Observable<string> = this.nodeDeletedSubject.asObservable();

  private nodeEditedSubject: Subject<string> = new Subject<string>();
  nodeEdited$: Observable<string> = this.nodeEditedSubject.asObservable();

  private connectionDeletedSubject: Subject<string> = new Subject<string>();
  connectionDeleted$: Observable<string> = this.connectionDeletedSubject.asObservable();

  private addLabelSubject: Subject<LabelNode> = new Subject<LabelNode>();
  addLabel$: Observable<LabelNode> = this.addLabelSubject.asObservable();

  private addNoteSubject: Subject<NoteNode> = new Subject<NoteNode>();
  addNote$: Observable<NoteNode> = this.addNoteSubject.asObservable();

  constructor() {}

  deleteNode(id: string) {
    this.nodeDeletedSubject.next(id);
  }

  editNode(id: string) {
    this.nodeEditedSubject.next(id);
  }

  deleteConnection(id: string) {
    this.connectionDeletedSubject.next(id);
  }

  updateCanvasLocation(location: Point) {
    this.canvasLocation = location;
  }

  addNote(location: Point): NoteNode {
    let node = new NoteNode();
    this.configureTextNode(node, location);
    node.type = "Note";
    node.initializeContextMenu(this);
    node.multiLine = true;
    this.addNoteSubject.next(node);
    return node;
  }

  addLabel(location: Point): LabelNode {
    let node = new LabelNode();
    this.configureTextNode(node, location);
    node.type = "Label";
    node.multiLine = false;
    node.initializeContextMenu(this);
    this.addLabelSubject.next(node);
    return node;
  }

  private configureTextNode(node: ITextNode, location: Point) {
    node.id = Guid.create().toString();
    node.text = "Double click to edit text";
    node.elementType = "markup";
    node.label = "";
    node.position = location;
    node.defaultPosition = location;
    node.dimension = { width: 70, height: 70 };
  }
}
