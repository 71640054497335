import BaseInventoryRouter from "./BaseInventoryRouter";

export default class InventoryRouter801 extends BaseInventoryRouter {
  constructor() {
    super("801", "800", 4, 8);
    this.maxAddons = 0;
    this.defaultInterfaces.addEthernetInterfaces("Ethernet", 2, null, this.maxAddons);
    this.defaultInterfaces.addBriInterfaces(1, null, this.maxAddons);
  }
}
