import IHasInterfaces from "../../Shared/interfaces/IHasInterfaces";
import IInventoryDevice from "../Devices/IInventoryDevice";
import InventoryBriInterface from "../Interfaces/InventoryBriInterface";
import IAddon from "./IAddon";

export default class Hwic2Bri implements IAddon {
  name: string = "VIC2-2BRI-NT/TE";
  description: string = "2 Bri";

  addToDevice(device: IHasInterfaces, rack: number, slot: number) {
    for (let i = 0; i < 2; i++) {
      device.interfaces.push(new InventoryBriInterface(rack, slot, i));
    }
  }
}
