import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LabDocumentComponent } from "./features/lab-document/components/lab-document/lab-document.component";
import { AuthGuard } from "./core/guards/AuthGuard";
import { ForbiddenComponent } from "./core/components/forbidden/forbidden.component";
import { AutoLoginComponent } from "./core/components/auto-login/auto-login.component";
import { StandaloneConsoleComponent } from "./features/console/pages/standalone-console/standalone-console.component";
import { NetMapComponent } from "./features/netmap/components/netmap/netmap.component";
import { environment } from "src/environments/environment";
import { NoAccessComponent } from "./shared/pages/no-access/no-access.component";
import { StandaloneBosonTextEditorComponent } from "./features/boson-text-editor/pages/standalone-boson-text-editor/standalone-boson-text-editor.component";
import { LabDocumentViewerComponent } from "./features/lab-document/components/lab-document-viewer/lab-document-viewer.component";

let routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./features/home/home.module").then((m) => m.HomeModule),
  },

  { path: "forbidden", component: ForbiddenComponent },
  { path: "no-access", component: NoAccessComponent },
  { path: "auto-login", component: AutoLoginComponent },
  { path: "editor/:deviceIndex/:fileName", component: StandaloneBosonTextEditorComponent, canActivate: [AuthGuard] },

  {
    path: "nsx",
    loadChildren: () => import("./features/nsx/nsx.module").then((m) => m.NsxModule),
    canActivate: [AuthGuard],
  },

  {
    path: "lab",
    loadChildren: () => import("./features/lab/lab.module").then((m) => m.LabModule),
    canActivate: [AuthGuard],
  },

  { path: "document/viewer", component: LabDocumentViewerComponent, canActivate: [AuthGuard] },
  { path: "document/:id", component: LabDocumentComponent, canActivate: [AuthGuard] },
  { path: "document/:id/topology", component: LabDocumentComponent, canActivate: [AuthGuard] },
  { path: "document/:id/command-summary", component: LabDocumentComponent, canActivate: [AuthGuard] },
  { path: "document/:id/ip-addresses", component: LabDocumentComponent, canActivate: [AuthGuard] },
  { path: "document/preview/:id", component: LabDocumentComponent, canActivate: [AuthGuard] },
  { path: "netmap/:id", component: NetMapComponent, canActivate: [AuthGuard] },
  { path: "console/:id", component: StandaloneConsoleComponent, canActivate: [AuthGuard] },

  {
    path: "license",
    loadChildren: () => import("./features/license-agree/license.module").then((m) => m.LicenseModule),
    canActivate: [AuthGuard],
  },

  {
    path: "grade",
    loadChildren: () => import("./features/grade/grade.module").then((m) => m.GradeModule),
    canActivate: [AuthGuard],
  },

  {
    path: "designer",
    loadChildren: () =>
      import("./features/network-designer/network-designer.module").then((m) => m.NetworkDesignerModule),
    canActivate: [AuthGuard],
  },
  {
    path: "labs",
    loadChildren: () => import("./features/labs/labs.module").then((m) => m.LabsModule),
  },

  {
    path: "open",
    loadChildren: () => import("./features/user-files/files.module").then((m) => m.FileModule),
    canActivate: [AuthGuard],
  },

  {
    path: "save",
    loadChildren: () => import("./features/user-files/files.module").then((m) => m.FileModule),
    canActivate: [AuthGuard],
  },

  {
    path: "run",
    loadChildren: () => import("./features/user-files/files.module").then((m) => m.FileModule),
    canActivate: [AuthGuard],
  },

  {
    path: "preferences",
    loadChildren: () => import("./features/preferences/preferences.module").then((m) => m.PreferencesModule),
    canActivate: [AuthGuard],
  },

  {
    path: "activate",
    loadChildren: () => import("./features/activation/activation.module").then((m) => m.ActivationModule),
    canActivate: [AuthGuard],
  },
];

if ("bee" === environment.sessionApplication) {
  routes = routes.filter((route) => {
    return ["nsx", "auto-login", "forbidden"].includes(route.path);
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
