import { Injectable, Output } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { of, Subject } from "rxjs";
import { Font } from "../models/Font";
import { UserPreferences } from "../../../shared/models/UserPreferences";
import { environment } from "../../../../environments/environment";
import { AppService } from "../../../core/services/app.service";
import { switchMap, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PreferencesService {
  //Fields
  uri = environment.apiUri + "/user-preferences";

  //Sources
  private PreferencesLoadedSource = new Subject();
  private PreferencesChangedSource = new Subject<UserPreferences>();
  private SetToDarkModeSource = new Subject<boolean>();

  //Observables
  PreferencesChangedObservable = this.PreferencesChangedSource.asObservable();
  SetToDarkModeObservable = this.SetToDarkModeSource.asObservable();

  //Constructor
  constructor(private http: HttpClient, private as: AppService) {}

  //Notify Methods
  NotifyPreferencesChanged(preferences: UserPreferences) {
    this.PreferencesChangedSource.next(preferences);
  }

  GetAvailableFontSizes() {
    //until API is updated this is a canned list of sizes
    return [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 28, 30, 35, 40, 45, 50];
  }

  GetMonospaceFonts(): Array<Font> {
    //According to w3 schools there are only two websafe monospace fonts
    return [
      {
        FontName: "Courier New",
        FontFamily: "'Courier New', Courier",
      },
      {
        FontName: "Lucida Console",
        FontFamily: "'Lucida Console', Monaco",
      },
    ];
  }

  GetTextFonts(): Array<Font> {
    return [
      {
        FontName: "Arial",
        FontFamily: "'arial',sans-serif",
      },
      {
        FontName: "Brush Script MT",
        FontFamily: "'Brush Script MT',cursive",
      },
      {
        FontName: "Comic Sans",
        FontFamily: "'Comic Sans MS', 'Comic Sans', cursive",
      },
      {
        FontName: "Georgia",
        FontFamily: "'georgia',serif",
      },
      {
        FontName: "Helvetica",
        FontFamily: "'helvetica',sans-serif",
      },
      {
        FontName: "Tahoma ",
        FontFamily: "'tahoma',sans-serif",
      },
      {
        FontName: "Times New Roman",
        FontFamily: "'Times New Roman',serif",
      },
      {
        FontName: "Trebuchet MS",
        FontFamily: "'trebuchet ms',sans-serif",
      },
      {
        FontName: "Verdana ",
        FontFamily: "'verdana',sans-serif",
      },
    ];
  }

  GetTroubleshootingVersions(): Array<string> {
    //Uses Regex to ignore everything after major and minor the set removes duplicates
    let Versions = ["1.0.0", "1.0.1", "1.5.0", "2.0.0", "2.0.1"];
    let versionRegex = /^\d+\.\d+/g;
    let MajorMinorVersions: Array<string> = Array.from(
      new Set(
        Versions.map((e) => e.match(versionRegex))
          .filter((e) => e.length > 0)
          .map((e) => `${e[0]}`)
      )
    );

    //Sorts by Major then minor version in decending order
    MajorMinorVersions.sort((a, b) => {
      let versionArrayA: Array<number> = a.split(".").map((e) => parseInt(e));
      let versionArrayB: Array<number> = b.split(".").map((e) => parseInt(e));
      let x: number, y: number;

      if (versionArrayA[0] == versionArrayB[0]) {
        x = versionArrayA[1];
        y = versionArrayB[1];
      } else {
        x = versionArrayA[0];
        y = versionArrayB[0];
      }

      return y - x;
    });

    //Format the array to desired display
    MajorMinorVersions = MajorMinorVersions.map((e) => `${e}.x`);
    MajorMinorVersions[0] = "Latest";

    return MajorMinorVersions;
  }

  ClearLabHistory(userId: number) {
    return this.http.delete(`${environment.apiUri}/users/${userId}/history`).toPromise();
  }

  getCurrentPreferences() {
    if (this.as.CurrentUser) {
      return this.http.get<any[]>(`${this.uri}/${this.as.CurrentUser.Id}`).pipe(
        switchMap((r) => {
          return of(this.ConvertRawPreferences(r));
        })
      );
    } else {
      return this.as.CurrentUser$.pipe(
        switchMap(() => {
          return this.http.get<any[]>(`${this.uri}/${this.as.CurrentUser.Id}`);
        }),
        switchMap((r) => {
          return of(this.ConvertRawPreferences(r));
        })
      );
    }
  }

  updatePreferences(preferences: any) {
    return this.http.patch(`${this.uri}/${this.as.CurrentUser.Id}`, preferences);
  }

  private ConvertRawPreferences(raw: any[]): UserPreferences {
    let preferences = new UserPreferences();
    let defaultPreferences = environment.defaultPreferences;

    //Convert array to preferences object
    for (let i = 0; i < raw.length; i++) {
      try {
        preferences[raw[i].Key] = raw[i].Value;
      } catch (error) {
        continue;
      }
    }

    //Set Default values if missing
    for (const [key, value] of Object.entries(defaultPreferences)) {
      try {
        if (preferences[key] == undefined) {
          preferences[key] = value;
        }
      } catch (error) {
        continue;
      }
    }

    return preferences;
  }

  setThemeBasedOnSystem() {
    if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
      document.documentElement.setAttribute("data-theme", "dark");
      document.querySelector('meta[name="theme-color"]').setAttribute("content", "#796db8");
    } else {
      document.documentElement.setAttribute("data-theme", "light");
      document.querySelector('meta[name="theme-color"]').setAttribute("content", "#493D91");
    }
  }

  setThemeBasedOnPreferences(settings: UserPreferences) {
    if (settings.theme == "Light") {
      document.documentElement.setAttribute("data-theme", "light");
      document.querySelector('meta[name="theme-color"]').setAttribute("content", "#493D91");
    } else if (settings.theme == "Dark") {
      document.documentElement.setAttribute("data-theme", "dark");
      document.querySelector('meta[name="theme-color"]').setAttribute("content", "#796db8");
    } else {
      this.setThemeBasedOnSystem();
    }
  }

  getIsDarkModeActive(): boolean {
    if (document.documentElement.getAttribute("data-theme") == "dark") {
      return true;
    } else {
      return false;
    }
  }

  addSystemDarkModeListener() {
    // Verify that the browser supports this listener first
    try {
      window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => {
        this.getCurrentPreferences().subscribe((settings: UserPreferences) => {
          this.setThemeBasedOnPreferences(settings);
          this.SetToDarkModeSource.next(this.getIsDarkModeActive());
        });
      });
    } catch (ex) {
      console.log("This browser does not support integration with system dark mode preferences.");
    }
  }

  async SwapImageColors(src: string, originalColor: string, newColor: string) {
    // create fake image to calculate height / width
    let img = new Image();
    img.src = src;
    img.style.visibility = "hidden";

    // Get image dimensions and create the canvas
    let ImageDimensions = await this.getImageDimensions(src);
    let canvas = document.createElement("canvas");

    // Set Canvas specs
    canvas.width = ImageDimensions.width;
    canvas.height = ImageDimensions.height;

    //Draw the image on the canvas
    let imageCanvas = canvas.getContext("2d");
    imageCanvas.drawImage(img, 0, 0);

    // do actual color replacement
    let imageData = imageCanvas.getImageData(0, 0, canvas.width, canvas.height);
    let data = imageData.data;

    // Convert Hex to RGB since it is easier to work with
    let rgbFrom = this.hexToRGB(originalColor);
    let rgbTo = this.hexToRGB(newColor);

    // Iterate over the entire image byte array replacing the color as it is encountered
    let r, g, b;
    for (let i = 0, byteLength = data.length; i < byteLength; i += 4) {
      r = data[i];
      g = data[i + 1];
      b = data[i + 2];

      if (r == rgbFrom.r && g == rgbFrom.g && b == rgbFrom.b) {
        data[i] = rgbTo.r;
        data[i + 1] = rgbTo.g;
        data[i + 2] = rgbTo.b;
      }
    }

    // Extract the modified image from the canvas and return the base64 url string
    imageCanvas.putImageData(imageData, 0, 0);
    return canvas.toDataURL();
  }

  private hexToRGB(hexStr): { r: number; g: number; b: number } {
    let color: any = {};
    color.r = parseInt(hexStr.substr(1, 2), 16);
    color.g = parseInt(hexStr.substr(3, 2), 16);
    color.b = parseInt(hexStr.substr(5, 2), 16);

    return color;
  }
  private getImageDimensions(base64): Promise<{ width: number; height: number }> {
    return new Promise(function (resolved, rejected) {
      var i = new Image();
      i.onload = function () {
        resolved({ width: i.width, height: i.height });
      };
      i.src = base64;
    });
  }

  private calculateLuminence(hex: string) {
    let rgbColor = this.hexToRGB(hex);
    let luminence: number = (0.299 * rgbColor.r + 0.587 * rgbColor.g + 0.114 * rgbColor.b) / 255;
  }
}
