import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import User from "../models/User";
import { Observable, Subject } from "rxjs";
import Session from "../models/Session";
import ActivityFilter from "src/app/shared/models/ActivityFilter";
import Activity from "src/app/shared/models/Activity";
import FavoriteConfiguration from "src/app/features/network-designer/models/Inventory/Favorites/FavoriteConfiguration";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  uri = environment.apiUri + "/users";
  private ActivityUpdatedSource = new Subject<Activity>();
  ActivityUpdatedObservable: Observable<Activity> = this.ActivityUpdatedSource.asObservable();
  ActivityDeleted = new Subject<string>();
  ActivityDeleted$: Observable<string> = this.ActivityDeleted.asObservable();

  constructor(private http: HttpClient) {}

  getUserByEmail(email: string): Observable<User> {
    const encodedEmail: string = btoa(email);
    return this.http.get<User>(`${this.uri}/email/${encodedEmail}`);
  }

  getUserByPersonId(personId: number) {
    return this.http.get<User>(`${this.uri}/person-id/${personId}`);
  }

  getActiveSession(id: string): Observable<Session> {
    return this.http.get<Session>(`${this.uri}/${id}/session`, {
      params: { Application: environment.sessionApplication },
    });
  }

  releaseSession(id: string) {
    return this.http.delete<Session>(`${this.uri}/${id}/session`, {
      params: { Application: environment.sessionApplication },
    });
  }

  getTimeInNetSim(id) {
    return this.http.get(`${this.uri}/${id}/totaltimeloggedin`);
  }

  getFeedback(id) {
    return this.http.get(`${this.uri}/${id}/feedback`);
  }

  getGuestLabTree() {
    console.log("getting guest lab tree");
    return this.http.get(`${this.uri}/labtree`);
  }

  getLabTree(id) {
    return this.http.get(`${this.uri}/${id}/labtree`);
  }

  getLabTreeHistory(id: string) {
    return this.http.get(`${this.uri}/${id}/labtreehistory`);
  }

  getHistory(filter: ActivityFilter) {
    var filterParams: HttpParams = new HttpParams();

    Object.keys(filter).map(function (filterNamedIndex) {
      filterParams = filterParams.append(filterNamedIndex, filter[filterNamedIndex]);
    });

    return this.http.get(`${this.uri}/${filter.PersonId}/history`, { params: filterParams });
  }

  checkLabAccess(labId: string) {
    return this.http.get(`${this.uri}/lab-access/${labId}`);
  }

  createRecentLab(personId: number, labId) {
    let obj: Activity = new Activity();
    obj.PersonId = personId.toString();
    obj.MetaData = "";
    obj.Verb = "Started";
    obj.Subject = labId;

    this.http.post(`${environment.apiUri}/activities`, obj).subscribe((res) => {
      this.ActivityUpdatedSource.next(obj);
    });
  }

  createGradeLab(id: string, personId: number, labId: string, result: string) {
    let obj: Activity = new Activity();
    obj.PersonId = personId.toString();
    obj.MetaData = JSON.stringify({
      GradeResultId: id,
    });
    obj.Verb = result;
    obj.Subject = labId;

    this.http.post(`${environment.apiUri}/activities`, obj).subscribe((res) => {
      this.ActivityUpdatedSource.next(obj);
    });
  }

  getRecentLabs(id: string) {
    return this.http.get(`${this.uri}/${id}/recent-labs`);
  }

  getResumeProgress(id: string) {
    return this.http.get(`${this.uri}/${id}/resume-progress`);
  }

  getSavedLabs(id: string) {
    return this.http.get(`${this.uri}/${id}/saved-labs`);
  }

  getHistoryByLabId(filter: ActivityFilter) {
    var filterParams: HttpParams = new HttpParams();

    Object.keys(filter).map(function (filterNamedIndex) {
      filterParams = filterParams.append(filterNamedIndex, filter[filterNamedIndex]);
    });

    return this.http.get(`${this.uri}/${filter.PersonId}/history/${filter.Subject}`, { params: filterParams });
  }

  deleteHistoryByLabId(personId: number, labId: string) {
    return this.http.delete(`${this.uri}/${personId}/history/${labId}`);
  }

  BetterActivityVerb(oldVerb: string, newVerb: string): string {
    let verbMap = {
      Started: 1,
      GradedIncorrect: 2,
      GradedCorrect: 3,
    };

    if (!oldVerb) {
      return newVerb;
    } else {
      if (verbMap[oldVerb] < verbMap[newVerb]) {
        return newVerb;
      } else {
        return oldVerb;
      }
    }
  }

  getFavoriteConfigurations(id: string) {
    return this.http.get(`${this.uri}/${id}/configurations/favorite`);
  }

  addFavoriteConfiguration(id: string, config: FavoriteConfiguration) {
    console.log(config);
    return this.http.post(`${this.uri}/${id}/configurations/favorite`, config);
  }

  deleteFavoriteConfigurations(id: string, configId: string) {
    return this.http.delete(`${this.uri}/${id}/configurations/favorite/${configId}`);
  }
}
