import { Guid } from "guid-typescript";
import Connection from "src/app/engine/models/Connection";
import IInventoryInterface from "./IInventoryInterface";
import { CreateInterfaceName } from "./InterfaceModule";

export default class InventoryConsoleInterface implements IInventoryInterface {
  id: string;
  name: string;
  type: string;
  rack: number;
  slot: number;
  port: number;

  constructor() {
    this.id = Guid.create().toString();
    this.type = "Console";
    this.name = "Console0";
    this.rack = null;
    this.slot = null;
    this.port = 0;
  }

  addToSerializedTopology(doc: XMLDocument, parent?: HTMLElement) {
    // Console interfaces are not writtent to output
    return null;
  }

  createConnection(): Connection {
    let newConnection = new Connection();
    newConnection.ConnectorType = this.type;
    return newConnection;
  }
}
