import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import EditorResponse from "src/app/engine/models/EditorResponse";
import { EngineService } from "src/app/engine/services/engine.service";

@Component({
  selector: "app-boson-text-editor",
  templateUrl: "./boson-text-editor.component.html",
  styleUrls: ["./boson-text-editor.component.scss"],
})
export class BosonTextEditorComponent implements OnInit {
  @Input() IsStandAlone: boolean = false;
  @Input() Contents: string = "";
  @Input() FileName: string = "";
  @Input() DeviceIndex: number = 0;
  Exit: () => void;

  constructor(private es: EngineService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.IsStandAlone) {
      this.route.params.subscribe((params) => {
        this.DeviceIndex = parseInt(params["deviceIndex"]);
        this.FileName = params["fileName"];
        this["Exit"] = () => {
          window.close();
        };

        let startTimer = setInterval(() => {
          if (this.es.webSocketConnection) {
            this.refreshClick();
            clearInterval(startTimer);
          }
        }, 100);
      });
    }
  }

  saveClick() {
    this.es.saveEditor(this.DeviceIndex, this.Contents).take(1).subscribe();
  }

  refreshClick() {
    this.es.webSocketConnection;
    this.es
      .refreshEditor(this.DeviceIndex)
      .take(1)
      .subscribe((response: EditorResponse) => {
        this.Contents = response.contents;
      });
  }

  exitClick() {
    console.log(this.Exit, this.IsStandAlone);
    this.Exit();
  }
}
