<div
  *ngIf="!loading"
  #terminal
  class="console"
  [class]="IsStandalone ? 'standalone-console' : ''"
  tabindex="0"
  (contextmenu)="rightClickPaste($event)"
  (mouseup)="mouseUp($event)"
  (input)="handleInput($event)"
  (keydown)="handleKeyDown($event)"
  (keypress)="handleKeyPress($event)"
  [ngStyle]="{
    'background-color': BackgroundColor,
    color: ForegroundColor,
    'font-family': FontFamily,
    'font-size.pt': FontSize
  }"
  contenteditable="true"
  autocomplete="off"
  autocorrect="off"
  autocapitalize="off"
  spellcheck="false"
>
  <span class="cursor">_</span>
</div>
<progress-spinner *ngIf="loading" style="display: block; text-align: center; padding-top: 40%"></progress-spinner>
