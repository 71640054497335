import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import Session from "../models/Session";
import Container from "../models/Container";
import { Observable } from "rxjs";
import UserAgentData from "../models/UserAgentData";

@Injectable({
  providedIn: "root",
})
export class SessionsService {
  uri = environment.apiUri + "/sessions";

  constructor(private http: HttpClient) {}

  createSession(UserId, Status) {
    let ScreenResolution = [0, 0];
    let UAData: UserAgentData = new UserAgentData();

    UAData.UserAgentString = navigator.userAgent;
    ScreenResolution = this.getScreenResolution();

    const obj = {
      UserId,
      Status,
      UserAgentData: UAData,
      ScreenResolution,
      Application: environment.sessionApplication,
    };
    return this.http.post(`${this.uri}`, obj);
  }

  updateSession(Session: Session) {
    return this.http.patch(`${this.uri}/${Session.Id}`, Session);
  }

  updateLastSeenAt(Session: Session, LastSeenAt: string = new Date().toISOString()) {
    Session.LastSeenAt = LastSeenAt;
    return this.http.patch(`${this.uri}/${Session.Id}`, Session);
  }

  claimInstance(Session: Session): Observable<Container> {
    return this.http.post<Container>(`${this.uri}/${Session.Id}/containers`, []);
  }

  releaseInstance(Session: Session) {
    return this.http.delete<Container>(`${this.uri}/${Session.Id}/containers`, {});
  }

  private getScreenResolution(): number[] {
    return [
      Math.round(window.screen.width * window.devicePixelRatio),
      Math.round(window.screen.height * window.devicePixelRatio),
    ];
  }
}
