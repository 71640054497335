import { Injectable } from "@angular/core";
import { AbstractSecurityStorage } from "angular-auth-oidc-client";

@Injectable()
export class AuthLocalStorage implements AbstractSecurityStorage {
  public read(key: string): any {
    const item: string = window.localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    } else {
      return null;
    }
  }

  public write(key: string, value: any): void {
    try {
      value = value || null;
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // log error
    }
  }

  public remove(key: string): void {
    window.localStorage.removeItem(key);
  }
}
