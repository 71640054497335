import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { take } from "rxjs/operators";
import KeyPair from "src/app/shared/models/KeyPair";
import ConnectionEdge from "src/app/shared/models/TopologyCanvas/Edges/ConnectionEdge";
import { NetMapLayout } from "src/app/shared/models/TopologyCanvas/NetMapLayout";
import DeviceNode from "src/app/shared/models/TopologyCanvas/Nodes/DeviceNode";
import { ITopologyNode } from "src/app/shared/models/TopologyCanvas/Nodes/ITopologyNode";
import IContextMenuSupport from "../../../models/Context Menu/IContextMenuSupport";
import { CanvasService } from "../../../services/canvas.service";
import { SelectInterfaceComponent } from "../../select-interface/select-interface.component";

@Component({
  selector: "[app-device-node]",
  templateUrl: "./device-node.component.svg",
  styleUrls: ["./device-node.component.scss"],
})
export class DeviceNodeComponent implements OnInit, DoCheck {
  // Inputs from canvas
  @ViewChild("iconImage") iconImage: any;
  @ViewChild("nameRect") labelRectangle: ElementRef;
  @Input() node: ITopologyNode;
  @Input() activeCursor: string;
  @Input() layout: NetMapLayout;
  xIcon: number = 0;
  yIcon: number = 0;

  _iconUrl: string;
  get iconUrl(): string {
    return this._iconUrl;
  }
  set iconUrl(value: string) {
    this._iconUrl = value;
    this.changes.detectChanges();
  }

  isNodeLoaded: boolean = false;
  errorredImage: string;

  //Events
  @Output() connectionModalShown: EventEmitter<{ node: DeviceNode; event: MouseEvent }> = new EventEmitter<{
    node: DeviceNode;
    event: MouseEvent;
  }>();
  @Output() connectionModalDismissed: EventEmitter<{ node: DeviceNode; event: MouseEvent }> = new EventEmitter<{
    node: DeviceNode;
    event: MouseEvent;
  }>();
  @Output() connectionModalClosed: EventEmitter<{ node: DeviceNode; event: MouseEvent }> = new EventEmitter<{
    node: DeviceNode;
    event: MouseEvent;
  }>();
  @Output() contextMenuDisplayed: EventEmitter<{ node: IContextMenuSupport; event: PointerEvent }> = new EventEmitter<{
    node: IContextMenuSupport;
    event: PointerEvent;
  }>();

  constructor(private modalService: NgbModal, private cs: CanvasService, private changes: ChangeDetectorRef) {}
  ngDoCheck(): void {
    if (this.isNodeLoaded == false && this.node != undefined) {
      this.isNodeLoaded = true;
      this.iconUrl = this.setIconImage(this.node.type, (<DeviceNode>this.node).configuration.options, true);
    } else if (this.isNodeLoaded == true && this.node == undefined) {
      this.isNodeLoaded = false;
    }

    if (this.isNodeLoaded == true) {
      //setting image for the first time
      if (
        !this.errorredImage &&
        this.iconUrl != this.setIconImage(this.node.type, (<DeviceNode>this.node).configuration.options, true)
      ) {
        this.iconUrl = this.setIconImage(this.node.type, (<DeviceNode>this.node).configuration.options, true);
        return;
      }

      // Image changed
      let nodeIconPath = this.getIconFromConfig(<DeviceNode>this.node);
      if (nodeIconPath && nodeIconPath != this.iconUrl && !this.errorredImage) {
        this.iconUrl = this.setIconImage(this.node.type, (<DeviceNode>this.node).configuration.options, true);
        return;
      }

      //image changed from error
      if (nodeIconPath && nodeIconPath != this.iconUrl && this.errorredImage != nodeIconPath) {
        this.iconUrl = this.setIconImage(this.node.type, (<DeviceNode>this.node).configuration.options, true);
        this.errorredImage = null;
        return;
      }
    }
  }

  ngOnInit(): void {}

  getIconFromConfig(node: DeviceNode): string {
    try {
      return node.configuration.options.find((m) => m.Key == "Icon").Value;
    } catch (error) {
      return null;
    }
  }

  deviceClick(dev: ITopologyNode, event: MouseEvent) {
    if (this.activeCursor == "connection") {
      let modalRef = this.modalService.open(SelectInterfaceComponent, {
        scrollable: true,
        centered: true,
        size: "xs",
      });

      // When the modal is shown initialize it with data
      modalRef.shown.pipe(take(1)).subscribe((observer) => {
        this.connectionModalShown.next({ node: <DeviceNode>dev, event: event });
      });

      // modal is exited out of without a selection. Reset connection selection
      modalRef.dismissed.pipe(take(1)).subscribe((observer) => {
        this.connectionModalDismissed.next({ node: <DeviceNode>dev, event: event });
      });

      // modal is exited out of without a selection. Reset connection selection
      modalRef.closed.pipe(take(1)).subscribe((observer) => {
        this.connectionModalClosed.next({ node: <DeviceNode>dev, event: event });
      });
    }
  }

  // Some browsers have different drag drop mechanics so disable the default event and let the topology handle it
  @HostListener("dragstart", ["$event"])
  dragStart(event: DragEvent) {
    event.preventDefault();
  }

  onImageLoad(event) {
    // centers the image on the node
    let boundingBox = event.target.getBoundingClientRect();
    let rectangleBox = this.labelRectangle.nativeElement.getBoundingClientRect();
    let diffForCenter = (rectangleBox.width - boundingBox.width) / 2;
    this.xIcon = diffForCenter < 0 ? 0 : diffForCenter;
  }

  onImageError(event) {
    console.info("Failed to load image", this.iconUrl);
    this.errorredImage = this.iconUrl;
    this.iconUrl = this.setIconImage(this.node.type, (<DeviceNode>this.node).configuration.options, false);
  }

  inDragCollection(node: ITopologyNode) {
    return this.layout.dragCollection.some((m) => m.id == node.id);
  }

  inSnapCollection(node: ITopologyNode) {
    for (let i = 0; i < this.layout.snapCollection.length; i++) {
      if (
        this.layout.snapCollection[i].BestAxis == "X" &&
        this.layout.snapCollection[i].SnappableNode.position.y == node.position.y
      ) {
        return true;
      }

      if (
        this.layout.snapCollection[i].BestAxis == "Y" &&
        this.layout.snapCollection[i].SnappableNode.position.x == node.position.x
      ) {
        return true;
      }
    }

    return false;
  }

  displayContextMenu(event: PointerEvent, node: IContextMenuSupport) {
    this.contextMenuDisplayed.next({ node: node, event: event });
  }

  setIconImage(type: string, options: KeyPair[], customEnabled: boolean): string {
    let iconPath = options.find((m) => m.Key == "Icon");
    if (customEnabled == true && iconPath && iconPath.Value && iconPath.Value != "") {
      return iconPath.Value;
    } else {
      if (type == "Switch") {
        return "/assets/images/Switch.png";
      } else if (type == "Host") {
        return "/assets/images/WinPC.png";
      } else if (type == "Phone") {
        return "/assets/images/IPPhone.png";
      } else if (type == "Frame Relay") {
        return "/assets/images/netmap/cloud.svg";
      } else {
        // default to router image
        return "/assets/images/Router.png";
      }
    }
  }
}
