import { StringMapWithRename } from "@angular/compiler/src/compiler_facade_interface";
import { Injectable, PipeDecorator } from "@angular/core";
import { DndDropEvent } from "ngx-drag-drop";
import { Observable, Subject } from "rxjs";
import KeyPair from "src/app/shared/models/KeyPair";
import EHwic1Gigabit from "../models/Inventory/Addons/EHwic1Gigabit";
import EHwic4Gigabit from "../models/Inventory/Addons/EHwic4Gigabit";
import Hwic1Serial from "../models/Inventory/Addons/Hwic1Serial";
import Hwic2Bri from "../models/Inventory/Addons/Hwic2Bri";
import Hwic2FastEthernet from "../models/Inventory/Addons/Hwic2FastEthernet";
import Hwic2Serial from "../models/Inventory/Addons/Hwic2Serial";
import Hwic4Bri from "../models/Inventory/Addons/Hwic4Bri";
import Hwic4Serial from "../models/Inventory/Addons/Hwic4Serial";
import Hwic8Serial from "../models/Inventory/Addons/Hwic8Serial";
import IAddon from "../models/Inventory/Addons/IAddon";
import NM1Ethernet from "../models/Inventory/Addons/NM1Ethernet";
import NM1FastEthernet from "../models/Inventory/Addons/NM1FastEthernet";
import NM4ASSerial from "../models/Inventory/Addons/NM4ASSerial";
import NM4Bri from "../models/Inventory/Addons/NM4Bri";
import NM4Ethernet from "../models/Inventory/Addons/NM4Ethernet";
import NM4Serial from "../models/Inventory/Addons/NM4Serial";
import NM8ASSerial from "../models/Inventory/Addons/NM8ASSerial";
import NM8Bri from "../models/Inventory/Addons/NM8Bri";
import PC1Eth from "../models/Inventory/Addons/PC1Eth";
import SW14FastEthernet from "../models/Inventory/Addons/SW14FastEthernet";
import SW2Eth from "../models/Inventory/Addons/SW2Eth";
import SW3Eth from "../models/Inventory/Addons/SW3Eth";
import SW4Eth from "../models/Inventory/Addons/SW4Eth";
import Wic1Bri from "../models/Inventory/Addons/Wic1Bri";
import Wic1Ethernet from "../models/Inventory/Addons/Wic1Ethernet";
import Wic1Serial from "../models/Inventory/Addons/Wic1Serial";
import Wic2Serial from "../models/Inventory/Addons/Wic2Serial";
import IInventoryDevice from "../models/Inventory/Devices/IInventoryDevice";
import BaseInventoryRouter from "../models/Inventory/Devices/Routers/BaseInventoryRouter";
import InventoryRouter1003 from "../models/Inventory/Devices/Routers/InventoryRouter1003";
import InventoryRouter1004 from "../models/Inventory/Devices/Routers/InventoryRouter1004";
import InventoryRouter1005 from "../models/Inventory/Devices/Routers/InventoryRouter1005";
import InventoryRouter1601 from "../models/Inventory/Devices/Routers/InventoryRouter1601";
import InventoryRouter1602 from "../models/Inventory/Devices/Routers/InventoryRouter1602";
import InventoryRouter1603 from "../models/Inventory/Devices/Routers/InventoryRouter1603";
import InventoryRouter1604 from "../models/Inventory/Devices/Routers/InventoryRouter1604";
import InventoryRouter1605 from "../models/Inventory/Devices/Routers/InventoryRouter1605";
import InventoryRouter1710 from "../models/Inventory/Devices/Routers/InventoryRouter1710";
import InventoryRouter1720 from "../models/Inventory/Devices/Routers/InventoryRouter1720";
import InventoryRouter1721 from "../models/Inventory/Devices/Routers/InventoryRouter1721";
import InventoryRouter1750 from "../models/Inventory/Devices/Routers/InventoryRouter1750";
import InventoryRouter1751 from "../models/Inventory/Devices/Routers/InventoryRouter1751";
import InventoryRouter1760 from "../models/Inventory/Devices/Routers/InventoryRouter1760";
import InventoryRouter1941 from "../models/Inventory/Devices/Routers/InventoryRouter1941";
import InventoryRouter2501 from "../models/Inventory/Devices/Routers/InventoryRouter2501";
import InventoryRouter2502 from "../models/Inventory/Devices/Routers/InventoryRouter2502";
import InventoryRouter2503 from "../models/Inventory/Devices/Routers/InventoryRouter2503";
import InventoryRouter2504 from "../models/Inventory/Devices/Routers/InventoryRouter2504";
import InventoryRouter2505 from "../models/Inventory/Devices/Routers/InventoryRouter2505";
import InventoryRouter2507 from "../models/Inventory/Devices/Routers/InventoryRouter2507";
import InventoryRouter2509 from "../models/Inventory/Devices/Routers/InventoryRouter2509";
import InventoryRouter2513 from "../models/Inventory/Devices/Routers/InventoryRouter2513";
import InventoryRouter2514 from "../models/Inventory/Devices/Routers/InventoryRouter2514";
import InventoryRouter2515 from "../models/Inventory/Devices/Routers/InventoryRouter2515";
import InventoryRouter2516 from "../models/Inventory/Devices/Routers/InventoryRouter2516";
import InventoryRouter2520 from "../models/Inventory/Devices/Routers/InventoryRouter2520";
import InventoryRouter2521 from "../models/Inventory/Devices/Routers/InventoryRouter2521";
import InventoryRouter2522 from "../models/Inventory/Devices/Routers/InventoryRouter2522";
import InventoryRouter2523 from "../models/Inventory/Devices/Routers/InventoryRouter2523";
import InventoryRouter2610 from "../models/Inventory/Devices/Routers/InventoryRouter2610";
import InventoryRouter2611 from "../models/Inventory/Devices/Routers/InventoryRouter2611";
import InventoryRouter2620 from "../models/Inventory/Devices/Routers/InventoryRouter2620";
import InventoryRouter2621 from "../models/Inventory/Devices/Routers/InventoryRouter2621";
import InventoryRouter2811 from "../models/Inventory/Devices/Routers/InventoryRouter2811";
import InventoryRouter2901 from "../models/Inventory/Devices/Routers/InventoryRouter2901";
import InventoryRouter2911 from "../models/Inventory/Devices/Routers/InventoryRouter2911";
import InventoryRouter3620 from "../models/Inventory/Devices/Routers/InventoryRouter3620";
import InventoryRouter3640 from "../models/Inventory/Devices/Routers/InventoryRouter3640";
import InventoryRouter801 from "../models/Inventory/Devices/Routers/InventoryRouter801";
import InventoryRouter802 from "../models/Inventory/Devices/Routers/InventoryRouter802";
import InventoryRouter803 from "../models/Inventory/Devices/Routers/InventoryRouter803";
import InventoryRouter804 from "../models/Inventory/Devices/Routers/InventoryRouter804";
import InventoryRouter805 from "../models/Inventory/Devices/Routers/InventoryRouter805";
import InventoryRouter806 from "../models/Inventory/Devices/Routers/InventoryRouter806";
import InventoryDiscoverySwitch from "../models/Inventory/Devices/Special/InventoryDiscoverySwitch";
import InventoryPC from "../models/Inventory/Devices/Special/InventoryPC";
import InventoryPhone from "../models/Inventory/Devices/Special/InventoryPhone";
import BaseInventorySwitch from "../models/Inventory/Devices/Switches/BaseInventorySwitch";
import InventorySwitch2950 from "../models/Inventory/Devices/Switches/InventorySwitch2950";
import InventorySwitch296024TT from "../models/Inventory/Devices/Switches/InventorySwitch296024TT";
import InventorySwitch296048TT from "../models/Inventory/Devices/Switches/InventorySwitch296048TT";
import InventorySwitch29608 from "../models/Inventory/Devices/Switches/InventorySwitch29608";
import InventorySwitch3550 from "../models/Inventory/Devices/Switches/InventorySwitch3550";
import InventorySwitch35608pc from "../models/Inventory/Devices/Switches/InventorySwitch35608pc";
import InventorySwitch3750S from "../models/Inventory/Devices/Switches/InventorySwitch3750S";
import InventorySwitch3750Stack from "../models/Inventory/Devices/Switches/InventorySwitch3750Stack";
import IInventoryTreeNode from "../models/Tree/IInventoryTreeNode";

@Injectable({
  providedIn: "root",
})
export class InventoryTreeService {
  //Fields
  lastDropEvent: DndDropEvent;
  allDevices: IInventoryDevice[];
  allAddons: IAddon[];

  //Observables
  private configurationOptionsLoadedSubject: Subject<KeyPair[]> = new Subject<KeyPair[]>();
  configurationOptionsLoaded$: Observable<KeyPair[]> = this.configurationOptionsLoadedSubject.asObservable();

  private favoriteConfigurationUpdatedSubject: Subject<boolean> = new Subject<boolean>();
  favoriteConfigurationsUpdated$: Observable<boolean> = this.favoriteConfigurationUpdatedSubject.asObservable();

  constructor() {
    this.allDevices = [...this.getAllRouters(), ...this.getAllSwitches(), ...this.getSpecialDevices()];
    this.allAddons = this.getAddons();
  }

  getAllRouters(): BaseInventoryRouter[] {
    return [
      new InventoryRouter1003(),
      new InventoryRouter1004(),
      new InventoryRouter1005(),
      new InventoryRouter1601(),
      new InventoryRouter1602(),
      new InventoryRouter1603(),
      new InventoryRouter1604(),
      new InventoryRouter1605(),
      new InventoryRouter1710(),
      new InventoryRouter1720(),
      new InventoryRouter1721(),
      new InventoryRouter1750(),
      new InventoryRouter1751(),
      new InventoryRouter1760(),
      new InventoryRouter1941(),
      new InventoryRouter2501(),
      new InventoryRouter2502(),
      new InventoryRouter2503(),
      new InventoryRouter2504(),
      new InventoryRouter2505(),
      new InventoryRouter2507(),
      new InventoryRouter2509(),
      new InventoryRouter2513(),
      new InventoryRouter2514(),
      new InventoryRouter2515(),
      new InventoryRouter2516(),
      new InventoryRouter2520(),
      new InventoryRouter2521(),
      new InventoryRouter2522(),
      new InventoryRouter2523(),
      new InventoryRouter2610(),
      new InventoryRouter2611(),
      new InventoryRouter2620(),
      new InventoryRouter2621(),
      new InventoryRouter2811(),
      new InventoryRouter2901(),
      new InventoryRouter2911(),
      new InventoryRouter3620(),
      new InventoryRouter3640(),
      new InventoryRouter801(),
      new InventoryRouter802(),
      new InventoryRouter803(),
      new InventoryRouter804(),
      new InventoryRouter805(),
      new InventoryRouter806(),
    ];
  }

  getAllSwitches(): BaseInventorySwitch[] {
    return [
      new InventorySwitch2950(),
      new InventorySwitch296024TT(),
      new InventorySwitch296048TT(),
      new InventorySwitch29608(),
      new InventorySwitch3550(),
      new InventorySwitch35608pc(),
    ];
  }

  getSpecialDevices(): IInventoryDevice[] {
    return [new InventoryPC(), new InventoryPhone()];
  }

  getAddons(): IAddon[] {
    return [
      new EHwic4Gigabit(),
      new Hwic2Bri(),
      new Hwic2Serial(),
      new Hwic4Serial(),
      new NM1FastEthernet(),
      new NM4Bri(),
      new NM4Serial(),
      new NM8Bri(),
      new SW14FastEthernet(),
      new SW3Eth(),
      new Wic1Bri(),
      new Wic1Serial(),
      new EHwic1Gigabit(),
      new Hwic1Serial(),
      new Hwic2FastEthernet(),
      new Hwic4Bri(),
      new Hwic8Serial(),
      new NM1Ethernet(),
      new NM4ASSerial(),
      new NM4Ethernet(),
      new NM8ASSerial(),
      new PC1Eth(),
      new SW2Eth(),
      new SW4Eth(),
      new Wic1Ethernet(),
      new Wic2Serial(),
    ];
  }

  private groupBy(list, keyGetter) {
    let map = new Map();
    let array = [];
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    map.forEach((mapValue, mapKey) => {
      array.push({ key: mapKey, value: mapValue });
    });

    return array;
  }

  private createTreeNode(id, name, children): IInventoryTreeNode {
    return {
      id: id,
      Name: name,
      Children: children,
      AreChildrenCollapsed: true,
      IsHidden: false,
    };
  }

  getSimplifiedInventoryTree(): IInventoryTreeNode[] {
    let tree = [
      new InventoryRouter3640(),
      new InventorySwitch2950(),
      new InventorySwitch3550(),
      new InventoryPC(),
      new InventoryPhone(),
    ];
    tree[0].Name = "Router";
    tree[1].Name = "Switch (Managed Layer 2)";
    tree[2].Name = "Switch (Layer 3)";
    tree[3].Name = "Windows PC";
    tree[4].Name = "IP Phone";
    return tree;
  }

  getInventoryTree(): IInventoryTreeNode[] {
    //Group tree by series
    let routerTree = this.groupBy(this.getAllRouters(), (r) => r.series);
    let switchTree = this.groupBy(this.getAllSwitches(), (r) => r.series);
    let specialTree = this.getSpecialDevices();

    //sort the series
    routerTree.sort((a, b) => parseInt(a.key) - parseInt(b.key));
    switchTree.sort((a, b) => parseInt(a.key) - parseInt(b.key));
    specialTree.sort((a, b) => (a.model < b.model ? -1 : a.model > b.model ? 1 : 0));

    //convert to nodes
    routerTree = routerTree.map((m) => this.createTreeNode(m.key, m.key, m.value));
    switchTree = switchTree.map((m) => this.createTreeNode(m.key, m.key, m.value));

    let routers: IInventoryTreeNode = {
      id: "RouterTree",
      Name: "Routers",
      Children: routerTree,
      AreChildrenCollapsed: false,
      IsHidden: false,
    };

    let switches: IInventoryTreeNode = {
      id: "SwitchesTree",
      Name: "Switches",
      Children: switchTree,
      AreChildrenCollapsed: false,
      IsHidden: false,
    };

    let others: IInventoryTreeNode = {
      id: "OtherTree",
      Name: "Other",
      Children: this.getSpecialDevices(),
      AreChildrenCollapsed: false,
      IsHidden: false,
    };

    return [routers, switches, others];
  }

  getDeviceBySeriesAndModel(series: string, model: string) {
    return this.allDevices.find((m) => m.series == series && m.model == model);
  }

  getAddonByName(name): IAddon {
    return this.allAddons.find((m) => m.name == name);
  }

  updateLastDropped(event: DndDropEvent) {
    this.lastDropEvent = event;
  }

  updateDeviceOptions(options: KeyPair[]) {
    this.configurationOptionsLoadedSubject.next(options);
  }

  updateFavoriteConfigurations() {
    this.favoriteConfigurationUpdatedSubject.next(true);
  }
}
